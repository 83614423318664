import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, withRouter, useHistory } from 'react-router-dom';
import { RootState } from 'typesafe-actions';
import { withCookies } from 'react-cookie';

import {
  insertLocalData,
  getLocalData,
  getCurrentTimestamp
} from '../../utils';
import {
  setTokenAction // setUserCredentialAction
} from '../../actions';

function LocalAuthProvider(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const ttdToken = props.cookies.get('ttdToken') || null;
  const packageToken = props.cookies.get('packageToken') || null;
  const tokenExpTime = getLocalData('tokenExpTime', 'local');
  const { pathname, search } = props.location;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isRedirecting, setIsRedirecting] = useState(false);

  const isRedirect = useCallback(() => {
    // const tokenExpTime = getLocalData('tokenExpTime', 'local');
    const currentTimestamp = getCurrentTimestamp();
    const shouldRedirect = !!(
      ((!ttdToken || !packageToken) && pathname !== '/login') ||
      (ttdToken &&
        packageToken &&
        pathname === '/bulk-edits' &&
        currentTimestamp - Number(tokenExpTime) <= 600)
    );
    // console.log('pathname: ' + pathname + ' red: ' + shouldRedirect);
    return shouldRedirect;
  }, [ttdToken, packageToken, pathname, tokenExpTime]);

  useEffect(() => {
    if (!loggedIn && ttdToken && packageToken) {
      dispatch(setTokenAction());
    }
  }, [dispatch, loggedIn, ttdToken, packageToken]);

  useEffect(() => {
    if (isRedirect()) {
      insertLocalData('redirectPath', `${pathname}${search}`);
      // return <Redirect to="/login" />;
      // history.push('/login');
      setIsRedirecting(true);
      window.location.href = '/login';
    }
  }, [history, isRedirect, pathname, search]);

  if (isRedirect()) {
    insertLocalData('redirectPath', `${pathname}${search}`);
    return <Redirect to="/login" />;
    // // history.push('/login');
    // setIsRedirecting(true);
    // window.location.href = '/login';
  }

  // if (!isRedirecting)
  return <>{loggedIn && props.children}</>;
  // return null;
}

export default withRouter(withCookies(LocalAuthProvider));
