import { split_array } from '../utils/index';
import { Observable, of, timer } from 'rxjs';
import {
  mergeMap,
  delay,
  tap,
  take,
  filter,
  concatMap,
  concatAll,
  reduce
} from 'rxjs/operators';
import { ICreateUpdateResultData } from './UpdateData';
import { IPutAdGroup, postPutAdGroup$ } from './postPutAdGroup';
import { readCampaignIds$, IRunBaseRead } from './readCampaignIds';
import { IResultsData } from './requestTtdApi';

import { blockingArrayRunner$ } from './blockingArrayRunner';

export interface IUpdateAdgroupBudgets {
  ttdCampaignId: string;
  // ad group maiing for cloning
  AdGroupIdMap?: Object;
  // ad group map for non cloned campaigns
  AdGroupMap?: Object;
  ttdAuthToken: string;
  progressFunc?: Function;
  run?: number;
  totalRuns?: number;
}

export interface IAgQueueGenerator {
  run?: number;
  totalRuns?: number;
  runHandler?: Function;
  progress?: Function;
  resultReceiver: Function;
}

interface IArrayJob {
  jobs: Array<any>;
  run?: number;
  runLength?: number;
  totalRuns?: number;
  // when to retrun result
  resultRunLimit?: number;
  progressFunc?: Function;
  ttdAuthToken: string;
}

interface IPollResult {
  messages: Array<any>;
  done: boolean;
}

interface IArrayJobHandler extends IArrayJob {
  handler: Function;
}

interface IArrayJobObsHandler extends IArrayJob {
  handler: (Object) => Observable<any>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
var campaignAgRunner: Observable<any> | undefined,
  campaignAgPusher: Function,
  campaignAgCompleter: Function | undefined,
  campaignRunnerInit: boolean = false;

interface IAgBudgetJson {
  // AdGroupIdMap = {oldAdgroup : newAdgroup}
  AdGroupIdMap?: Object;
  // AdGroupMap = {[adgroup id]:[adgroup name]}
  AdGroupMap?: Object;
  cf: Array<any>;
}

/**
 * returns Array of new ad groups
 * @param agMap -  ad group map to process
 */
const getNewAdgroupArray = (agMap: Object) => {
  var agKeys = Object.keys(agMap) as Array<any>;
  return agKeys.reduce((acc, ag) => {
    acc.push(agMap[ag]);
    return acc;
  }, []);
};

/***
 * generates Array of JSON objects containing the campaign flight settings for each ad group in agMap
 * @param agMap - ad group map
 * @param flightArr - array of campaign flights
 */
const generateAdgroupBudgetJson = (props: IAgBudgetJson) => {
  var { AdGroupIdMap, AdGroupMap, cf } = props;
  var sJsonArr,
    cfKeys = [
      'CampaignFlightId',
      'BudgetInAdvertiserCurrency',
      'BudgetInImpressions',
      'DailyTargetInAdvertiserCurrency',
      'DailyTargetInImpressions'
    ];

  // prettier-ignore
  if (!cf || cf.length === 0 || (!AdGroupIdMap && !AdGroupMap) ||
    (AdGroupIdMap && Object.keys(AdGroupIdMap).length === 0) ||
    (AdGroupMap && Object.keys(AdGroupMap).length === 0)
  ) {
    return sJsonArr;
  }
  var campId = cf[0].CampaignId;
  var flightBase = cf.map((cf) => {
    return cfKeys.reduce((acc, key) => {
      if (cf[key]) {
        acc[key] = cf[key];
      }
      return acc;
    }, {});
  });
  var baseJson = {
    CampaignId: campId,
    RTBAttributes: {
      BudgetSettings: {
        AdGroupFlights: flightBase
      }
    }
  };

  var agArr: Array<any> = [];
  if (AdGroupIdMap) {
    agArr = getNewAdgroupArray(AdGroupIdMap);
  }
  if (AdGroupMap) {
    agArr = Object.keys(AdGroupMap);
  }

  return agArr.map((ag) => {
    var agJson = {
      ...baseJson,
      AdGroupId: ag
    };
    return agJson;
  });
};

/**
 * Runs adgroup budget update for 1 campaign
 * @param props
 * @returns
 */
export const updateAdgroupBudgets$ = (props: IUpdateAdgroupBudgets) => {
  let {
    ttdAuthToken,
    ttdCampaignId,
    // AdGroupIdMap = {oldAdgroup : newAdgroup}
    AdGroupIdMap,
    // AdGroupMap = {[adgroup id]:[adgroup name]}
    AdGroupMap,
    progressFunc,
    run,
    totalRuns
  } = props;

  var ccRun = run;
  var obj: IRunBaseRead = {
    run: 0,
    start: 0,
    limit: 1,
    ttdAuthToken,
    BaseId: 'CampaignId'
  };
  obj.CampaignId = ttdCampaignId;
  return readCampaignIds$(obj).pipe(
    // process campaign result + filter out flights
    mergeMap((res: IResultsData) => {
      var { data, message } = res;
      var retObj: ICreateUpdateResultData = {
        data: [],
        count: 0,
        messages: []
      };
      if (message) {
        // forward error
        retObj.messages = [ttdCampaignId + ' - ' + message];
      } else {
        var cf = (data && data.CampaignFlights) || [];
        var agJson = generateAdgroupBudgetJson({
          AdGroupIdMap,
          AdGroupMap,
          cf
        });
        if (agJson) {
          // update ad group budgets
          var agObj: IArrayJob = {
            ttdAuthToken,
            jobs: agJson,
            progressFunc,
            run: ccRun || 1,
            totalRuns
          };
          return blockingCampaignJobRunner$(agObj);
        }
        retObj.messages = [ttdCampaignId + ' - no valid AdGroup mapping found'];
      }
      return of(retObj);
    })
  );
};

/**
 * more generic array runner with progress calculation
 * handler executes the action on the payload
 * @param props
 */
const progressJobHandler = (props: IArrayJobObsHandler) => {
  var {
    jobs,
    ttdAuthToken,
    run,
    runLength,
    totalRuns,
    handler,
    progressFunc
  } = props;

  var mArr = jobs;
  if (!Array.isArray(mArr)) {
    mArr = [jobs];
  }
  run = run || -1;
  var sCount = 0,
    rCount = 0;
  var jLen = mArr.length,
    count = 0;

  if (run >= 0 && runLength && totalRuns) {
    sCount = (run - 1) * runLength;
    count += sCount;
    if (run !== totalRuns) {
      //we potentially overestimate a bit here as we don't know if the last run is full or not
      jLen = totalRuns * runLength;
    } else {
      rCount = (totalRuns - 1) * runLength;
      jLen += rCount;
    }
  }
  return of(mArr).pipe(
    tap((el) => {
      // console.log(JSON.stringify(el));
    }),
    concatAll(),
    // Emit each value as a sequence of observables with a desired delay
    // parallel execution for all fetches
    mergeMap((runObj) => {
      // include closure parameters in addition to JSON payload here for swapping out the handler easily later without loosing these context variables
      return of({
        sJSON: runObj,
        ttdAuthToken,
        run,
        totalRuns
      }).pipe(
        delay(250),
        tap(() => {
          count++;
        }),
        // run each fetch and provide JSON payload, ttdAuthToken, run, totalRuns to the handler function
        mergeMap(handler),
        tap(() => {
          // progress reporting
          // prettier-ignore
          if (progressFunc && typeof progressFunc === 'function' && count && jLen){
            var pct = Math.round((count / jLen) * 100);
            progressFunc({ percent: pct });
          }
        })
      );
    }, 1),
    // combine data
    reduce(
      (acc, val: IResultsData) => {
        var data = val.data;
        acc.data.push(data);
        if (val.message) {
          // @ts-ignore
          acc.messages.push(val.message);
        }
        acc.count = acc.data.length;
        return acc;
      },
      {
        data: [] as any,
        count: 0,
        messages: [] as any
      } as ICreateUpdateResultData
    ),
    take(1)
    // tap((el) => {
    //   console.log(JSON.stringify(el));
    // })
  );
};

/**
 * Adgroup put handling
 * @param rProps
 * @returns
 */
const agPutRun = (rProps) => {
  // console.log('agPutRun run: ' + JSON.stringify(sJSON));
  var { sJSON, ttdAuthToken } = rProps;
  var agObj: IPutAdGroup = {
    sJSON,
    ttdAuthToken,
    method: 'PUT'
  };
  return postPutAdGroup$(agObj);
};

// handle set of ad group jobs that can run in one minute
export const agMinuteJobHandler = (props: IArrayJob) => {
  return progressJobHandler({ ...props, handler: agPutRun });
};

/**
 * Campaign Array runner for updating adgroup budgets
 * @param rProps
 * @returns
 */
const agBudgetUpdateHandler = (rProps) => {
  // console.log('agBudgetUpdateRun run: ' + JSON.stringify(sJSON));
  // prettier-ignore
  var { sJSON, ttdAuthToken, run, totalRuns } = rProps;
  var { ttdCampaignId, AdGroupMap } = sJSON;
  var agObj: IUpdateAdgroupBudgets = {
    ttdAuthToken,
    ttdCampaignId,
    AdGroupMap,
    run,
    totalRuns
  };
  return updateAdgroupBudgets$(agObj);
};

// handle set of ad group jobs that can run in one minute
export const agBudgetUpdateRunner$ = (props: IArrayJob) => {
  return progressJobHandler({ ...props, handler: agBudgetUpdateHandler });
};

// /**
//  * handle individual job that can run in one minute
//  * @param props
//  */
//  export const agMinuteJobHandlerO = (props: IArrayJob) => {
//   var { jobs, ttdAuthToken, run, runLength, totalRuns, progressFunc } = props;

//   var mArr = jobs;
//   if (!Array.isArray(mArr)) {
//     mArr = [jobs];
//   }
//   var sCount = 0,
//     rCount = 0;
//   var jLen = mArr.length,
//     count = 0;

//   if (run >= 0 && runLength && totalRuns) {
//     sCount = (run - 1) * runLength;
//     count += sCount;
//     if (run !== totalRuns) {
//       //we potentially overestimate a bit here as we don't know if the last run is full or not
//       jLen = totalRuns * runLength;
//     } else {
//       rCount = (totalRuns - 1) * runLength;
//       jLen += rCount;
//     }
//   }
//   return of(mArr).pipe(
//     tap((el) => {
//       console.log(JSON.stringify(el));
//     }),
//     concatAll(),
//     // Emit each value as a sequence of observables with a desired delay
//     // parallel execution for all fetches
//     mergeMap((runObj) => {
//       // status message
//       // var gm1: number;
//       return of({
//         sJSON: runObj,
//         ttdAuthToken, run, totalRuns
//       }).pipe(
//         delay(250),
//         tap(() => {
//           count++;
//         }),
//         // create parameters for each fetch
//         mergeMap((rProps) => {
//           // console.log('minute run: ' + JSON.stringify(sJSON));
//           var { sJSON, ttdAuthToken } = rProps;
//           var agObj: IPutAdGroup = {
//             sJSON,
//             ttdAuthToken,
//             method: 'PUT'
//           };
//           return postPutAdGroup$(agObj);
//           // return of({ data: [sJSON], message: '' }) as Observable<IResultsData>;
//         }),
//         tap(() => {
//           // progress reporting
//           // prettier-ignore
//           if (progressFunc && typeof progressFunc === 'function' && count && jLen){
//             var pct = Math.round((count / jLen) * 100);
//             progressFunc({ percent: pct });
//           }
//         })
//       );
//     }, 1),
//     // combine data
//     reduce(
//       (acc, val: IResultsData) => {
//         var data = val.data;
//         acc.data.push(data);
//         if (val.message) {
//           // @ts-ignore
//           acc.messages.push(val.message);
//         }
//         acc.count = acc.data.length;
//         return acc;
//       },
//       {
//         data: [] as any,
//         count: 0,
//         messages: [] as any
//       } as ICreateUpdateResultData
//     ),
//     // tap((el) => {
//     //   console.log(' bQ1 next' + JSON.stringify(el));
//     //   // allow next run as we have the current one complete
//     //   agDoNext();
//     // }),
//     take(1)
//     // tap((el) => {
//     //   console.log(JSON.stringify(el));
//     // })
//   );
// };

/**
 * breaks down campaign jobs into minute jobs and runs them all using our blockingArrayRunner
 * @param args
 */
export const blockingCampaignJobRunner$ = (
  props: IArrayJob
): Observable<any> => {
  // prettier-ignore
  var {jobs,totalRuns} = props;

  var adGroupMessages: Array<string> = [],
    resultReceived = false;
  // split jobs into minute jobs
  var jArr = split_array(jobs, 36);
  var jobLen = jArr.length;

  /**
   * setup our run handler for all jobs
   * @param xArr
   * @returns
   */
  const outerArrayRunner$ = (xArr) => {
    var runArgs = {
      ...props,
      jobs: xArr,
      handler: agMinuteJobHandler,
      runLength: 32,
      totalRuns: jobLen
    };
    return myArrrayRunner$(runArgs);
  };

  /**
   * stores results for the adgroups we are running in this job and will be used by queue poller later
   * @param res
   */
  const collectAdgroupMessages = (res: ICreateUpdateResultData) => {
    var { messages } = res;
    if (Array.isArray(messages)) {
      resultReceived = true;
      messages.forEach((el) => {
        if (el && el !== '') {
          adGroupMessages.push(el);
        }
      });
    }
  };

  /**
   * we need to poll for results as we can't directly subscribe to the ArrayRunner and use a result notifier instead
   * @returns ad group result
   */
  const queuePoller$ = () => {
    var startingOffset = 500;
    var pollingInterval = 500;
    return timer(startingOffset, pollingInterval).pipe(
      concatMap(() => {
        if (resultReceived) {
          return of({ messages: [...adGroupMessages], done: true });
        } else {
          return of({ messages: [], done: false });
        }
      }),
      // filter out  InProgress
      filter((pollResult: IPollResult) => {
        let { done } = pollResult;
        return done;
      }),
      take(1),
      // tap((res: IPollResult) => {
      //   console.log('queuePoller result: ' + JSON.stringify(res));
      // })
    );
  };

  // as we don't know the jobs will complete in order, we use a flag for initializing
  if (!campaignRunnerInit) {
    // set campaignAgRunner and campaignAgPusher
    agQueueGenerator$({
      totalRuns,
      runHandler: outerArrayRunner$,
      resultReceiver: collectAdgroupMessages
    });
  }

  // prettier-ignore
  // console.log('outer Runner: run - ' +run +' campaignRunnerInit -  ' +campaignRunnerInit + ' json - ' + JSON.stringify([jArr]));

  // providing our receiver for the mesages here is needed as the ArrayRunner responds to different function closures
  campaignAgPusher([jArr], collectAdgroupMessages);

  return queuePoller$();
};

// array job run
export const myArrrayRunner$ = (props: IArrayJobHandler): Observable<any> => {
  // prettier-ignore
  var {jobs, handler} = props;

  var mArr = jobs,
    count = 1;
  if (!Array.isArray(mArr)) {
    mArr = [jobs];
  }
  var jobLen = mArr.length;
  return of(mArr).pipe(
    // tap((el) => {
    //   console.log(JSON.stringify(el));
    // }),
    concatAll(),
    // serial execution for all fetches
    mergeMap((run) => {
      return of(run).pipe(
        // create parameters for each run
        mergeMap((jobArr) => {
          var jobProbs = {
            ...props,
            jobs: jobArr,
            run: count,
            totalRuns: jobLen
          };
          // execute handler
          return handler(jobProbs);
        }),
        tap((_) => {
          count++;
        })
      );
    }, 1),
    reduce(
      (acc, val) => {
        // @ts-ignore
        var { data, messages } = val;
        acc.data = [...acc.data, ...data];
        acc.messages = [...acc.messages, ...messages];
        return acc;
      },
      {
        data: [] as any,
        messages: [] as any
      }
    ),
    take(1)
  );
};

// allows cleanup in case of failed jobs
export const handleCloneAgComplete = () => {
  if (typeof campaignAgCompleter === 'function') {
    campaignAgCompleter();
  }
};

/**
 * does the initial setup of our blockingArryRunner
 * @param props
 */
export const agQueueGenerator$ = (props: IAgQueueGenerator) => {
  var { totalRuns, runHandler, resultReceiver } = props;

  /**
   * the progress handler will be used to reset our cached properties and init flag as soon as all jobs are complete This allows to create a new ArrayRunner for each new set of campaign clone jobs
   * @param obj
   */
  const totalProgressHandler = (obj) => {
    if (obj === 100) {
      campaignAgRunner = undefined;
      campaignAgPusher = () => {};
      campaignAgCompleter = undefined;
      campaignRunnerInit = false;
    }
  };

  // we don't know the order in which clonejobs wil be completing, so we can't use the run-parameter as start indicator and use a flag instead
  if (!campaignRunnerInit) {
    var ba;
    ba = blockingArrayRunner$({
      jobs: [],
      jobRunner: runHandler as Function,
      resultReceiver,
      // totalRuns is number of campaigns we clone
      finalLength: totalRuns,
      progressNotifier: totalProgressHandler
    });

    campaignAgRunner = ba.runner;
    campaignAgPusher = ba.pusher;
    campaignAgCompleter = ba.completer;
    campaignRunnerInit = true;
  }
  // for other runs, we will reuse the runner / pusher until all jobs are complete
};
