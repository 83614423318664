import { Observable, of } from 'rxjs';
import {
  mergeMap,
  delay,
  tap,
  take,
  // filter,
  concatMap,
  concatAll,
  reduce,
  catchError
} from 'rxjs/operators';
import { IResultsData } from './requestTtdApi';

const enableDebug =
  process.env.REACT_APP_DEBUG &&
  process.env.REACT_APP_DEBUG.toLocaleLowerCase() !== 'false';

export interface ICreateUpdateDataArr {
  [index: string]: number | string | undefined | any;
  ttdAuthToken: string;
  method: 'POST' | 'PUT';
  // JSON data
  JSONArr: Array<any>;
  // optional custom rate limit
  limit?: number;
  parallelRuns?: number;
  // progress notification
  progress?: Function;
  // forward progress notifier instead of using default one
  forwardProgress?: Function;
  // allow disabling ResponeFilter
  disableResponseFilter?: boolean;
  // measure timing
  measurePerformance?: boolean;
  // measureName needs to be set for meaningful measuring
  measureName?: string;
}

export interface ICreateUpdateData {
  [index: string]: number | string | undefined | any;
  ttdAuthToken: string;
  method: 'POST' | 'PUT';
  // JSON data
  sJSON: string | JSON;
  // optional custom rate limit
  limit?: number;
  // run limit
  parallelRuns?: number;
  // allow disabling ResponeFilter
  disableResponseFilter?: boolean;
  // measure timing
  measurePerformance?: boolean;
  // measureName needs to be set for meaningful measuring
  measureName?: string;
  // run counter
  run?: number;
  // total number of runs
  totalRuns?: number;
  // forward progress notifierr instead of using default one
  forwardProgress?: Function;
}

export interface ICreateUpdateResultData {
  data: any;
  count?: number;
  messages?: Array<any>;
  rateLimit?: number;
}

export interface APICreateUpdate {
  // eslint-disable-next-line no-unused-vars
  (props: ICreateUpdateData): Observable<IResultsData>;
}

export interface ICreateUpdateDataFunc extends ICreateUpdateDataArr {
  apiCreateUpdate: APICreateUpdate;
}

var campDelayCorrection = 0;

export const createUpdateData$ = (
  props: ICreateUpdateDataFunc
): Observable<IResultsData> => {
  let {
    ttdAuthToken,
    JSONArr,
    method,
    parallelRuns,
    progress,
    apiCreateUpdate,
    disableResponseFilter,
    measurePerformance,
    measureName,
    forwardProgress
  } = props;

  var cnt = 0;
  // limit is enforced differently now in requestTtdApi
  var desiredDelay = 250; //limit ? 60000 / limit : 300; // default 200 per minute
  var runParallel = parallelRuns || 10;
  var currentParallelRuns = runParallel;
  var totalRuns = JSONArr.length;

  // using an arrray as counter provider together with mergeMap for parallel execution of the fetch requests
  return of(JSONArr).pipe(
    // Split array into single values during emit
    // Collect observables and subscribe to next when previous completes
    //@ts-ignore
    concatAll(),
    // Emit each value as a sequence of observables with a desired delay
    concatMap((a: JSON) => of(a).pipe(delay(2))),
    // parallel execution for all fetches
    mergeMap((run) => {
      // status message
      // var gm1: number;
      var measure_mark = '';
      return of(run).pipe(
        tap(() => {
          desiredDelay = campDelayCorrection || desiredDelay;
        }),
        delay(desiredDelay),
        // create parameters for each fetch
        mergeMap((runJSON: JSON) => {
          if (measurePerformance) {
            measure_mark = measureName + '_' + method + '_' + (cnt + 1);
            console.time(measure_mark); //initial mark for the current run
          }
          if (cnt % 10 === 0 && enableDebug)
            console.log(
              ' ' +
                method.toLowerCase() +
                ' ' +
                (cnt + 1) +
                ' of ' +
                totalRuns +
                ' running - mm: ' +
                measure_mark
            );
          cnt++;
          let obj: ICreateUpdateData;
          obj = {
            ttdAuthToken,
            sJSON: runJSON,
            method,
            disableResponseFilter,
            // extra progress info
            run: cnt,
            totalRuns,
            forwardProgress
          };
          return of(obj);
        }),
        // execute individual fetch
        mergeMap((p: ICreateUpdateData) => {
          return apiCreateUpdate(p);
        }, 1),
        tap(() => {
          if (measurePerformance) {
            console.timeEnd(measure_mark); //final mark for the same run
          }
          // progress reporting
          if (!forwardProgress && progress && typeof progress === 'function') {
            /* we can't be sure about the order of requests 
            therefore, we just increase a request counter for each run and compare it to the number of total runs;
            the function can be called more than once for multi loads, so each call has a separate cnt / runs set in the runProgress array reflecting all calls  
            */
            var pct = Math.round((cnt / totalRuns) * 100);
            progress({ percent: pct });
          }
        })
      );
    }, currentParallelRuns),
    // combine data
    reduce(
      (acc, val: IResultsData) => {
        var data = val.data;
        acc.data.push(data);
        if (val.message) {
          // @ts-ignore
          acc.messages.push(val.message);
        }
        acc.count = acc.data.length;
        // rate limit auto correction
        if (!campDelayCorrection && val.rateLimitParams) {
          var { timeFrame, maxCalls } = val.rateLimitParams;
          if (timeFrame && maxCalls) {
            var calcDelay = Math.floor(timeFrame / maxCalls);
            if (desiredDelay !== calcDelay) {
              campDelayCorrection = Math.floor(calcDelay * 0.98);
            }
            acc.rateLimit = Math.floor(maxCalls * 0.98);
            // console.log('new rate limit: ' + maxCalls);
          }
        }
        return acc;
      },
      {
        data: [],
        count: 0,
        rateLimit: 0,
        messages: [] as any
      } as ICreateUpdateResultData
    ),
    take(1),
    tap(() => {
      if (progress && typeof progress === 'function') {
        progress({ percent: 100, immediate: true });
      }
    }),
    catchError((err) => {
      // console.log(err);
      // ensure proper cleanup
      if (progress && typeof progress === 'function') {
        progress({ percent: 100, immediate: true });
      }
      return of(err);
    })
    // tap(e => {
    //   console.log('after reduce', e);
    // })
    // ensure complete data or error
    // filter((el: IResultsData) => {
    //   if (!el || (el && el.message !== '')) {
    //     // let errors pass
    //     return true;
    //   }
    //   if (el.data && el.count && el.totalCount)
    //     return el.count === el.totalCount;
    //   return false;
    // }),
    // // See the result, not necessary
    // tap(console.log)
  );
};
