import React, { useCallback } from 'react';
import { titleCase } from '../../utils';
type headerProps = {
  handleUpdateParams: (a: string) => void;
  field: string;
  replaceName?: string;
};

/**
 * creates header fragment with click handler using title case or replaceName
 * @param handleUpdateParams - click handler function with field parameter
 * @param field - field name
 * @param replaceName  - replace name to display
 * @returns header fragment with click handler
 */
const ColumnHeader = (props: headerProps) => {
  let { handleUpdateParams, field, replaceName } = props;
  let fld = replaceName;
  if (!fld) {
    fld = field;
  }
  const fldTitleCase = titleCase(fld);

  /* A 25 px wide glyph in the header will potentially push the click handler to the right and mean we aren't handling click on it correctly. Using an absolute positioned parent div below to overlay a displayed glyph and then relatively positioning our column heading fixes that issue
   */
  if (fldTitleCase.includes('Pacing') || fldTitleCase.includes('Campaign')) {
    // left aligned columns
    return (
      <div
        className="header-table"
        onClick={() => handleUpdateParams(field)}
        style={{ position: 'absolute', left: '-25px', width: '100%' }}
      >
        <div
          style={{
            position: 'relative',
            left: '50px'
          }}
        >
          {fldTitleCase}
        </div>
      </div>
    );
  }
  // right aligned columns for numbers 
  return (
    <div
      className="header-table"
      onClick={() => handleUpdateParams(field)}
      style={{ position: 'absolute', left: '-25px', width: '100%' }}
    >
      <div
        style={{
          position: 'relative',
          left: '25px'
        }}
      >
        {fldTitleCase + '\u00A0\u00A0'}
      </div>
    </div>
  );
};

export default ColumnHeader;
