import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
// import { MyStore } from "typesafe-actions";
import { CookiesProvider } from 'react-cookie';

import store from './store';
import * as serviceWorker from './serviceWorker';
// reset style for all browsers
import 'normalize.css/normalize.css';
import 'styles/base.scss';
import './styles/index.scss';
import '@ttd/maui/src/fonts/avenir.scss';
import App from './App';
// import configureStore from './store/_index';

const renderContainer = (Component) => {
  const root = document.getElementById('root');

  const Application = (
    <Provider store={store}>
      <Router>
        <StrictMode>
          <CookiesProvider>
            <Component />
          </CookiesProvider>
        </StrictMode>
      </Router>
    </Provider>
  );

  render(Application, root);
};

renderContainer(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
