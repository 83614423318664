import React, { useCallback, useEffect, useRef, useState } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import _ from 'lodash';
import SelectListControl from '@ttd/maui/lib/components/controls/SelectListControl';
import { CHART_NAME, CHART_TYPE_OPTIONS, ChartType } from './types';

interface ChartContainerProps {
  packageData: object[];
  chartId: string;
  chartType: ChartType;
  onChangeType: (type) => void;
  disabledType?: ChartType;
}

const ChartContainer = ({
  packageData,
  chartId,
  chartType,
  onChangeType, // parent change notifier
  disabledType
}: ChartContainerProps) => {
  let chartRef = useRef();

  const [chartOptions, setChartOptions] = useState({});
  const [selectTypeOptions, setSelectTypeOptions] = useState([]);

  useEffect(() => {
    // disable chart type displayed in other chart
    setSelectTypeOptions(
      _.map(CHART_TYPE_OPTIONS, (option) => {
        return {
          ...option,
          isDisabled: option.id === disabledType?.id
        };
      })
    );
  }, [disabledType]);

  const chartCallback = useCallback(
    (myChart) => {
      if (packageData.length === 0) {
        myChart.showLoading('Loading Data ...');
      } else {
        myChart.hideLoading();
      }
      chartRef.current = myChart;
    },
    [packageData.length]
  );

  const getChartStyle = (chartName) => {
    switch (chartName) {
      case CHART_NAME.TOP_CHART: {
        return {
          color: '#14a9b0',
          fillColor: '#32c4c8'
        };
      }
      case CHART_NAME.BOTTOM_CHART: {
        return {
          color: '#ffb565',
          fillColor: '#ffd391'
        };
      }
      default: {
        return {
          color: '',
          fillColor: ''
        };
      }
    }
  };

  const generateChartOptions = useCallback(() => {
    const categories: string[] = [];
    const chartData: Number[] = [];

    /*chartType
    {"id":"spend","name":"Spend","apiResponseKey":"spend","chartTooltip":"Total Spend","chartTooltipSuffix":"$"}
    */
    _.forEach(packageData, (data) => {
      chartData.push(data[chartType.apiResponseKey] || 0);
      categories.push(moment(data['report_date']).format('MM/DD'));
    });

    const chartStyle = getChartStyle(chartId);

    return {
      loading: { hideDuration: 1000, showDuration: 1000 },
      chart: {
        type: 'area'
      },
      credits: { enabled: false },
      title: { text: '' },
      xAxis: { categories },
      yAxis: { title: { text: chartType.name.toUpperCase() } },
      tooltip: {
        headerFormat: 'Date: <b>{point.x}</b><br />',
        pointFormat: `${chartType.chartTooltip}: <b>{point.y}</b>`,
        valueSuffix: `${chartType.chartTooltipSuffix}`
      },
      series: [
        {
          data: chartData,
          name: chartType.chartTooltip,
          color: chartStyle.color,
          fillColor: chartStyle.fillColor,
          fillOpacity: 0.4
        }
      ],
      plotOptions: {
        area: {
          clip: false,
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2,
            states: { hover: { enabled: true } }
          }
        },
        states: { inactive: { opacity: 1 }, hover: { lineWidthPlus: 0 } }
      },
      chartOptions: { animation: true },
      legend: { enabled: false }
    };
  }, [packageData, chartId, chartType]);

  useEffect(() => {
    setChartOptions(generateChartOptions());
    if (packageData.length > 0) {
      if (
        // @ts-ignore
        chartRef?.current?.hideLoading
      ) {
        // @ts-ignore
        chartRef.current?.hideLoading();
      }
    }
  }, [chartType, packageData, generateChartOptions]);

  useEffect(() => {
    // Handler to call on window resize for resizing both charts
    function handleResize() {
      if (Highcharts && Highcharts.charts[0]) {
        var chartLen = Highcharts.charts.length;
        for (var i = 1; i < chartLen; i++) {
          Highcharts.charts[i]?.setSize(window.innerWidth - 500, undefined);
          Highcharts.charts[i]?.reflow();
        }
      }
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div data-testid={chartId} className="chart-container">
      <SelectListControl
        id={'chart-selection-' + chartId}
        role={'select'}
        className="chart-selection"
        displayNameKey="name"
        primaryKey="id"
        value={chartType}
        onChange={onChangeType}
        availableItems={selectTypeOptions}
        allowEmptySelection={false}
        placeHolder="Select time frame"
      />
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{
          className: 'chart-area'
        }}
        callback={chartCallback}
      />
    </div>
  );
};

export default ChartContainer;
