import { Observable } from 'rxjs';
import { IResultsData, requestTtdApi$ } from './requestTtdApi';

export interface IPutAdGroup {
  // auth token
  ttdAuthToken: string;
  // method
  method: 'POST' | 'PUT';
  // JSON payload
  sJSON: string | JSON;
  // parallel runs information for handling token recovery correctly
}

enum DateStrBrand {}
export type DateStr = string & DateStrBrand;
export type Int = number & { __int__: void };

type agPacing = 'PaceToEndOfFlight' | 'PaceAhead' | 'Off' | 'PaceToEndOfDay';

interface agBudget {
  Amount: number;
  CurrencyCode: string;
}

export const postPutAdGroup$ = (
  props: IPutAdGroup
): Observable<IResultsData> => {
  var { ttdAuthToken, sJSON, method } = props;
  // for posts CampaignId does not exist, so take CampaignName instead as error id
  var errorId = 'AdGroupName';
  // @ts-ignore
  if (sJSON.AdGroupId) {
    // if we have a adgroup id then we take it as error id
    errorId = 'AdGroupId';
  }
  return requestTtdApi$({
    ttdAuthToken,
    sJSON,
    method,
    shortUrl: '/adgroup',
    responseFilterFieldList: [
      // 'Availability',
      'CampaignId',
      'AdGroupId',
      'AdGroupName',
      'IsEnabled',
      // multi level objects
      // 'RTBAttributes.CreativeIds',
      'RTBAttributes.BudgetSettings.Budget',
      'RTBAttributes.BudgetSettings.DailyBudget',
      'RTBAttributes.BudgetSettings.AdGroupFlights'
    ],
    errorId
  });
};
