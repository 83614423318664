import { UserManager } from 'oidc-client';
import { OIDC_CONFIGURATION } from '../../vars';
import { SIGNIN_OIDC_PATH, SIGNOUT_OIDC_PATH } from '../../constants/route';

const settings = {
  authority: OIDC_CONFIGURATION.AUTH_URL,
  client_id: OIDC_CONFIGURATION.CLIENT_ID,
  redirect_uri: `${OIDC_CONFIGURATION.CLIENT_ROOT}${SIGNIN_OIDC_PATH}`,
  silent_redirect_uri: OIDC_CONFIGURATION.CLIENT_ROOT,
  post_logout_redirect_uri: `${OIDC_CONFIGURATION.CLIENT_ROOT}${SIGNOUT_OIDC_PATH}`,
  response_type: 'code',
  scope: OIDC_CONFIGURATION.SCOPE
};

let userManager;

const getUserManager = () => {
  if (!userManager) {
    userManager = new UserManager(settings);
  }
  return userManager;
};

export default getUserManager();
