import React from 'react';
import _ from 'lodash';
import moment from 'moment';

function RecentlyViewed(props) {
  return (
    <table>
      <thead>
        <tr>
          <th>Recently Viewed Packages</th>
        </tr>
      </thead>
      <tbody>
        {!_.isEmpty(props.list) &&
          props.list.map((item, key) => {
            const currentDate = new Date(item.last_viewed_at);
            if (moment(currentDate).format('yyyy/M/D') !== 'Invalid date') {
              return (
                <tr
                  key={key}
                  onClick={() =>
                    props.onPackageSelected(item.package_name, item.package_id)
                  }
                >
                  <td>
                    {item.package_name} ({moment(currentDate).format('MM/DD')})
                  </td>
                </tr>
              );
            }

            return true;
          })}
      </tbody>
    </table>
  );
}

export default RecentlyViewed;
