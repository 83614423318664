import { Observable } from 'rxjs';
import { IResultsData, requestTtdApi$ } from './requestTtdApi';

export interface IPostPutCampaignFlight {
  // auth token
  ttdAuthToken: string;
  // method
  method: 'POST' | 'PUT';
  // JSON payload
  sJSON: string | JSON;
  // parallel runs information for handling token recovery correctly
}

enum DateStrBrand {}
export type DateStr = string & DateStrBrand;
export type Int = number & { __int__: void };

type campPacing = 'PaceToEndOfFlight' | 'PaceAhead' | 'Off';

interface campBudget {
  Amount: number;
  CurrencyCode: string;
}

// const formatDateStrUTC = (dtStr: string): DateStr => {
//   let mDt = new Date(dtStr);
//   let sDt = mDt.toISOString().replace('Z', '+00:00') as DateStr;
//   // console.log('formatDateUTC date:' + mDt.toLocaleString() + ' to ' + dtStr);
//   return sDt;
// };

interface campFlight {
  CampaignId: string;
  CampaignFlightId?: string;
  BudgetInAdvertiserCurrency: number;
  StartDateInclusiveUTC: string;
  EndDateExclusiveUTC: string;
}

export interface genCampJson {
  // if Budget / StartDate is specified, we can't specify CampaignFlights
  Budget?: campBudget;
  StartDate?: string;
  EndDate?: string;
  AutoAllocatorEnabled?: boolean;
  AutoPrioritizationEnabled?: boolean;
  PacingMode: campPacing;
  CampaignFlights?: Array<campFlight>;
  // CampaignConversionReportingColumns are required, but can be an empty Array
  CampaignConversionReportingColumns: Array<any>;
  AdvertiserId: string;
  CampaignName: string;
  Description?: string;
  TimeZone?: string;
}

export const PostPutCampaignFlight$ = (
  props: IPostPutCampaignFlight
): Observable<IResultsData> => {
  var { ttdAuthToken, sJSON, method } = props;
  // for posts CampaignFlightId does not exist, so take CampaignId instead as error id
  var errorId = 'CampaignId',
    requestFilterFieldList: Array<any> = ['op', 'rowIdx'];
  if (method === 'POST') {
    requestFilterFieldList = ['CampaignFlightId', 'op', 'rowIdx'];
  }
  // @ts-ignore
  else if (sJSON.CampaignFlightId) {
    // if we have a campaign flight id then we take it as error id for puts
    errorId = 'CampaignFlightId';
  }
  return requestTtdApi$({
    ttdAuthToken,
    sJSON,
    method,
    shortUrl: '/campaignflight',
    requestFilterFieldList,
    responseFilterFieldList: [
      'CampaignId',
      'CampaignFlightId',
      'BudgetInAdvertiserCurrency',
      'BudgetInImpressions',
      'DailyTargetInAdvertiserCurrency',
      'DailyTargetInImpressions',
      'StartDateInclusiveUTC',
      'EndDateExclusiveUTC'
    ],
    errorId
  });
};
