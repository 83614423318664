export const HOME_PATH = '/';
export const SIGNIN_OIDC_PATH = '/signin-oidc';
export const SIGNOUT_OIDC_PATH = '/signout-oidc';
export const LOGIN_PATH  = '/login'
export const PACKAGE_PATH = '/package';
export const PACKAGE_PERFORMANCE_DETAIL_PATH = '/package-performance-detail';
export const PACKAGE_SETTING_PATH = '/package-setting';
export const BULK_EDIT_PATH = '/bulk-edit';
export const BULK_EXPORT_PATH = '/bulk-export';

export const FORBIDDEN_PATH = '/forbidden';
