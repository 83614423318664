import { Observable, of, throwError } from 'rxjs';
import {
  retryWhen,
  take,
  delay,
  // tap,
  catchError,
  mergeMap,
  tap
} from 'rxjs/operators';
import { ttdAjax, ttdAjaxParams } from './ttdajax';
import { responseFilter } from './responsefilter';
import { registerRequest, shouldAllowRequest } from './ttd_request_tracker';

const enableDebug =
  process.env.REACT_APP_DEBUG &&
  process.env.REACT_APP_DEBUG.toLocaleLowerCase() !== 'false';

export interface IRequestTtdApi {
  // auth token
  ttdAuthToken: string;
  // JSON payload
  sJSON?: string | JSON;
  // request method
  method: 'GET' | 'POST' | 'PUT';
  // short endpoint URL
  shortUrl: string;
  // Array of fields to keep in response
  /* example
    var fieldList = [
      'AdvertiserId',
      'CampaignName',
      'CampaignId',
      'Availability'
      // multi level objects
      // 'RTBAttributes.CreativeIds',
      // 'RTBAttributes.BudgetSettings.Budget',
      // 'RTBAttributes.NielsenSettings'
    ];
  */
  responseFilterFieldList: Array<any>;
  // Id to use for error Reporting
  errorId: string;
  altErrorId?: string;
  // Array of fields to filter from request
  requestFilterFieldList?: Array<any>;
  // fields from sJson to include in response
  responseIncludeFieldList?: Array<any>;
  // overwrite maximum retries
  maxRetries?: number;
  filterError?: boolean | undefined;
  disableResponseFilter?: boolean | undefined;
}

export interface IRateLimitParams {
  timeFrame: number;
  maxCalls: number;
}
export interface IRequestError {
  response: string;
  status: number;
  request?: any;
}

interface IPageInfo {
  endCursor: string | null;
  hasNextPage: boolean;
}
interface ILastChangeTrackingVersion {
  advertiserId: string;
  lastChangeTrackingVersion: bigint;
}

export interface IResultsData {
  data: any;
  count?: number;
  totalCount?: number;
  ResultCount?: number;
  TotalFilteredCount?: number;
  message: string | IRequestError;
  ReferenceId?: string;
  rateLimitParams?: IRateLimitParams;
  // will only be defined when later polling via postPipeFunction$ is needed
  ttdAuthToken?: string;
  BaseId?: string;
  totalCounts?: Object;
  pageInfo?: IPageInfo;
  lastChangeTrackingVersions?: ILastChangeTrackingVersion[];
}

// export interface IResultData {
//   data: any;
//   message: string | IRequestError;
//   rateLimitParams?: IRateLimitParams;
//   authToken?: string;
// }

const requestFilter = (
  data: JSON | string | undefined,
  fieldList?: Array<any>
) => {
  var /* fieldList = [
      // both fields are read only and need to be excluded
      'CreatedAtUTC',
      'LastUpdatedAtUTC'
    ],*/
    mData: JSON | { [key: string]: number | string | boolean } = {};

  const filterData = (
    mDt: Object,
    fList: Array<string>,
    dKeys: Array<string>
  ) => {
    var retObj = dKeys.reduce((acc: any, el: string) => {
      // filter out the properties we don't need
      var fnd = fList.some((fld: string) => el === fld);
      if (!fnd) {
        // @ts-ignore
        acc[el] = mDt[el];
      }
      return acc;
    }, {});
    return retObj;
  };

  if (typeof data === 'string') {
    mData = JSON.parse(data);
  } else if (data) {
    mData = data;
  } else {
    return data;
  }
  if (Array.isArray(fieldList)) {
    var retObj;
    if (Array.isArray(mData)) {
      retObj = mData.map((el) => filterData(el, fieldList, Object.keys(el)));
    } else {
      retObj = filterData(mData, fieldList, Object.keys(mData));
    }
    return retObj;
  }
  return mData;
};

export const requestTtdApi$ = (
  props: IRequestTtdApi
): Observable<IResultsData> => {
  let {
    sJSON,
    ttdAuthToken,
    method,
    shortUrl,
    responseFilterFieldList,
    responseIncludeFieldList,
    requestFilterFieldList,
    errorId,
    altErrorId,
    maxRetries,
    filterError,
    disableResponseFilter
  } = props;

  /**
   * build error message
   * @param param1 - error object returned from ttdAjax
   */
  const Error = ({ response, status, request }: IRequestError) => {
    // console.log('resp: ', response);
    // console.log('status: ', status);
    var requestStr = '';
    if (request && request.body) {
      requestStr = request.body;
      if (requestStr) {
        var rObj = JSON.parse(requestStr);
        if (rObj && rObj[errorId]) requestStr = rObj[errorId];
      }
    } else if (altErrorId) {
      requestStr = altErrorId;
    }
    var sDetails = '';
    if (typeof response !== 'string') {
      if (response) {
        try {
          //@ts-ignore
          var eDetails = response.ErrorDetails;
          if (eDetails) {
            if (typeof eDetails === 'object' && eDetails.length > 0) {
              sDetails = JSON.stringify(eDetails);
            } else {
              sDetails = eDetails;
            }
          }
        } catch (e) {
          //
        }
      }
    }
    return `${requestStr}\t${
      // eslint-disable-next-line no-nested-ternary
      typeof response === 'string'
        ? response
        : // @ts-ignore
        response && response.Message
        ? // @ts-ignore
          response.Message + ' ' + sDetails
        : JSON.stringify(response) + ' ' + sDetails
    }\t${status}`;
  };

  /**
   * search header string for numeric value
   * @param sHeader header string to search
   * @param sSearch header name
   */
  const scanHeader = (sHeader: string, sSearch: string) => {
    var testRe = new RegExp(sSearch + ':\\s+(\\d+)');
    var match = testRe.exec(sHeader),
      ret = 0;
    if (match && match.length > 0) {
      ret = Number(match[1]);
      if (Number.isNaN(ret)) {
        ret = 0;
      }
    }
    return ret;
  };

  const doRequestTtdApi$ = (
    pTtdAuthToken: string
  ): Observable<IResultsData> => {
    var rqErrCnt: number = 0,
      maxRqErrCnt = maxRetries || 50,
      extraDelay = 0;
    var rateLimitParams: IRateLimitParams = { timeFrame: 0, maxCalls: 0 };
    var params = {
      method,
      shortUrl,
      data: JSON.stringify(requestFilter(sJSON, requestFilterFieldList)),
      authToken: pTtdAuthToken,
      filterError
    };

    // var { maxCallsPerMinute, shouldDelay, currentCount } = shouldAllowRequest({
    //   method,
    //   shortUrl
    // });
    // if (currentCount > maxCallsPerMinute) {
    //   extraDelay = Math.floor(currentCount / maxCallsPerMinute) * 60000;
    // }
    var desiredDelay = 0;
    // shouldDelay
    //   ? Math.floor(60000 / maxCallsPerMinute) + extraDelay
    //   : 0;
    // if (enableDebug)
    //   console.log(
    //     'desiredDelay: ' + desiredDelay + ' incl. extraDelay: ' + extraDelay
    //   );
    return of(params).pipe(
      tap(() => {
        var {
          maxCallsPerMinute,
          shouldDelay,
          currentCount
        } = shouldAllowRequest({
          method,
          shortUrl
        });
        if (currentCount > maxCallsPerMinute) {
          extraDelay = Math.floor(currentCount / maxCallsPerMinute) * 5000;
        }
        desiredDelay = shouldDelay
          ? Math.floor(60000 / maxCallsPerMinute) + extraDelay
          : 0;
        if (enableDebug) {
          console.log(
            '\n new request - shortUrl: ' +
              shortUrl +
              ' currentCount: ' +
              currentCount
          );
          console.log(
            'desiredDelay: ' +
              desiredDelay +
              ' incl. extraDelay: ' +
              extraDelay +
              '\n'
          );
        }
      }),
      delay(desiredDelay),
      mergeMap(
        (params1: ttdAjaxParams): Observable<IResultsData> => {
          return ttdAjax(params1).pipe(
            retryWhen((errors: any) =>
              // error handling
              errors.pipe(
                // wait 1 sec
                delay(3000),
                mergeMap((error: any) => {
                  // count errors
                  rqErrCnt++;
                  var myRegexp = /\/v3(.*)/im;
                  var match = myRegexp.exec(error.request.url),
                    shortUrl1;
                  if (match != null) {
                    shortUrl1 = match[1];
                  } else {
                    shortUrl1 = '';
                  }
                  registerRequest({
                    method: error.request.method,
                    shortUrl: shortUrl1
                  });
                  if (
                    error.request.url.includes('/authentication') ||
                    // also retry token errors as we might need to refresh
                    error.status === 401
                  ) {
                    if (rqErrCnt < 2) {
                      return of(error.status).pipe(delay(1500));
                    }
                    return throwError(error);
                  }
                  if (
                    (error.status === 500 || error.status === 0) &&
                    rqErrCnt < 5
                  ) {
                    // console.log('error status 500 -> retrying ... ' + rqErrCnt);
                    return of(error.status).pipe(delay(3000));
                  }
                  if (error.status === 429 && rqErrCnt < maxRqErrCnt) {
                    // try to read header information - will set 0 if not found
                    var headers = error.xhr.getAllResponseHeaders(),
                      retryAfter = scanHeader(headers, 'retry-after'),
                      timeFrame = scanHeader(headers, 'window_size'),
                      maxCalls = scanHeader(
                        headers,
                        'window_max_api_calls_allowed'
                      );
                    var extraDelay1 = 0;
                    var {
                      maxCallsPerMinute,
                      currentCount
                    } = shouldAllowRequest({
                      method,
                      shortUrl
                    });
                    if (!maxCalls) {
                      maxCalls = maxCallsPerMinute;
                    }
                    //&& /geo/i.test(shortUrl)
                    if (/geo/i.test(shortUrl) && currentCount > maxCalls) {
                      // random extra delay helps for geo end points
                      extraDelay1 =
                        Math.max(
                          0.25,
                          Math.random() * Math.floor(currentCount / maxCalls)
                        ) * 5000;
                    }
                    // configure retry with backup of 10 secs
                    retryAfter = (retryAfter * 1000 || 10000) + extraDelay1;
                    // set rate limit parameters
                    rateLimitParams = {
                      timeFrame,
                      maxCalls
                    };
                    if (enableDebug) {
                      console.log(
                        `error status 429 -> retrying ... after: ${
                          retryAfter / 1000
                        }s extraDelay ${extraDelay1} retry: ${rqErrCnt}`
                      );
                      // if (error.request.body) {
                      //   try {
                      //     var bJson = JSON.parse(error.request.body);
                      //     if (bJson.PageStartIndex) {
                      //       console.log(
                      //         'PageStartIndex: ' + bJson.PageStartIndex
                      //       );
                      //     }
                      //   } catch (e) {
                      //     //
                      //   }
                      // }
                    }
                    return of(error.status).pipe(delay(retryAfter));
                  }
                  // if (
                  //   !(
                  //     error.status === 429 ||
                  //     error.status === 500 ||
                  //     error.status === 0
                  //   )
                  // ){
                  //   console.log('errorStatus not 500, 429 or 0 -> throwing directly');
                  // }
                  // else {console.log('max retries reached -> throwing directly');}
                  return throwError(() => error);
                }, 1),
                // max 15 retries + 1 to throw error if needed
                take(maxRqErrCnt + 1)
              )
            ),
            // evaluate resource
            // tap((res) => {
            //   // console.log(' put result ' + JSON.stringify(res));
            // }),
            mergeMap((res: IResultsData) => {
              var resDt: IResultsData;
              // @ts-ignore
              resDt = {};
              //@ts-ignore
              resDt.data =
                res &&
                (disableResponseFilter
                  ? res
                  : responseFilter(res, responseFilterFieldList));
              // patch data from sJSON into response
              if (responseIncludeFieldList) {
                responseIncludeFieldList.forEach((fld) => {
                  var mJSON = sJSON;
                  if (typeof mJSON === 'string') {
                    try {
                      mJSON = JSON.parse(mJSON);
                    } catch (e) {
                      // console.error('parse JSON error: ', e);
                    }
                  }
                  if (mJSON && mJSON[fld] !== undefined) {
                    resDt.data[fld] = mJSON[fld];
                  }
                });
              }
              resDt.message = '';
              resDt.rateLimitParams = rateLimitParams;
              if (res.ResultCount) {
                resDt.count = res.ResultCount;
                resDt.totalCount = res.TotalFilteredCount;
              }
              if (res.ReferenceId) {
                resDt.ReferenceId = res.ReferenceId;
              }
              return of(resDt);
            }),
            // tap((res) => {
            //   console.log('put response', res);
            // }),
            // catches errors
            catchError((error) => {
              // console.error('Put error: ', error);
              return of({
                data: '',
                message: Error(error) // Error(error as IRequestError)
              });
            })
          );
        }
      )
    );
  };

  return doRequestTtdApi$(ttdAuthToken);
};
