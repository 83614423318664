import { Dispatch } from 'redux';
import {
  PACKAGE_PERFORMANCE_HISTORY_SUCCESS_ACTION,
  PACKAGE_PERFORMANCE_HISTORY_FAILED_ACTION,
  CLEAN_STATE,
  PackagePerformanceDetailActionTypes,
  PackagePerformanceOverviewActionTypes,
  PkgUnassignedCampaignsActionTypes,
  PKG_DETAIL_FAILED_ACTION,
  PKG_DETAIL_SUCCESS_ACTION,
  PKG_PERFORMANCE_DETAIL_FAILED_ACTION,
  PKG_PERFORMANCE_DETAIL_SUCCESS_ACTION,
  PKG_PERFORMANCE_OVERVIEW_FAILED_ACTION,
  PKG_PERFORMANCE_OVERVIEW_SUCCESS_ACTION,
  PKG_UNASSIGNED_CAMPAIGN_SUCCESS,
  PKG_UNASSIGNED_CAMPAIGN_FAILED,
  PKG_DELETE_FAILED_ACTION,
  HOMEPAGE_URL_CHANGED,
  HISTORY_CHANGED
} from '../actions/actionTypes';

interface packageState {
  performanceHistory: object;
  listPackagePerformanceOverview: Array<PackagePerformanceOverviewActionTypes>;
  packagePerformanceDetail: Array<PackagePerformanceDetailActionTypes>;
  packageDetail: {};
  packageUnassignedCampaigns: Array<PkgUnassignedCampaignsActionTypes>;
  progressPercent: number;
  errorMessage: string;
  homePageUrl: string;
  historyToggle: string;
}

const initialState: packageState = {
  performanceHistory: [],
  listPackagePerformanceOverview: [],
  packagePerformanceDetail: [],
  packageDetail: {},
  packageUnassignedCampaigns: [],
  progressPercent: 0,
  errorMessage: '',
  homePageUrl: '/',
  historyToggle: 'toolbar-action'
};

const packageReducer = (state = initialState, action) => {
  switch (action.type) {
    case PACKAGE_PERFORMANCE_HISTORY_SUCCESS_ACTION:
      return { ...state, performanceHistory: action.payload };
    case PACKAGE_PERFORMANCE_HISTORY_FAILED_ACTION:
      return { ...state, errorMessage: action.payload };
    case PKG_PERFORMANCE_OVERVIEW_SUCCESS_ACTION:
      return { ...state, listPackagePerformanceOverview: action.payload };
    case PKG_PERFORMANCE_OVERVIEW_FAILED_ACTION:
      return { ...state };
    case PKG_PERFORMANCE_DETAIL_SUCCESS_ACTION:
      return { ...state, packagePerformanceDetail: action.payload };
    case CLEAN_STATE:
      return { ...state, packagePerformanceDetail: [] };
    case PKG_DELETE_FAILED_ACTION:
      return { ...state, errMsg: action.payload.errMsg };
    case PKG_DETAIL_SUCCESS_ACTION:
      return { ...state, packageDetail: action.payload };
    case PKG_UNASSIGNED_CAMPAIGN_SUCCESS:
      return { ...state, packageUnassignedCampaigns: action.payload };
    case HOMEPAGE_URL_CHANGED:
      return {
        ...state,
        homePageUrl: action.payload
      };
    case HISTORY_CHANGED:
      return {
        ...state,
        historyToggle: action.payload
      };
    case PKG_UNASSIGNED_CAMPAIGN_FAILED:
    case PKG_DETAIL_FAILED_ACTION:
    case PKG_PERFORMANCE_DETAIL_FAILED_ACTION:

    // eslint-disable-next-line no-fallthrough
    default:
      return { ...state };
  }
};

export default packageReducer;

export const forceCampaignTableSort = (
  dispatch: Dispatch<any>,
  sortOrder?: string
) => {
  let invertSort = sortOrder == 'Desc' ? 'Asc' : 'Desc';
  setTimeout(() => {
    dispatch({
      type: '@@enhanceddataview/DATAVIEW_CONFIG_UPDATE_SORT_DIRECTION',
      payload: { id: 'campaignperformancetable', sortDirection: invertSort }
    });
  }, 0);
  setTimeout(() => {
    dispatch({
      type: '@@enhanceddataview/DATAVIEW_CONFIG_UPDATE_SORT_DIRECTION',
      payload: { id: 'campaignperformancetable', sortDirection: sortOrder }
    });
  }, 10);
};

export const forceCampaignTableSortField = (
  dispatch: Dispatch<any>,
  sortField?: string
) => {
  setTimeout(() => {
    dispatch({
      type: '@@enhanceddataview/DATAVIEW_CONFIG_UPDATE_SORT_FIELD',
      payload: { id: 'campaignperformancetable', sortField: sortField }
    });
  }, 50);
};
