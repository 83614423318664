import React, { useEffect, useState } from 'react';

import DataTable from '@ttd/maui/lib/components/display/DataTable/DataTable';
import EnhancedDataTable from '@ttd/maui/lib/components/display/DataTable/EnhancedDataTable';
import ProgressBar from '@ramonak/react-progress-bar';

const PackagePerformanceTable = ({
  isLoading,
  listPackagePerformanceOverview,
  advertiserOptions,
  onPackageSelected,
  handleUpdateParams,
  filterPackageList,
  advertiserOption
}) => {
  const [isShow, setIsShow] = useState<boolean>(false);

  const onButtonClick = () => {
    setIsShow(!isShow);
  };

  // Calculate Table Size
  const [displayRows, setDisplayRows] = useState<number>(1);
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // 275 px are needed for everything else (from header bar to table header)
      // one table row is 48 px

      // calculate leftover space
      var possibleRows = Math.max(
        1, // display at least 1 row
        Math.floor((window.innerHeight - 275) / 48)
      );
      var dataRows = listPackagePerformanceOverview
        ? listPackagePerformanceOverview.length
        : 1;
      var resultRows = Math.min(possibleRows, dataRows);
      setDisplayRows(resultRows);
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [listPackagePerformanceOverview]);

  const columns = [
    {
      id: 'package_name',
      header: (
        <div
          className="header-table"
          onClick={() => handleUpdateParams('package_name')}
        >
          Package Name
        </div>
      ),
      layout: { order: 1 },
      width: { minWidthPixels: 200, flexGrow: 1 },
      isSortable: true,
      sortComparator: DataTable.COMPARATOR.STRING('package_name'),
      renderContent: (row) => (
        <div
          className="col-name"
          onClick={() => onPackageSelected(row.package_name, row.package_id)}
        >
          {row.package_name}
        </div>
      )
    },
    {
      id: 'pacing_percent',
      header: (
        <div onClick={() => handleUpdateParams('pacing_percent')}>Pacing</div>
      ),
      layout: { order: 2 },
      width: { minWidthPixels: 60, flexGrow: 1 },
      isSortable: true,
      sortComparator: DataTable.COMPARATOR.NUMERIC('pacing_percent'),
      renderContent: (row) => (
        <div>
          <div
            style={{
              width: `${
                row.pacing_percent > 100 ? row.pacing_percent - 50 : 50
              }%`,
              position: 'relative',
              top: '10px',
              maxWidth: '100%',
              minWidth: '60px'
            }}
          >
            <ProgressBar
              labelSize={row.pacing_percent < 40 ? '0px' : '12px'}
              bgcolor={
                row.pacing_percent < 40
                  ? 'red'
                  : row.pacing_percent < 100
                  ? '#6a1b9a'
                  : row.pacing_percent === 100
                  ? '#2eaeff'
                  : '#ef6c00'
              }
              borderRadius="5px"
              completed={row.pacing_percent}
            />
          </div>
          {row.pacing_percent < 40 && (
            <div
              style={{
                width: '50%',
                height: '20px',
                zIndex: 2,
                position: 'absolute',
                top: '18px',
                left: '36%',
                fontWeight: 'bold',
                fontSize: '12px',
                color: 'white'
              }}
            >
              {(row.pacing_percent ? row.pacing_percent : 0) + '%'}
            </div>
          )}
          <div
            style={{
              width: '50%',
              visibility: row.pacing_percent > 100 ? 'visible' : 'hidden',
              position: 'relative',
              top: '-10px',
              left: '46%',
              zIndex: -1
            }}
          >
            <ProgressBar
              labelSize="0px"
              bgcolor="#ef6c00"
              borderRadius="5px"
              baseBgColor="#ffffff"
              completed={Math.max(row.pacing_percent - 100, 0)}
            />
          </div>
        </div>
      )
    },
    {
      id: 'target_budget',
      header: (
        <div onClick={() => handleUpdateParams('target_budget')}>
          Target Budget
        </div>
      ),
      layout: { order: 3 },
      width: { minWidthPixels: 90, flexGrow: 1 },
      isSortable: true,
      sortComparator: DataTable.COMPARATOR.NUMERIC('target_budget'),
      renderContent: (row) =>
        row.target_budget
          ? row.target_budget.toLocaleString(undefined, {
              minimumFractionDigits: 2
            })
          : ''
    },
    {
      id: 'total_spend',
      header: (
        <div onClick={() => handleUpdateParams('total_spend')}>
          Current Spend{' '}
        </div>
      ),
      layout: { order: 4 },
      width: { minWidthPixels: 120, flexGrow: 1 },
      isSortable: true,
      sortComparator: DataTable.COMPARATOR.NUMERIC('total_spend'),
      renderContent: (row) =>
        row.total_spend
          ? row.total_spend.toLocaleString(undefined, {
              minimumFractionDigits: 2
            })
          : ''
    },
    {
      id: 'total_impressions',
      header: (
        <div onClick={() => handleUpdateParams('total_impressions')}>
          Impressions{' '}
        </div>
      ),
      layout: { order: 5 },
      width: { minWidthPixels: 100, flexGrow: 1 },
      isSortable: true,
      sortComparator: DataTable.COMPARATOR.NUMERIC('total_impressions'),
      renderContent: (row) =>
        row.total_impressions
          ? row.total_impressions.toLocaleString(undefined, {
              minimumFractionDigits: 0
            })
          : ''
    },
    {
      id: 'budget',
      header: <div onClick={() => handleUpdateParams('budget')}>Budget </div>,
      layout: { order: 6 },
      width: { minWidthPixels: 50, flexGrow: 1 },
      isSortable: true,
      sortComparator: DataTable.COMPARATOR.NUMERIC('budget'),
      renderContent: (row) =>
        row.budget
          ? row.budget.toLocaleString(undefined, {
              minimumFractionDigits: 2
            })
          : ''
    },
    {
      id: 'end_date',
      header: (
        <div onClick={() => handleUpdateParams('end_date')}>End Date </div>
      ),
      layout: { order: 7 },
      width: { minWidthPixels: 45, flexGrow: 1 },
      isSortable: true,
      sortComparator: DataTable.COMPARATOR.STRING('end_date'),
      renderContent: (row) => (row.end_date ? row.end_date : '')
    }
  ];

  const dataTableColumns = [...columns];
  // console.log('PackagePerfDetails - isLoading: ' + isLoading);
  return (
    <>
      {!isLoading && (
        <div className="advertiser-list">
          <button className="advertiser-list--button" onClick={onButtonClick}>
            {advertiserOption.name}
            <span className={`arrow ${isShow ? 'arrow-up' : 'arrow-down'}`} />
          </button>
          <ul className={isShow ? 'is-show' : 'is-hide'}>
            {advertiserOptions.length > 0 &&
              advertiserOptions.map((item, key) => {
                return (
                  <li key={key}>
                    {item.id === advertiserOption.id ? (
                      <input
                        type="radio"
                        name="advertiser_filter"
                        id={`advertiser-option-${item.id}`}
                        onClick={() => {
                          filterPackageList(item);
                          setIsShow(!isShow);
                        }}
                        defaultChecked
                      />
                    ) : (
                      <input
                        type="radio"
                        name="advertiser_filter"
                        id={`advertiser-option-${item.id}`}
                        onClick={() => {
                          filterPackageList(item);
                          setIsShow(!isShow);
                        }}
                      />
                    )}
                    <label htmlFor={`advertiser-option-${item.id}`}>
                      {item.name}
                    </label>
                  </li>
                );
              })}
          </ul>
        </div>
      )}
      <EnhancedDataTable
        id="packageperformancetable"
        onSelectionChanged={() => {}}
        theme={DataTable.THEME.LIGHT}
        rows={listPackagePerformanceOverview}
        getPrimaryKey={(row) => row.package_id}
        bodyHeight={{ isFixed: true, heightInRows: displayRows }}
        columns={dataTableColumns}
        sorting={{
          sortAllRows: true
        }}
        isRetrievingNewPage={isLoading}
        isFiltering={false}
        isRetrievingMoreData={false}
        isMoreDataAvailable={false}
        getIsRowDisabled={(row) => false}
        getIsRowVisible={(row) => true}
      />
    </>
  );
};

export default PackagePerformanceTable;
