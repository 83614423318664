import axios from 'axios';
import { getCookies } from '../utils';

import { baseUrl } from './../vars';

export const getPackageSearch = (
  term,
  limit = 10,
  offset = 0,
  sortOrder = null,
  sortField = null
) => {
  let requestUrl = `${baseUrl}/pkg/packages?SearchTerm=${term}&Limit=${limit}&Offset=${offset}`;
  if (sortOrder !== null) {
    requestUrl = `${requestUrl}&SortOrder=${sortOrder}`;
  }
  if (sortField !== null) {
    requestUrl = `${requestUrl}&SortField=${sortField}`;
  }

  return axios
    .get(requestUrl, {
      headers: { PkgAuth: getCookies('packageToken') }
    })
    .then((packages) => {
      if (packages.status === 200 && packages.data.error === undefined) {
        return packages.data;
      }

      return [];
    })
    .catch((error) => {
      console.log(error.message);
      return [];
    });
};
