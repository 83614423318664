import React, { useEffect, useCallback, useState, useMemo } from 'react';
import _ from 'lodash';

import './PackageKPI.scss';
import SelectListControl from '@ttd/maui/lib/components/controls/SelectListControl';
import {
  CHART_NAME,
  CHART_TYPES,
  TIME_FRAME_OPTIONS,
  TIME_FRAME_TYPES
} from './types';
import ChartContainer from './ChartContainer';
import moment from 'moment';
import { getLastMonth } from '../../../utils';
import { time } from 'console';

function PackageKPI({ pkg, top, bottom, period, updatePeriod, updateCharts }) {
  // const myPkg = JSON.stringify(pkg[0] ? pkg[0].packageId : {});
  // console.log('period: ' + period + ' pkg: ' + myPkg);
  const [filteredPackageData, setFilteredPackageData] = useState([]);

  /**
   * Filter the package data down to the time frame we need to display based on the last report date
   * @argument originData - data to filter
   * @argument timeFrame - time frame to use
   * @argument lastReportDate - base date of last report
   */
  const filterChartByTimeFrame = useCallback(
    (originData = [], timeFrame, lastReportDate = '') => {
      // filter data based on lookback time frame for chart display
      let current = new Date(),
        mDt = new Date();
      if (lastReportDate) {
        current = new Date(lastReportDate);
      }
      mDt.setHours(0, 0, 0, 0);
      // console.log('filterChartByTimeFrame ' + timeFrame + lastReportDate);
      // console.log('filterChartByTimeFrame current', current.toString(), timeFrame, lastReportDate);
      let pastDate = new Date();
      let maxDate = mDt;
      // handle timeframes like "30-days" by extracting hte number before "-days"
      let testDays = Number.parseInt(timeFrame.replace('-days', ''), 10);
      if (!isNaN(testDays)) {
        pastDate = new Date(
          current.setDate(current.getDate() - (testDays - 1))
        );
      } else
        switch (timeFrame) {
          case 'month-to-date': {
            pastDate = new Date(current.getFullYear(), current.getMonth(), 1);
            break;
          }
          // last mont is special, we need to exclude all current month data by setting a max date
          case 'last-month': {
            pastDate = getLastMonth(current);
            let cm = new Date(current.getFullYear(), current.getMonth(), 1);
            maxDate = new Date(cm.setDate(cm.getDate() - 1));
            break;
          }
          default:
        }
      pastDate.setHours(0, 0, 0, 0);
      // console.log('filterChartByTimeFrame pastDate', pastDate.toString());
      // console.log('filterChartByTimeFrame MaxDate', maxDate.toString());
      let data = _.filter(originData, (item) => {
        var compDate = new Date(item.report_date).getTime();
        return compDate >= pastDate.getTime() && compDate <= maxDate.getTime();
      });
      // if we just have 1 day, then we add a previous day with all 0 to show an area in the chart
      if (data.length === 1) {
        let lDate = new Date(data[0].report_date);
        let prevDate = new Date(lDate.setDate(lDate.getDate() - 1));
        let prevDateStr = moment(prevDate).format('yyyy-MM-DD');
        // console.log('prevDateStr ' + prevDateStr);
        let dtKeys = Object.keys(data[0]);
        // set all metrics to 0 for previous day
        let prevDtData = dtKeys.reduce(
          (acc, key) => {
            acc[key] = 0;
            return acc;
          },
          { report_date: prevDateStr }
        );
        data = [prevDtData, ...data];
      }
      return data;
    },
    []
  );

  // filters data to the period we need to display
  useEffect(() => {
    setFilteredPackageData(
      pkg && pkg[0]
        ? filterChartByTimeFrame(
            pkg[0]['dates'],
            period,
            pkg[0]['last_report_date']
          )
        : []
    );
  }, [pkg, period, filterChartByTimeFrame]);

  return (
    <>
      <div className="chart-header">
        <h3>Package Charts</h3>
      </div>

      <div className="top-chart-container">
        <div data-testid="time-frame" id="time-frame" className="time-frame">
          <span>Charting Period:</span>
          <SelectListControl
            className="time-frame-select"
            id="time-frame-select"
            displayNameKey="name"
            primaryKey="id"
            value={TIME_FRAME_TYPES[period]}
            onChange={updatePeriod}
            availableItems={TIME_FRAME_OPTIONS}
            allowEmptySelection={false}
            placeHolder="Select time frame"
          />
        </div>
        <ChartContainer
          packageData={filteredPackageData}
          chartId={CHART_NAME.TOP_CHART}
          chartType={CHART_TYPES[top]}
          onChangeType={(type) => updateCharts(type.id, undefined)}
          disabledType={CHART_TYPES[bottom]}
        />
      </div>

      <div className="bottom-chart-container">
        <ChartContainer
          packageData={filteredPackageData}
          chartId={CHART_NAME.BOTTOM_CHART}
          chartType={CHART_TYPES[bottom]}
          onChangeType={(type) => updateCharts(undefined, type.id)}
          disabledType={CHART_TYPES[top]}
        />
      </div>
    </>
  );
}

export default PackageKPI;
