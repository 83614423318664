export interface IAdvertiserPayloadPartnerId {
  after?: string | null;
}

export const advertiserPayloadPartnerId = function (
  firstOnly: boolean = false
): string {
  var body = {
    query: `query Advertisers ($first: Int!, $after: String) {
              advertisers(first: $first, after:$after) {
                pageInfo { endCursor hasNextPage }
                totalCount
                edges {
                    node {
                        AdvertiserId:id
                        partner {
                            PartnerId:id
                        }
                    }
                }
              }
            }`,
    variables: {
      first: 650,
      after: null
    }
  };
  if (firstOnly) {
    body.variables.first = 1;
  }
  // convert to JSON string
  return JSON.stringify(body);
};

export interface ICampaignFlightsPayloadAdvId {
  advertiserIds: string[];
  after?: string | null;
  endDate: string;
}

// The specific payload builder function
export const campaignFlightsPayloadAdvId = function (
  jsonVars: ICampaignFlightsPayloadAdvId,
  firstOnly: boolean = false,
  kokaiOnly: boolean = false
): string {
  var body = {
    query: `query CampaignAndFlights ($advertiserIds: [String!], $first: Int!, $after: String, $endDate: DateTime) {
            campaigns( where: { advertiserId: { in: $advertiserIds }, isTemplate: { eq: false },
            isProgrammaticGuaranteed: { eq: false } , endDate: { gt: $endDate }
            # version: { eq: KOKAI }
            }
            first: $first, after:$after) {
                pageInfo { endCursor hasNextPage }
                totalCount
            edges {
                cursor
                node {
                    CampaignId: id
                    CampaignName: name
                    StartDate: startDate
                    EndDate: endDate
                    PacingMode: pacingMode
                    TimeZone: timeZoneIANA
                    CreatedAtUTC: createdAtUtc
                    LastUpdatedAtUTC: lastUpdatedAtUtc
                    # isProgrammaticGuaranteed
                    version
                    CampaignFlights: flights( where: { isDeleted: { eq: false } } ) {
                        totalCount
                        nodes {
                            CampaignFlightId: id
                            StartDateInclusiveUTC: startDateInclusiveUTC
                            EndDateExclusiveUTC: endDateExclusiveUTC
                            BudgetInAdvertiserCurrency: budgetInAdvertiserCurrency
                            BudgetInImpressions: budgetInImpressions
                            DailyTargetInAdvertiserCurrency: dailyTargetInAdvertiserCurrency
                            DailyTargetInImpressions: dailyTargetInImpressions
                            }
                            }
                            advertiser { AdvertiserId: id }
                            partner { PartnerId: id }
                            }
                            }
                            }
                            }`,
    // c-Spell:disable
    variables: {
      advertiserIds: [],
      first: 650,
      after: null,
      endDate: '2024-01-01'
    }
  };
  if (firstOnly) {
    body.variables.first = 1;
  }
  if (kokaiOnly) {
    body.query = body.query.replace('# version:', 'version:');
  }
  var applyVars = Object.keys(jsonVars).reduce((acc, key) => {
    if (jsonVars[key] === undefined && key !== 'after') {
      throw new Error(`Missing required variable: ${key}`);
    }
    acc[key] = jsonVars[key];
    return acc;
  }, body.variables);
  body.variables = applyVars;
  // convert to JSON string
  return JSON.stringify(body);
};

export interface ICampaignFlightsPayloadCampId {
  campaignIds: string[];
  after?: string | null;
  endDate: string;
}

// The specific payload builder function
export const campaignFlightsPayloadCampId = function (
  jsonVars: ICampaignFlightsPayloadCampId,
  firstOnly: boolean = false,
  kokaiOnly: boolean = false
): string {
  var body = {
    query: `query CampaignAndFlights ($campaignIds: [String!], $first: Int!, $after: String, $endDate: DateTime) {
            campaigns( where: { id: { in: $campaignIds }, isTemplate: { eq: false },
            isProgrammaticGuaranteed: { eq: false } , endDate: { gt: $endDate }
            # version: { eq: KOKAI }
            }
            first: $first, after:$after) {
                pageInfo { endCursor hasNextPage }
                totalCount
            edges {
                cursor
                node {
                    CampaignId: id
                    CampaignName: name
                    StartDate: startDate
                    EndDate: endDate
                    PacingMode: pacingMode
                    TimeZone: timeZoneIANA
                    CreatedAtUTC: createdAtUtc
                    LastUpdatedAtUTC: lastUpdatedAtUtc
                    # isProgrammaticGuaranteed
                    version
                    CampaignFlights: flights( where: { isDeleted: { eq: false } } ) {
                        totalCount
                        nodes {
                            CampaignFlightId: id
                            StartDateInclusiveUTC: startDateInclusiveUTC
                            EndDateExclusiveUTC: endDateExclusiveUTC
                            BudgetInAdvertiserCurrency: budgetInAdvertiserCurrency
                            BudgetInImpressions: budgetInImpressions
                            DailyTargetInAdvertiserCurrency: dailyTargetInAdvertiserCurrency
                            DailyTargetInImpressions: dailyTargetInImpressions
                            }
                            }
                            advertiser { AdvertiserId: id }
                            partner { PartnerId: id }
                            }
                            }
                            }
                            }`,
    // c-Spell:disable
    variables: {
      campaignIds: [],
      first: 650,
      after: null,
      endDate: '2024-01-01'
    }
  };
  if (firstOnly) {
    body.variables.first = 1;
  }
  if (kokaiOnly) {
    body.query = body.query.replace('# version:', 'version:');
  }
  var applyVars = Object.keys(jsonVars).reduce((acc, key) => {
    if (jsonVars[key] === undefined && key !== 'after') {
      throw new Error(`Missing required variable: ${key}`);
    }
    acc[key] = jsonVars[key];
    return acc;
  }, body.variables);
  body.variables = applyVars;
  // convert to JSON string
  return JSON.stringify(body);
};

export interface IGetBulkJobProgressPayloadId {
  jobId: string;
}

export const getBulkJobProgressPayloadId = function (
  jsonVars: IGetBulkJobProgressPayloadId
): string {
  var body = {
    query: `query GetBulkJobProgress($jobId: ID!) {
    jobProgress(id: $jobId) {
      id
      jobStatus
      resultUrl
      validationErrors
    }
  }`,
    // c-Spell:disable
    variables: {
      jobId: '0'
    }
  };
  body.variables.jobId = jsonVars.jobId;
  // convert to JSON string
  return JSON.stringify(body);
};

export interface IEmptyPayload {}

export const getFileUploadPayload = function (jsonVars: IEmptyPayload): string {
  var body = {
    query: `mutation IdUpload {
    fileUpload {
      id
      uploadUrl
    }
  }`
  };
  // convert to JSON string
  return JSON.stringify(body);
};

export interface IBulkUploadPayload {
  advertiserId: string;
  uploadId: string;
}

/**
 * Creates a dynamic GraphQL payload for bulk operations.
 *
 * @param mutationName - The name of the mutation (e.g. BulkCampaignCreation, BulkCampaignUpdate).
 * @param mutationObject - The name of the mutation object (e.g. bulkCreateCampaigns, bulkUpdateCampaigns).
 * @param jsonVars - The variables required for the mutation (advertiserId, uploadId).
 * @returns A stringified JSON payload for the GraphQL mutation.
 */
export const bulkOperationBasePayload = function (
  mutationName: string,
  mutationObject: string,
  jsonVars: IBulkUploadPayload
): string {
  const body = {
    query: `
      mutation ${mutationName}($advertiserId: ID!, $uploadId: ID!) {
        ${mutationObject}(
          input: {
            advertiserId: $advertiserId,
            fileId: $uploadId
          }
        ) {
          data {
            id
          }
          userErrors {
            field
            message
          }
        }
      }
    `,
    variables: {
      advertiserId: jsonVars.advertiserId,
      uploadId: jsonVars.uploadId
    }
  };

  // Convert the GraphQL query and variables to a JSON string
  return JSON.stringify(body);
};

// For bulk campaign creation
export const bulkCreateCampaignsPayload = (
  jsonVars: IBulkUploadPayload
): string => {
  return bulkOperationBasePayload(
    'BulkCampaignCreation',
    'bulkCreateCampaigns',
    jsonVars
  );
};

// For bulk campaign flight creation
export const bulkCreateCampaignFlightsPayload = (
  jsonVars: IBulkUploadPayload
): string => {
  return bulkOperationBasePayload(
    'BulkCampaignFlightCreation',
    'bulkCreateCampaignFlights',
    jsonVars
  );
};

// For bulk campaign update
export const campaignUpdatePayload = (jsonVars: IBulkUploadPayload): string => {
  return bulkOperationBasePayload(
    'BulkCampaignUpdate',
    'bulkUpdateCampaigns',
    jsonVars
  );
};

// For bulk campaign flight update
export const campaignFlightUpdatePayload = (
  jsonVars: IBulkUploadPayload
): string => {
  return bulkOperationBasePayload(
    'BulkCampaignFlightUpdate',
    'bulkUpdateCampaignFlights',
    jsonVars
  );
};

/* query AdGroupsAndFlights(
    $advertiserIds: [String!]
    $first: Int!
    $after: String
) {
    adGroups(
        where: {
            advertiserId: { in: $advertiserIds }
            isArchived: { eq: false }
            isTemplate: { eq: false }
        }
        first: $first
        after: $after
    ) {
        totalCount
        pageInfo {
            hasNextPage
            endCursor
        }
        edges {
            node {
                AdGroupFlights: adGroupFlights {
                    nodes {
                        adGroupId
                        campaignFlightId
                        budgetInAdvertiserCurrency
                        dailyTargetInAdvertiserCurrency
                    }
                }
                AdGroupId: id
                campaign {
                    CampaignId: id
                    Version: version
                }
                IsEnabled: isEnabled
                AdGroupName: name
                campaignStackRank
            }
        }
    }
}
{"advertiserIds": ["4j378qw", "a8xtj2o", "khs3gdx", "vcmgkd4"],
"first": 1000,
"after":null}
*/

// module.exports = { campaignFlightsPayloadAdvId, campaignFlightsPayloadCampId };

/*
query GetBulkJobProgress($jobId: ID!) {
    jobProgress(id: $jobId) {
      jobStatus
      validationErrors
    }
  }

mutation {
    fileUpload {
      id
      uploadUrl
    }
  }

  const fileUploadMutation: IQueryGQL<IEmptyPayload> = {
  ttdAuthToken: "your_auth_token_here",
  payloadBuilder: getFileUploadPayload, // Function to build payload
  jsonVars: {}, // No variables needed, so pass an empty object
};

const bulkJobProgressQuery: IQueryGQL<IGetBulkJobProgressPayloadId> = {
  ttdAuthToken: "your_auth_token_here",
  payloadBuilder: getBulkJobProgressPayloadId,
  jsonVars: { jobId: "12345" },
};

  */
