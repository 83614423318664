import produce from 'immer';
export interface progressStep {
  stepKey: string;
  stepName: string;
  percentComplete: number;
  showPercentDetails: true;
  isComplete: boolean;
  isCompleteStep?: boolean;
}

export const exportBase: progressStep[] = [
  {
    stepKey: 'one',
    stepName: 'Export Packages',
    percentComplete: 0.01,
    showPercentDetails: true,
    isComplete: false
  },
  {
    stepKey: 'two',
    stepName: 'Export Campaigns',
    percentComplete: 0.01,
    showPercentDetails: true,
    isComplete: false
  }
];

export const updateBase: progressStep[] = [
  {
    stepKey: 'one',
    stepName: 'Reading Data',
    percentComplete: 0.01,
    showPercentDetails: true,
    isComplete: false
  },
  {
    stepKey: 'two',
    stepName: 'Data Verification',
    percentComplete: 0.01,
    showPercentDetails: true,
    isComplete: false
  },
  {
    stepKey: 'three',
    stepName: 'Campaign Jobs',
    // stepSummary
    percentComplete: 0.01,
    showPercentDetails: true,
    isComplete: false
  },
  {
    stepKey: 'four',
    stepName: 'Cloning Jobs',
    percentComplete: 0.01,
    showPercentDetails: true,
    isComplete: false
  },
  {
    stepKey: 'five',
    stepName: 'Package Jobs',
    percentComplete: 0.01,
    showPercentDetails: true,
    isComplete: false
  }
];

export const UPDATE_INDEX_STEP = 'UPDATE_INDEX_STEP';
export const SET_EXPORT_BASE = 'SET_EXPORT_BASE';
export const SET_UPDATE_BASE = 'SET_UPDATE_BASE';
export const HIDE_STEP = 'HIDE_STEP';

interface stepProgress {
  steps: progressStep[];
  currentStepIndex: number;
  display: boolean;
}

const initialState: stepProgress = {
  steps: [],
  currentStepIndex: 0,
  display: false
};

const stepProgressReducer = produce((draft: stepProgress, action) => {
  // console.log('SP Red: ' + JSON.stringify(action.payload));
  //@prettier-ignore
  switch (action.type) {
    case UPDATE_INDEX_STEP:
      var steps = draft.steps;
      var { percent, currentStepIndex } = action.payload;
      if (percent > 1) {
        // scale down to percentage
        percent = Math.min(percent / 100, 1);
      }
      if (steps && currentStepIndex !== undefined) {
        var currentStep = steps[currentStepIndex];
        if (currentStep) {
          currentStep.percentComplete = percent;
          if (percent >= 1) {
            currentStep.isComplete = true;
            // console.log('currentStep: ' + currentStep + ' complete ');
          }
          if (currentStepIndex > 0) {
            var prevStep = steps[currentStepIndex - 1];
            prevStep.percentComplete = 1;
            prevStep.isComplete = true;
            // console.log('prevStep: ' + (currentStepIndex - 1) + ' completed ');
          }
        }
      }
      // prettier-ignore
      Object.assign(draft, {currentStepIndex, steps, display: true });
      // console.log('SP Update Index Step  ' + JSON.stringify(draft));
      break;
    case SET_EXPORT_BASE:
      // prettier-ignore
      Object.assign(draft, {currentStepIndex: 0, steps: exportBase, display: true });
      break;
    case SET_UPDATE_BASE:
      // prettier-ignore
      Object.assign(draft, { currentStepIndex: 0, steps: updateBase, display: true });
      break;
    case HIDE_STEP:
      // prettier-ignore
      Object.assign(draft, { currentStepIndex: 0, steps: [], display: false });
  }
}, initialState);

export default stepProgressReducer;
