import { Observable } from 'rxjs';
import { IRequestError, requestTtdApi$ } from './requestTtdApi';

enum DateStrBrand {}
export type DateStr = string & DateStrBrand;
export type Int = number & { __int__: void };
export type YesNo = string & ('Yes' | 'No');

interface adGroupNameLookup {
  [key: string]: string;
}

export interface ICloneCampaign {
  sJSON?: Object;
  TargetAdvertiserId?: string;
  CampaignId: string;
  CampaignName: string;
  AdGroupIds?: string[];
  ShouldCloneAdGroupBudgets?: boolean | YesNo;
  UpdateAdGroupBudgets?: boolean;
  EnableAdGroupIds?: string[];
  CampaignFlights?: CampaignFlight[];
  baseCampaign?: string;
  agIds?: adGroupNameLookup;
  agNielsen?: any;
  ttdAuthToken: string;
  [key: string]: any;
}

export interface ICLoneCampaignExtraData {
  runIdx?: number;
  rowIdx?: number;
  UpdateAdGroupBudgets?: boolean;
}
export interface ICloneCampaignData {
  ReferenceId?: string;
  data?: ICLoneCampaignExtraData;
  message: string | IRequestError;
}

export interface CampaignFlight {
  CampaignId?: string;
  StartDateInclusiveUTC: DateStr;
  EndDateExclusiveUTC?: DateStr;
  BudgetInAdvertiserCurrency: number;
  BudgetInImpressions?: Int;
  DailyTargetInAdvertiserCurrency?: number;
  DailyTargetInImpressions?: Int;
  [key: string]: any;
}

export interface ICloneCampaignJSON {
  TargetAdvertiserId?: string;
  CampaignId: string;
  CampaignName: string;
  AdGroupIds?: string[];
  ShouldCloneAdGroupBudgets?: boolean | YesNo;
  EnableAdGroupIds?: string[];
  CampaignFlights?: CampaignFlight[];
  [key: string]: any;
}

const cloneCampaignJSON = (jsonArgs: ICloneCampaignJSON) => {
  // only CampaignId and CampaignName are required
  let retJson = Object.keys(jsonArgs).reduce(
    (acc: Object, param: any) => {
      switch (typeof jsonArgs[param]) {
        case 'string': // campaign Id + name -> should not be empty; if it happens we'll get an intensional API error for a missing property
          //if (jsonArgs[param]) {
          var val;
          switch (param) {
            case 'TargetAdvertiserId':
              val = jsonArgs[param];
              if (val) {
                //@ts-ignore
                acc[param] = val;
              }
              break;
            case 'ShouldCloneAdGroupBudgets':
              val = jsonArgs[param];
              if (/yes/i.test(val)) {
                //@ts-ignore
                acc[param] = true;
              } else if (/no/i.test(val)) {
                //@ts-ignore
                acc[param] = false;
              }
              break;
            // don't forward to API - this parameter is used by  progress reporting only
            case 'baseCampaign':
              break;
            // don't forward to API - this parameter is for post processing only
            case 'KeepAdGroupAutoOptimization':
              break;
            // case 'CampaignName':
            //   var val = jsonArgs[param];
            //   val = val.replace(' kf_clone', '');
            //   //@ts-ignore
            //   acc[param] = val;
            //   break;
            default:
              //@ts-ignore
              acc[param] = jsonArgs[param];
          }

          break;
        case 'boolean': // should clone budgets will be boolean; missing values won't be forwarded and are ignored
          //@ts-ignore
          acc[param] = jsonArgs[param];
          break;
        case 'number':
          // pass trough
          acc[param] = jsonArgs[param];
          break;
        default:
          // agLookup is only used for waring messages
          if (param !== 'agIds') {
            // adgroup ids are string arrays and should no be empty - empty ones will not be forwarded and that's fine
            var arrVal = jsonArgs[param];
            if (Array.isArray(arrVal) && arrVal.length > 0) {
              //@ts-ignore
              acc[param] = jsonArgs[param];
            }
          }
      }
      return acc;
    },
    //@ts-ignore
    {
      /*TargetAdvertiserId: 'i29c20w' */
    } as ICloneCampaignJSON
  );
  // console.log('cloneCampaignJSON' + JSON.stringify(retJson, null, 2), retJson);
  // debugger;
  return retJson;
};

export const postCampaignClone$ = (
  props: ICloneCampaign
): Observable<ICloneCampaignData> => {
  var { ttdAuthToken, sJSON } = props;
  var errorId = 'CampaignId';
  return requestTtdApi$({
    ttdAuthToken,
    // @ts-ignore
    sJSON: cloneCampaignJSON(sJSON),
    method: 'POST',
    shortUrl: '/campaign/clone',
    requestFilterFieldList: [
      'agIds',
      'KeepAdGroupAutoOptimization',
      'baseCampaign',
      'runIdx',
      'rowIdx',
      'UpdateAdGroupBudgets'
    ],
    responseFilterFieldList: ['ReferenceId', 'message'],
    responseIncludeFieldList: ['runIdx', 'rowIdx', 'UpdateAdGroupBudgets'],
    errorId
  });
};
