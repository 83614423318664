import { Observable } from 'rxjs';
import { IRequestError, IResultsData, requestTtdApi$ } from './requestTtdApi';

export interface ICloneCampaignStatus {
  ReferenceId?: string;
  runIdx?: number;
  rowIdx?: number;
  ttdAuthToken: string;
}

export interface ICloneCampaignStatusData {
  ReferenceId?: string;
  ProgressPercentage?: Number;
  Status?: string;
  CampaignId?: string;
  CloneReport?: Array<any>;
  FailureMessage?: string;
  FailedAdGroupIds?: Object;
  AdGroupIdMap?: Object;
  message: string | IRequestError;
  // all below might be parsed in from clone request 
  runIdx?: number;
  rowIdx?: number;
  UpdateAdGroupBudgets?: boolean;
}

export const readCampaignCloneStatus$ = (
  props: ICloneCampaignStatus
): Observable<IResultsData> => {
  let { ReferenceId, ttdAuthToken } = props;
  var errorId = 'ReferenceId';
  // untypical query parameters, so don't use readQuery$ here
  return requestTtdApi$({
    ttdAuthToken,
    method: 'GET',
    shortUrl: '/campaign/clone/status/' + ReferenceId,
    responseFilterFieldList: [],
    disableResponseFilter: true,
    errorId
  });
};
