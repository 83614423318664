import React, { useEffect, useState, useCallback, useRef, memo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

import './PackagePacing.scss';

function PackagePacing({ pkg }) {
  const [reportsOutdated, setReportsOutdated] = useState(false);
  const [contMsg, setContMsg] = useState({
    ending: '',
    targetBudget: '',
    currentSpend: '',
    mtdSpend: '',
    totalBudget: '',
    runTime: '',
    lastReportDate: ''
  });
  const [chartOptions, setChartOptions] = useState({});

  var chartRef = useRef();
  window.Highcharts = Highcharts;
  const genChartOptions = useCallback(() => {
    var data,
      chartData: Array<any> = [],
      circleText = '';
    if (pkg.length > 0) {
      data = pkg[0];
      // console.log("circle base " + JSON.stringify(data));
      // data for circle
      var spend = data.pacing_percent,
        budgetPercent = spend < 100 ? 100 - spend : 0;
      circleText =
        spend >= 100
          ? 'projected to spend the<br> full package budget'
          : 'projected to underspend';
      chartData = [
        { y: spend, name: '', color: '#004fc2' },
        { y: budgetPercent, name: '', color: '#a2bbfb' }
      ];
    }
    return {
      // prettier-ignore
      chart: { renderTo: 'donutchart', type: 'pie', plotBorderWidth: 0, plotShadow: false,  height: '100%', spacingTop: 0 },
      loading: { hideDuration: 1000, showDuration: 1000 },
      credits: { enabled: false },
      // prettier-ignore
      title: {text: circleText,align: 'center',verticalAlign: 'middle',y: -20},
      // prettier-ignore
      tooltip: { formatter: function () { // @ts-ignore
          return `Total: ${this.y}%`; }},
      // prettier-ignore
      accessibility: {description: '', point: {valueSuffix: '%' }},
      // prettier-ignore
      plotOptions:{pie:{borderWidth:0,dataLabels:{enabled:true,distance:-50,style:{fontWeight:'bold',color:'white'}},startAngle:-90,endAngle:90, center: ['50%', '50%']}},
      // prettier-ignore
      series:[{type:'pie',name:'Total',innerSize:'96%',pointPadding:0,groupPadding:0,
        data: chartData
          }]
    };
  }, [pkg]);

  useEffect(() => {
    if (pkg.length > 0) {
      // @ts-ignore
      if (chartRef && chartRef.current && chartRef.current.hideLoading)
        // @ts-ignore
        chartRef.current.hideLoading();
    }
    var myOptions = genChartOptions();
    setChartOptions(myOptions);
  }, [genChartOptions, pkg]);

  useEffect(() => {
    var data;
    const formatNumber = (number, decimals, text) => {
      var sNum = '';
      if (number) {
        sNum = number.toLocaleString(undefined, {
          minimumFractionDigits: decimals
        });
      }
      if (text && sNum) {
        sNum += ' ' + text;
      }
      return sNum;
    };

    const formatDate = (date) => {
      var sDate = '';
      if (date) {
        sDate = moment(date).format('yyyy/MM/DD');
      }
      return sDate;
    };

    const sumJsonArr = (
      mArr: Array<any>,
      key: string,
      start: number,
      end: number
    ) => {
      if (
        !Array.isArray(mArr) ||
        mArr.length === 0 ||
        mArr.length < end ||
        !key ||
        start > end
      ) {
        return 0;
      }
      if (mArr[0][key] === undefined || Number.isNaN(mArr[0][key])) {
        return 0;
      }
      var sum = 0;
      for (var i = 0; i < end - start + 1; i++) {
        var val = Number(mArr[start + i][key]);
        sum += val;
      }
      var ret = Math.round(sum * 100) / 100;
      return ret;
    };

    const getMtdData = (dateArr) => {
      var spendCurMonth = 0;
      // impsCurMonth = 0;
      if (Array.isArray(dateArr) && dateArr.length > 0) {
        var dtLen = dateArr.length;
        // start and end date of our reports
        var // sDtStart = dates[0].report_date,
          sDtEnd = dateArr[dtLen - 1].report_date,
          // startYear = Number(sDtStart.substring(0, 5)),
          // startMonth = Number(sDtStart.substring(5, 8)),
          // startDay = Number(sDtStart.substring(8, 10)),
          // endYear = Number(sDtEnd.substring(0, 5)),
          // endMonth = Number(sDtEnd.substring(5, 8)),
          endDay = Number(sDtEnd.substring(8, 10)),
          // dayOfWeek = new Date(sDtEnd).getDay(),
          // protect against missing dates
          monthStart = Math.max(dtLen - endDay, 0);
        // var curMonthArr = dates.slice(dtLen - endDay, dtLen);
        // log(JSON.stringify(curMonthArr));
        spendCurMonth = sumJsonArr(dateArr, 'spend', monthStart, dtLen - 1);
        // impsCurMonth = sumJsonArr(dateArr,'impressions',monthStart, dtLen - 1);
      }
      return spendCurMonth;
    };

    if (pkg.length > 0) {
      data = pkg[0];
      // package display data
      var reportDateStart = data.min_campaign_start_date,
        reportDateEnd = data.max_campaign_end_date,
        today = new Date(),
        endDate = new Date(reportDateEnd),
        oneDay = 1000 * 60 * 60 * 24,
        daysLeft = Math.ceil((endDate.getTime() - today.getTime()) / oneDay),
        ending =
          daysLeft < 0
            ? 'last campaign already ended'
            : daysLeft +
              ' day' +
              (daysLeft > 1 ? 's' : '') +
              ' left until last campaign ends',
        targetBudget =
          ' ' + formatNumber(data.target_budget, 2, 'Target Budget'),
        currentSpend = ' ' + formatNumber(data.total_spend, 2, 'Current Spend'),
        totalBudget = ' ' + formatNumber(data.budget, 2, 'Total Budget'),
        mtdSpend =
          ' ' + formatNumber(getMtdData(data.dates), 2, 'Current Month Spend'),
        lastReportDate =
          'Last report date: ' + formatDate(data.last_report_date),
        runTime =
          'Package run time: ' +
          formatDate(reportDateStart) +
          ' - ' +
          formatDate(reportDateEnd);
      setContMsg({
        ending,
        targetBudget,
        currentSpend,
        mtdSpend,
        totalBudget,
        lastReportDate,
        runTime
      });
    }
  }, [pkg]);

  useEffect(() => {
    var data;
    if (pkg.length > 0) {
      data = pkg[0];
      var reportDate = data.report_date;
      // calculate yesterday and cut off all hours
      const yesterday = new Date(
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24)
          .toISOString()
          .replace(/T.*/, '')
      );
      // set either last report date or today
      var comp = reportDate ? new Date(reportDate) : new Date(),
        tDiff = yesterday.getTime() - comp.getTime();
      // we have outdated reports if the date difference is greater 0
      setReportsOutdated(tDiff > 0);
      // setReportsOutdated(true);
    }
  }, [pkg]);

  const chartCallback = useCallback(
    (myChart) => {
      if (pkg.length === 0) {
        myChart.showLoading('Loading Data ...');
      } else {
        myChart.hideLoading();
      }
      chartRef.current = myChart;
    },
    [pkg.length]
  );

  return (
    <>
      <div className="package_pacing_heading">
        <h3>Package Pacing</h3>
      </div>
      {/* <div id="package_pacing_chart" className="package-pacing-chart" /> */}
      <HighchartsReact
        highcharts={Highcharts}
        // @ts-ignore
        options={chartOptions}
        containerProps={{
          id: 'package_pacing_chart',
          className: 'package-pacing-chart'
        }}
        callback={chartCallback}
      />
      <div className="package-pacing-chart__content">
        <p>
          <i>{contMsg.ending}</i>
        </p>
        <i>{contMsg.runTime}</i>
        <p />
        <div
          className={
            reportsOutdated ? 'package-pacing-chart__content--outdated' : ''
          }
        >
          <i>{contMsg.lastReportDate}</i>
        </div>
        <ul>
          <li key="targetBudget">
            <span className="dot light-blue" />
            <strong>{contMsg.targetBudget}</strong>
          </li>
          <li key="currentSpend">
            <span className="dot dark-blue" />
            <strong>{contMsg.currentSpend} </strong>
          </li>
          <li key="totalBudget">
            <span className="dot grey" />
            <strong>{contMsg.totalBudget} </strong>
          </li>
          <li key="mtdSpend">
            <span className="dot yellow" />
            <strong>{contMsg.mtdSpend} </strong>
          </li>
        </ul>
      </div>
    </>
  );
}

export default memo(PackagePacing);
