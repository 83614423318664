export const responseFilter = (data, fieldList) => {
  /**
   * returns object value or '' if undefined
   * @param pObject - object to test
   * @param valDefault - (optional) default value to use or ''
   */
  var valOrEmpty = (pObject, valDefault = '') => {
    if (typeof pObject === 'undefined') {
      // @ts-ignore
      return valDefault;
    }
    // @ts-ignore
    return pObject;
  };
  /**
   * return key or '' if key is undefined
   * @param {*} pObject - object to test
   * @param {*} pKey - key
   */
  var keyOrEmpty = (pObject, pKey) => {
    return valOrEmpty(pObject[pKey]);
  };
  var arrayJsonFilter = (aObjectArray, aKeyList, startObj = {}) => {
    var cO = startObj;
    // indicates the search ended
    var skipFurtherSearch = false;
    aKeyList.reduce((accObj, searchKey, idx, srcArr) => {
      if (skipFurtherSearch) return accObj;

      if (Array.isArray(accObj)) {
        // search all elements for key list

        // find elements kontaining he current search key
        var accObjFiltered = accObj.filter((elVal) => {
          return elVal[searchKey] !== undefined;
        });

        if (accObjFiltered.length === 0) {
          // nothing found -> end search here
          skipFurtherSearch = true;
          cO[searchKey] = '';
          return accObjFiltered;
        }

        // keys we still need to find
        var remainingKeys = srcArr.slice(idx + 1);
        var searchKeyArr = accObjFiltered.map((el) => {
          if (remainingKeys.length > 0) {
            return arrayJsonFilter(el[searchKey], remainingKeys, {});
          }
          // return keyOrEmpty( el,searchKey);
          return valOrEmpty(el[searchKey]);
        });

        if (remainingKeys.length === 0) {
          cO[searchKey] = searchKeyArr;
          return accObjFiltered;
        }

        var nextKey = remainingKeys[0];
        var keyObj = {};
        keyObj[nextKey] = [];
        cO[searchKey] = searchKeyArr.reduce((acc, el) => {
          acc[nextKey].push(...el[nextKey]);
          return acc;
        }, keyObj);
        // console.log(cO[searchKey]);
        skipFurtherSearch = true;
        return accObjFiltered;
      }
      // search Object for key list
      try {
        // determine required depth
        var l = srcArr.length - 1;
        // remember last object for Array handling
        var accObjOld = accObj;
        // get current level element
        // eslint-disable-next-line no-param-reassign
        accObj = accObj[searchKey];
        if (idx === l) {
          // last Object level -> assign value
          // @ts-ignore
          if (Array.isArray(accObjOld)) {
            // @ts-ignore
            cO[searchKey] = accObjOld.map((el) => keyOrEmpty(el, searchKey));
          } else {
            cO[searchKey] = valOrEmpty(accObj);
          }
        } else {
          // higher level -> make sure we have an object to return later
          // @ts-ignore
          if (!cO[searchKey]) {
            // @ts-ignore
            cO[searchKey] = {};
          }
          // set current return object to level we need
          // @ts-ignore
          cO = cO[searchKey];
        }
      } catch (e) {
        // happens e.g. for PG ad (non RTB)
        // console.log(e.message);
        // @ts-ignore
        cO[searchKey] = '';
      }
      // @ts-ignore
      return accObj;
    }, aObjectArray);
    return startObj;
  };

  // does the filtering
  var processFieldList = (mData) => {
    // safety for empty list / wrong list type
    if (!Array.isArray(fieldList) || fieldList.length === 0) {
      return mData;
    }
    var retObj = {};
    fieldList.forEach((expr) => {
      // multiple levels are separated by '.'
      var eArr = expr.split('.');
      var cO = retObj;
      arrayJsonFilter(mData, eArr, cO);
    });
    return retObj;
  };
  var mData;
  // provide the right data to filter function
  // @ts-ignore
  if (data && Array.isArray(data)) {
    // normal JSON Array, and not a TTD API response
    // @ts-ignore
    mData = data;
    // @ts-ignore
    return mData.map((el) => {
      return processFieldList(el);
    });
  }
  // @ts-ignore
  if (data && data.Result && Array.isArray(data.Result)) {
    // TTD API reponse with Array of JSON objects in data.Result
    // @ts-ignore
    mData = data.Result;
    // @ts-ignore
    var retData = mData.map((el) => {
      return processFieldList(el);
    });
    return retData;
  }
  // simple JSON object, not an Array
  // @ts-ignore
  mData = data.Result ? data.Result : data;
  var retData1 = processFieldList(mData);
  return retData1;
};
// module.exports = { responseFilter };
