export const START_REQUEST = 'START_REQUEST';
import Homepage from '../screens/Homepage/index';
export const STOP_REQUEST = 'STOP_REQUEST';
export const USER_SUCCESS_ACTION = 'USER_SUCCESS_ACTION';
export const USER_FAILED_ACTION = 'USER_FAILED_ACTION';
export const SET_TOKEN_SUCCESS_ACTION = 'SET_TOKEN_SUCCESS_ACTION';
export const SET_TOKEN_FAILED_ACTION = 'SET_TOKEN_FAILED_ACTION';
export const SET_USER_CREDENTIAL_SUCCESS_ACTION =
  'SET_USER_CREDENTIAL_SUCCESS_ACTION';
export const SET_USER_CREDENTIAL_FAILED_ACTION =
  'SET_USER_CREDENTIAL_FAILED_ACTION';
export const USER_LOGOUT_SUCCESS_ACTION = 'USER_LOGOUT_SUCCESS_ACTION';
export const USER_LOGOUT_FAILED_ACTION = 'USER_LOGOUT_FAILED_ACTION';
export const RESET_USER_ERROR_MESSAGE = 'RESET_USER_ERROR_MESSAGE';
export const PKG_PERFORMANCE_OVERVIEW_SUCCESS_ACTION =
  'PKG_PERFORMANCE_OVERVIEW_SUCCESS_ACTION';
export const PKG_PERFORMANCE_OVERVIEW_FAILED_ACTION =
  'PKG_PERFORMANCE_OVERVIEW_FAILED_ACTION';
export const PKG_PERFORMANCE_DETAIL_SUCCESS_ACTION =
  'PKG_PERFORMANCE_DETAIL_SUCCESS_ACTION';
export const PKG_PERFORMANCE_DETAIL_FAILED_ACTION =
  'PKG_PERFORMANCE_DETAIL_FAILED_ACTION';
export const SEARCH_PACKAGES_SUCCESS_ACTION = 'SEARCH_PACKAGES_SUCCESS_ACTION';
export const SEARCH_PACKAGES_FAILED_ACTION = 'SEARCH_PACKAGES_FAILED_ACTION';
export const PACKAGE_PERFORMANCE_HISTORY_SUCCESS_ACTION =
  'PACKAGE_PERFORMANCE_HISTORY_SUCCESS_ACTION';
export const PACKAGE_PERFORMANCE_HISTORY_FAILED_ACTION =
  'PACKAGE_PERFORMANCE_HISTORY_FAILED_ACTION';
export const CLEAN_STATE = 'CLEAN_STATE';
export const PKG_DETAIL_SUCCESS_ACTION = 'PKG_DETAIL_SUCCESS_ACTION';
export const PKG_DETAIL_FAILED_ACTION = 'PKG_DETAIL_FAILED_ACTION';
export const PKG_UPDATE_SUCCESS_ACTION = 'PKG_UPDATE_SUCCESS_ACTION';
export const PKG_UPDATE_FAILED_ACTION = 'PKG_UPDATE_FAILED_ACTION';
export const PKG_DELETE_FAILED_ACTION = 'PKG_DELETE_FAILED_ACTION';
export const PKG_DELETE_SUCCESS_ACTION = 'PKG_DELETE_SUCCESS_ACTION';
export const PKG_UNASSIGNED_CAMPAIGN_SUCCESS =
  'PKG_UNASSIGNED_CAMPAIGN_SUCCESS';
export const PKG_UNASSIGNED_CAMPAIGN_FAILED = 'PKG_UNASSIGNED_CAMPAIGN_FAILED';
export const UPLOAD_PROGRESS_PERCENT = 'UPLOAD_PROGRESS_PERCENT';
export const HOMEPAGE_URL_CHANGED = 'HOMEPAGE_URL_CHANGED';
export const HISTORY_CHANGED = 'HISTORY_CHANGED';
export interface HomepageUrlChangedAction {
  type: 'HOMEPAGE_URL_CHANGED';
  payload: string;
}
export interface HistoryChangedAction {
  type: 'HISTORY_CHANGED';
  payload: string;
}

// start types for request actions
export interface isLoading {
  isLoading: boolean;
}

interface resetErrorMessage {
  type: typeof RESET_USER_ERROR_MESSAGE;
}

interface startRequestAction {
  type: typeof START_REQUEST;
  payload: isLoading;
}

interface stopRequestAction {
  type: typeof STOP_REQUEST;
  payload: isLoading;
}
// end for request actions

interface userCredentialSuccessAction {
  type: typeof SET_USER_CREDENTIAL_SUCCESS_ACTION;
  payload: {};
}

interface userCredentialFailedAction {
  type: typeof SET_USER_CREDENTIAL_FAILED_ACTION;
}
// end for token actions

export interface partnerInterface {
  PageStartIndex: number;
  PageSize: number;
}

export interface listAdvertisersForPartnerInterface {
  PageStartIndex: number;
  PageSize: number;
}

interface userLogoutSuccessAction {
  type: typeof USER_LOGOUT_SUCCESS_ACTION;
}

interface userLogoutFailedAction {
  type: typeof USER_LOGOUT_FAILED_ACTION;
}
// start types for user actions
export interface userInfo {
  user: string;
  password: string;
}

export interface UserInfoTTD {
  Login: string;
  Password: string;
  TokenExpirationInMinutes: number;
}

interface userToken {
  username: string;
  ttdToken: string;
  packageToken: string;
}
interface userSuccessAction {
  type: typeof USER_SUCCESS_ACTION;
  payload: userToken;
}

interface userFailedAction {
  type: typeof USER_FAILED_ACTION;
}

// start types for token actions
interface tokenType {
  ttdToken: string;
  packageToken: string;
}

interface tokenSuccessAction {
  type: typeof SET_TOKEN_SUCCESS_ACTION;
  payload: {};
}

interface tokenFailedAction {
  type: typeof SET_TOKEN_FAILED_ACTION;
}
// end for token actions

export interface PackageParameterActionTypes {
  Limit: number;
  Offset: number;
  SortField: string;
  SortOrder: string;
}

export interface PackagePerformanceOverviewType {
  package_id: number;
  package_name: string;
  target_budget: number;
  total_impressions: number;
  total_spend: number;
  pacing_percent: number;
  advertiser_id?: string;
  advertiser_name?: string;
}

interface packagePerformanceOverviewSuccessAction {
  type: typeof PKG_PERFORMANCE_OVERVIEW_SUCCESS_ACTION;
  payload: PackagePerformanceOverviewType[];
}

interface packagePerformanceOverviewFailedAction {
  type: typeof PKG_PERFORMANCE_OVERVIEW_FAILED_ACTION;
}

// start types for search packages
export interface searchPackageSuccessAction {
  type: typeof SEARCH_PACKAGES_SUCCESS_ACTION;
  payload: {};
}

export interface searchPackageFailedAction {
  type: typeof SEARCH_PACKAGES_FAILED_ACTION;
}

export interface PackageDetailParameterActionTypes {
  Limit?: number;
  Offset?: number;
  SortField?: string;
  SortOrder?: string;
  PackageId: string;
}

export interface CampaignPerformanceType {
  advertiser_id: string;
  campaign_id: string;
  campaign_name: string;
  pacing_percent: number;
  target_budget: number;
  total_impressions: number;
  total_spend: number;
}

export interface PackagePerformanceDetailType {
  package_id: string;
  package_name: string;
  listCampaign: CampaignPerformanceType[];
}

interface packagePerformanceDetailSuccessAction {
  type: typeof PKG_PERFORMANCE_DETAIL_SUCCESS_ACTION;
  payload: PackagePerformanceDetailType[];
}

interface packagePerformanceDetailFailedAction {
  type: typeof PKG_PERFORMANCE_DETAIL_FAILED_ACTION;
}

export interface CleanStateAction {
  type: typeof CLEAN_STATE;
}

interface CampaignTypes {
  campaign_id: string;
  campaign_name: string;
}

export interface PackageDetailType {
  package_id: number;
  package_name: string;
  members: CampaignTypes[];
}

interface packageDetailSuccessAction {
  type: typeof PKG_DETAIL_SUCCESS_ACTION;
  payload: PackageDetailType;
}

interface packageDetailFailedAction {
  type: typeof PKG_DETAIL_FAILED_ACTION;
}

export interface packageInterface {
  PackageName: string;
  PackageId: number;
  Members: String[];
}

interface updatePackageSuccessAction {
  type: typeof PKG_UPDATE_SUCCESS_ACTION;
  payload: {};
}

interface updatePackageFailedAction {
  type: typeof PKG_UPDATE_FAILED_ACTION;
}

interface deletePackageSuccessAction {
  type: typeof PKG_DELETE_SUCCESS_ACTION;
}

interface deletePackageFailedAction {
  type: typeof PKG_DELETE_FAILED_ACTION;
  payload: {};
}

export interface UnassignedCampaignsType {
  campaign_name: string;
  campaign_id: string;
}

interface pkgUnassignedCampaignsSuccess {
  type: typeof PKG_UNASSIGNED_CAMPAIGN_SUCCESS;
  payload: UnassignedCampaignsType[];
}

interface pkgUnassignedCampaignsFailed {
  type: typeof PKG_UNASSIGNED_CAMPAIGN_FAILED;
}
export interface progressPercent {
  type: typeof UPLOAD_PROGRESS_PERCENT;
}

export interface pkgExportPackageCampaigns {
  ci: string;
  cn: string;
}
export interface pkgExportPackage {
  ai: string;
  pi: number;
  pn: string;
  m: pkgExportPackageCampaigns[];
}

type campPacing = 'PaceToEndOfFlight' | 'PaceAhead' | 'Off';
export interface ttdAdvertiserData {
  AdvertiserId: string;
  AdvertiserName: string;
  PartnerId: string;
}

export interface ttdcampFlight {
  CampaignId: string;
  CampaignFlightId?: string;
  BudgetInAdvertiserCurrency: number;
  StartDateInclusiveUTC: string;
  EndDateExclusiveUTC: string;
}
export interface ttdCampaignData {
  CampaignName: string;
  CampaignId: string;
  AdvertiserId: string;
  StartDate: string;
  EndDate: string;
  Budget: number;
  DailyBudget: number;
  BudgetInImpressions: number;
  DailyBudgetInImpressions;
  PacingMode: campPacing;
  TimeZone: string;
  CampaignFlights: ttdcampFlight[];
  // perhaps only needed here
  CreatedAtUTC: string;
  LastUpdatedAtUTC: string;
  CampaignConversionReportingColumns: Array<any>;
}

export type RequestActionTypes = startRequestAction | stopRequestAction;
export type UserActionTypes = userSuccessAction | userFailedAction;
export type TokenActionTypes = tokenSuccessAction | tokenFailedAction;
export type UserCredentialActionTypes =
  | userCredentialSuccessAction
  | userCredentialFailedAction;
export type UserLogoutActionTypes =
  | userLogoutSuccessAction
  | userLogoutFailedAction;
export type ResetErrorMessageActionTypes = resetErrorMessage;
export type PackagePerformanceOverviewActionTypes =
  | packagePerformanceOverviewSuccessAction
  | packagePerformanceOverviewFailedAction;
export type PackagePerformanceDetailActionTypes =
  | packagePerformanceDetailSuccessAction
  | packagePerformanceDetailFailedAction;
export type PackageDetailActionTypes =
  | packageDetailSuccessAction
  | packageDetailFailedAction;
export type PackageUpdateActionTypes =
  | updatePackageSuccessAction
  | updatePackageFailedAction;

export type PackageDeleteActionTypes =
  | deletePackageSuccessAction
  | deletePackageFailedAction;
export type PkgUnassignedCampaignsActionTypes =
  | pkgUnassignedCampaignsSuccess
  | pkgUnassignedCampaignsFailed;
