import {
  SEARCH_PACKAGES_SUCCESS_ACTION,
  SEARCH_PACKAGES_FAILED_ACTION
} from '../actions/actionTypes';

interface searchListInterface {
  list: object,
  message: string | null
}

const initialState: searchListInterface = {
  list: [],
  message: null
};

const searchListReducer = (state: searchListInterface = initialState, action) => {
  switch (action.type) {
    case SEARCH_PACKAGES_SUCCESS_ACTION:
      return {...state, list: action.payload};
    case SEARCH_PACKAGES_FAILED_ACTION:
      return {...state, message: action.payload};
    default:
      return {...state};
  }
};

export default searchListReducer;
