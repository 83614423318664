export type tPerfCampData = {
  advertiser_id: string;
  campaign_id: string;
  campaign_name: string;
  partner_id: string;
  target_budget: number;
  spend: number;
  impressions: number;
  clicks: number;
  ctr: number;
  pacing_percent: number;
  adv_cpm_usd: number;
  adv_cpc_usd: number;
  sampled_tracked_impressions: number;
  sampled_viewed_impressions: number;
  sampled_in_view_rate: number;
  vcpm_usd: number; // cspell:disable-line
  end_date: string;
  budget: number;
};
export type tPackagePerformanceDetails = {
  package_id: number;
  package_name: string;
  end_date: string;
  campaigns: tPerfCampData[];
  history: {
    '7-days': tPerfCampData[];
    '14-days': tPerfCampData[];
    '30-days': tPerfCampData[];
    '90-days': tPerfCampData[];
    'month-to-date': tPerfCampData[];
    'last-month': tPerfCampData[];
  };
};
export type tPerfArray = tPackagePerformanceDetails[] | undefined;
//prettier-ignore

export type tPerfDateRange ='7-days'|'14-days'|'30-days'|'90-days'|'month-to-date'|'last-month';

// prettier-ignore
export const  metrics_map = {spend:'spend', impressions:'impressions', clicks:'clicks' ,cpc:'adv_cpc_usd', cpm:'adv_cpm_usd', ctr:'ctr', vcpm:'vcpm_usd'} // cspell:disable-line

export const isInt = (field) => {
  return [
    'impressions',
    'clicks',
    'total_impressions',
    'total_clicks'
  ].includes(field);
};

export const filterTimeValues = (
  timeFrame: string,
  data: tPackagePerformanceDetails
) => {
  // console.log('filterTimeValues: ' + JSON.stringify(data));
  if (data && data.campaigns) {
    switch (timeFrame) {
      case 'lifeTime':
        return data?.campaigns ?? [];
      default:
        return data?.history[timeFrame] ?? [];
    }
  }
  return [];
};

export const filterCampaigns = (
  data: tPerfArray,
  history: boolean,
  period: string
) => {
  let timeFrame = history ? period : 'lifeTime';
  let vals = data && data[0] ? data[0] : undefined;
  if (vals) {
    return filterTimeValues(timeFrame, vals);
  }
  return [];
};
export const createUrlParams = (search) => {
  let ls = search ? search.replace(/^\?/, '') : '';
  let ws = window.location.search.replace(/^\?/, '');
  if (ls !== ws && ws) {
    console.log('location search ' + ls + ' window.location.search' + ws);
  }
  // 'PackageId=645&Limit=10000&Offset=0&SortField=target_budget&SortOrder=Asc';
  const getUniqueKeyVals = (urlParamStr: string) => {
    const getUniqueValObj = (keyValArr) => {
      return keyValArr.reduce((acc, el) => {
        try {
          let vA = el.split('=');
          acc[vA[0]] = vA[1];
        } catch (e) {
          //
        }
        return acc;
      }, {});
    };
    if (urlParamStr === undefined) {
      return {};
    }
    try {
      let paramArr = urlParamStr.split('&');
      if (paramArr[0] === '') {
        return {};
      }
      return getUniqueValObj(paramArr);
    } catch (e) {
      return {};
    }
  };

  let locParams = getUniqueKeyVals(ls);
  // console.log(JSON.stringify(locParams));
  let winParams = getUniqueKeyVals(ws);
  // console.log(JSON.stringify(winParams));
  let allParams = {
    ...locParams,
    ...winParams
  };
  // console.log(JSON.stringify(allParams));

  let keys = Object.keys(allParams);
  let urlSearch = keys
    .map((key) => {
      return '' + key + '=' + allParams[key];
    })
    .join('&');
  // console.log(urlSearch);
  return urlSearch;
};
