import { applyMiddleware, createStore as createReduxStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import makeRootReducer, { registerSubReducers } from '../reducers/reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import { MyStore } from 'typesafe-actions';

export const configureStore = (initialState = {}): MyStore => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middlewares = [thunkMiddleware];

  // ======================================================
  // Store Enhancers
  // ======================================================
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createReduxStore(
    makeRootReducer(),
    initialState,
    composedEnhancers
  );

  // holds the extra reducers which need to be registered for maui
  store['asyncReducers'] = {};
  // register maui reducers we need
  registerSubReducers(store);

  if (module.hot) {
    module.hot.accept('../reducers/reducers', () => {
      const reducers = require('../reducers/reducers').default;
      store.replaceReducer(reducers(store['asyncReducers']));
    });
  }

  return store;
};

// ========================================================
// Store Instantiation
// ========================================================
//@ts-ignore
const initialState = window.___INITIAL_STATE__;
const store = configureStore(initialState);
export default store;
export const configureTestStore = configureStore;
