import { Observable, of } from 'rxjs';
import { IResultsData, requestTtdApi$ } from './requestTtdApi';
import { IRunBase } from './LoadData';

export interface IReadQueryBase {
  [index: string]: number | string | undefined | any;
  BaseIdName: string; // name of Id to use e.g. AdvertiserId
  BaseId: string; // Id value
  method: 'POST' | 'GET';
  shortUrl: string;
  responseFilterFieldList: Array<any>;
  errorId: string;
  sJSON?: string;
  sJsonExtra?: Object; // extra JSON properties
}

/**
 * create JSON payload based on out parameters
 * @param PartnerId
 * @param start - start index
 * @param size - max response record count
 * @param searchTerm - string to search for (we just support 1)
 * @param sortField - sort field for ascending sort
 */
const campaignJSON = (start = 0, size = 1, searchTerm = '', sortField = '') => {
  var ret: any = {};
  ret = {
    PageStartIndex: start,
    PageSize: size,
    SearchTerms: [searchTerm]
  };
  if (sortField !== '') {
    ret.SortFields = [
      {
        FieldId: sortField,
        Ascending: true
      }
    ];
  }
  return ret;
};

/**
 * send 1 request + transform parameters the way the generic request method needs them
 * @param props
 * @returns  IResultsData - most commonly used are {data : [Array of Campaign Objects], message: <errormessage> }
 */
export const readQuery$ = (
  props: IReadQueryBase & IRunBase
): Observable<IResultsData> => {
  let {
    start, // start index
    limit, // record limit
    BaseIdName, // Id name e.g. AdvertiserId
    searchTerm, // optional, only 1 supported
    sortField,
    shortUrl,
    sJsonExtra
  } = props;
  var BaseId = props[BaseIdName];
  if (BaseId || shortUrl === '/partner/query') {
    var sJSON = campaignJSON(start, limit, searchTerm, sortField);
    if (BaseIdName && BaseId) {
      sJSON[BaseIdName] = BaseId;
    }
    if (sJsonExtra) {
      sJSON = { ...sJSON, ...sJsonExtra };
    }
    // eslint-disable-next-line no-param-reassign
    props = { ...props, sJSON };
    return requestTtdApi$(props);
  }
  return of({
    data: [],
    message: 'readQuery ' + props.shortUrl + ' - missing BaseId'
  });
};
