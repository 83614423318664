import { of, timer } from 'rxjs';
import { mergeMap, tap, concatMap, filter, take, map } from 'rxjs/operators';
import {
  ICloneCampaignStatusData,
  readCampaignCloneStatus$
} from './readCloneCampaignStatus';
import {
  ICloneCampaignJSON,
  ICloneCampaign,
  ICloneCampaignData,
  postCampaignClone$,
  ICLoneCampaignExtraData
} from './postCampaignClone';
import { updateAdgroupBudgets$ } from './updateAdgroupBudgets';
import { ICreateUpdateResultData } from './UpdateData';
import { IResultsData } from './requestTtdApi';
export interface IClonePollingData extends ICloneCampaignStatusData {
  warning?: string;
  adgroupMessages?: Array<string>;
}

export interface IClonePolling extends ICloneCampaign {
  forwardProgress?: Function;
  cloneResp?: IClonePollingData;
  run?: number;
  totalRuns?: number;
}

const genProgressArr = (max: number, dims = 3) => {
  // @ts-ignore
  var ret = [...Array(dims)].map((e) => Array(max).fill(0));

  // var mArr = [...Array(max-1).keys()].map((x) => ++x);
  // return mArr;
  return ret;
};
const genSkipObj = (max: number) => {
  // @ts-ignore
  return [...Array(max).keys()].reduce((acc, x) => {
    acc[x] = -1;
    return acc;
  }, {});
};

var pArr, partSkipIdx;

const adjustRunProgress = (run, runPart, percent) => {
  var part = runPart - 1,
    partArr = pArr[part],
    skipIdx = partSkipIdx[part],
    end = partArr.length;
  if (percent > 100) {
    percent = 100;
  }
  partArr[run - 1] = percent;
  if (percent === 100) {
    var i = skipIdx + 1;
    while (partArr[i] === 100 && i < end) {
      i++;
    }
    partSkipIdx[part] = i - 1;
  }
};

const calcProgress = (runPart) => {
  var part = runPart - 1,
    partArr = pArr[part];
  var start = 0,
    end = partArr.length,
    skipIdx = partSkipIdx[part],
    runAdd = 0;
  if (skipIdx > start) {
    start = skipIdx + 1;
    runAdd = skipIdx + 1;
  }
  var sum = 0;
  for (var i = start; i < end; i++) {
    sum += partArr[i] / 100;
  }
  sum = Math.round(((sum + runAdd) / end) * 100);
  return sum;
};

export const postClonePolling$ = (cloneData: IClonePolling) => {
  var startingOffset = 0;
  var pollingInterval = 5000;
  var { sJSON, ttdAuthToken, run, totalRuns, forwardProgress } = cloneData;
  var { cloneResp } = sJSON as ICloneCampaignJSON;

  if (run === 1) {
    pArr = genProgressArr(totalRuns || 1);
    partSkipIdx = genSkipObj(3);
  }

  const pFunc = (obj) => {
    var { percent } = obj;
    if (percent) {
      return percent;
    }
  };
  const combineProgress = () => {
    if (typeof forwardProgress === 'function') {
      var pct1 = calcProgress(1),
        pct2 = calcProgress(2),
        pct3 = calcProgress(3);
      // weight partial results
      var pct = Math.round(((pct1 + pct2 * 4 + pct3 * 10) / 15) * 100) / 100;
      forwardProgress(pct);
    }
  };

  const progressClone = (obj) => {
    adjustRunProgress(run, 1, pFunc(obj));
    combineProgress();
  };

  const progressPoll = (obj) => {
    adjustRunProgress(run, 2, pFunc(obj));
    combineProgress();
  };

  const progressPostProcess = (obj) => {
    adjustRunProgress(run, 3, pFunc(obj));
    combineProgress();
  };

  const processCloneReport = (cloneRep: Array<string>): string => {
    /*clone report example
  [
    "AdGroup budget was not cloned for AdGroupId 2qpwoey from CampaignFlightId 947422", 
    "AdGroup budget was not cloned for AdGroupId 8rh9ggf from CampaignFlightId 947422", 
  ]
  */
    var retMsg = '';
    if (cloneRep) {
      var len = cloneRep.length,
        agIds = cloneData.agIds || {},
        agLen = Object.keys(agIds).length,
        agCount = 0,
        msg = '; Warning - budget for ',
        msg1 = 'ad group',
        msg2 = ' was not cloned';
      if (len > 0) {
        var otherMsg: Array<String> = [];
        var agArr = cloneRep
          .map((strEl: string) => {
            var testRe = /AdGroup budget was not cloned for AdGroupId ([\w]+) from CampaignFlightId /i,
              agName = '',
              agId;
            var match = testRe.exec(strEl);
            if (match != null) {
              // we have an ad group with budget cloning issues
              agId = match[1];
              // lookup adgroup name
              agName = agIds[agId] || agName;
              if (agName) {
                agCount++;
              }
            } else {
              otherMsg.push(strEl);
              // console.warn('processCloneReport - no RegEx match ' + strEl);
            }
            return agName;
          })
          .sort();
        if (agCount > 0) {
          // simplify the message, if all relevant adgroups are effected
          if (agCount === agLen) {
            // we have warnings for all adgroups in the campaign
            retMsg = msg + 'all ' + msg1 + 's' + msg2;
          } else {
            let agIds = cloneData.AdGroupIds,
              agClLen = agIds && agIds.length > 0 ? agIds.length : 0;
            if (agCount === agClLen) {
              // we did just enable some ad groups, but all of those are effected
              retMsg = msg + 'all cloned ' + msg1 + 's' + msg2;
            } else {
              // just some adgroups were affected -> list them
              let agStr = agArr.join(', ');
              retMsg =
                msg + msg1 + (agArr.length > 1 ? 's ' : ' ') + agStr + msg2;
            }
          }
        } else if (otherMsg.length > 0) {
          retMsg = 'Warning\n' + otherMsg.join('\n');
        }
      }
    }
    // console.log('processCloneReport: ' + retMsg);
    return retMsg;
  };

  // test only here
  if (cloneResp) {
    var cRes = cloneResp;
    let {
      AdGroupIdMap,
      // FailedAdGroupIds,
      Status,
      FailureMessage,
      CampaignId,
      CloneReport
    } = cRes;

    cRes.warning = processCloneReport(CloneReport);
    // detailed warnings go to report file
    // updateStatus(cCampIdx, outputMessage + warning, CampaignId);
    if (Status === 'Completed' && !FailureMessage && AdGroupIdMap) {
      return updateAdgroupBudgets$({
        ttdCampaignId: CampaignId,
        ttdAuthToken,
        AdGroupIdMap,
        progressFunc: progressPostProcess,
        run,
        totalRuns
      }).pipe(
        tap((agRet: ICreateUpdateResultData) => {
          if (agRet.messages && agRet.messages.length > 0) {
            cRes.adgroupMessages = agRet.messages;
          }
          progressPostProcess({ percent: 100 });
        }),
        mergeMap(() => {
          return of({ data: cRes, message: '' });
        })
      );
    }
    // console.log('forward test cloning Response', cRes);
    return of({ data: cRes, message: '' });
  }
  // provide initial progress feedback
  if (progressClone) {
    progressClone({ percent: 1.2 });
  }
  // build up clone properties here
  return postCampaignClone$(cloneData).pipe(
    mergeMap((resp: ICloneCampaignData) => {
      if (progressClone) {
        progressClone({ percent: 100 });
      }
      let { ReferenceId, data /*message */ } = resp;
      let {
        runIdx,
        rowIdx,
        UpdateAdGroupBudgets
      } = data as ICLoneCampaignExtraData;
      if (ReferenceId) {
        return timer(startingOffset, pollingInterval).pipe(
          concatMap(() => {
            return readCampaignCloneStatus$({
              ReferenceId,
              ttdAuthToken
            }).pipe(
              // map data back as response
              map(
                (cloneResp1: IResultsData) =>
                  cloneResp1.data as ICloneCampaignStatusData
              )
            );
          }),
          // progress notification
          tap((res: ICloneCampaignStatusData) => {
            let { ProgressPercentage, Status } = res;
            let pct = Number(ProgressPercentage);
            if (!Number.isNaN(pct)) {
              pct = Math.round(pct * 100) / 100;
            } else {
              pct = 1;
            }
            if (Status && Status === 'InProgress' && progressPoll) {
              progressPoll({ percent: pct });
            }
          }),
          // filter out  InProgress
          filter((pollResult: ICloneCampaignStatusData) => {
            let { Status } = pollResult;
            return Status !== 'InProgress';
          }),
          take(1),
          mergeMap((clRes: IClonePollingData) => {
            let {
              AdGroupIdMap,
              // FailedAdGroupIds,
              Status,
              FailureMessage,
              CampaignId,
              CloneReport
            } = clRes;
            // patch in Indexes for later identification as the status request won't return them
            clRes.runIdx = runIdx;
            clRes.rowIdx = rowIdx;
            // also include UpdateAdGroupBudgets if we have it
            clRes.UpdateAdGroupBudgets = UpdateAdGroupBudgets;
            if (progressPoll) {
              progressPoll({ percent: 100 });
            }
            if (Status)
              if (Status !== 'InProgress') {
                // eslint-disable-next-line no-param-reassign
                clRes.warning = processCloneReport(CloneReport as string[]);
                // detailed warnings go to report file hee
                // updateStatus(cCampIdx, outputMessage + warning, CampaignId);
                if (
                  Status === 'Completed' &&
                  !FailureMessage &&
                  AdGroupIdMap &&
                  UpdateAdGroupBudgets
                ) {
                  return updateAdgroupBudgets$({
                    ttdCampaignId: CampaignId as string,
                    ttdAuthToken,
                    AdGroupIdMap,
                    progressFunc: progressPostProcess,
                    run,
                    totalRuns
                  }).pipe(
                    tap((agRet: ICreateUpdateResultData) => {
                      // clRes.
                      if (agRet.messages && agRet.messages.length > 0) {
                        // eslint-disable-next-line no-param-reassign
                        clRes.adgroupMessages = agRet.messages;
                      }
                      if (progressPostProcess) {
                        progressPostProcess({ percent: 100 });
                      }
                    }),
                    mergeMap(() => {
                      return of({ data: clRes, message: '' });
                    })
                  );
                }
              }
            // console.log('Polling result: ' + JSON.stringify(res), res);
            return of({ data: clRes, message: '' });
          })
          // tap((res: ICloneCampaignStatusData) => {
          //   // console.log('Polling result: ' + JSON.stringify(res), res);
          //   console.log('Polling result: ', res);
          // })
        );
      }
      // forward
      // console.log('forward cloning error', JSON.stringify(resp));
      // let outputMessage = 'Failed - ' + (resp && (resp.message || 'error'));
      // updateStatus(cCampIdx, outputMessage);
      if (progressPoll) {
        progressPoll({ percent: 100 });
      }
      if (progressPostProcess) {
        progressPostProcess({ percent: 100 });
      }
      return of(resp);
    })
  );
};
