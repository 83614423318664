import {
  START_REQUEST,
  STOP_REQUEST,
  RequestActionTypes
} from '../actions/actionTypes';

export interface RequestState {
  isLoading: boolean
}

const initialState: RequestState = {
  isLoading: false
};

const requestReducer = (state = initialState, action: RequestActionTypes): RequestState => {
  switch (action.type) {
    case START_REQUEST:
      return {...state, isLoading: action.payload.isLoading}
    case STOP_REQUEST:
      return {...state, isLoading: action.payload.isLoading}
    default:
      return {...state};
  }
};

export default requestReducer;
