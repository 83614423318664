import _ from 'lodash';
import {
  SEARCH_PACKAGES_SUCCESS_ACTION,
  SEARCH_PACKAGES_FAILED_ACTION,
  searchPackageSuccessAction,
  searchPackageFailedAction
} from './actionTypes';
import { requestStart, requestStop } from './requestActions';
import { getPackageSearch } from '../services/searchService';

const searchPackagesSuccess = (payload): searchPackageSuccessAction => {
  return {
    type: SEARCH_PACKAGES_SUCCESS_ACTION,
    payload
  };
};

const searchPackagesFailed = (): searchPackageFailedAction => {
  return {
    type: SEARCH_PACKAGES_FAILED_ACTION
  };
};

export const searchPackagesList = (
  term: string,
  limit: number = 10,
  offset: number = 0,
  sortOrder: any = null,
  sortField: any = null
) => {
  return async (dispatch) => {
    try {
      dispatch(requestStart());
      let packagesList = await getPackageSearch(
        term,
        limit,
        offset,
        sortOrder,
        sortField
      );
      packagesList = _.filter(
        packagesList,
        (o) => o.last_viewed_at && o.last_viewed_at !== ''
      );
      dispatch(searchPackagesSuccess(packagesList));
      dispatch(requestStop());
    } catch (e) {
      dispatch(searchPackagesFailed());
      dispatch(requestStop());
    }
  };
};
