import { ajax, AjaxConfig, AjaxResponse } from 'rxjs/ajax';
// import { XMLHttpRequest as xhr2 } from 'xmlhttprequest';
import { XMLHttpRequest as myXHR } from 'xhr2';

import { of, throwError, Observable } from 'rxjs';
import { catchError, pluck, tap } from 'rxjs/operators';
import { finishRequest, registerRequest } from './ttd_request_tracker';
import { baseUrl } from './../vars';

const XHR2 = typeof XMLHttpRequest !== 'undefined' ? XMLHttpRequest : myXHR;

const enableDebug =
  process.env.REACT_APP_DEBUG &&
  process.env.REACT_APP_DEBUG.toLocaleLowerCase() !== 'false';

const ttdUrl = baseUrl + '/v3';

let shortUrlBase = ttdUrl;
// let shortUrlBase = 'https://sb.thetradedesk.com:85/v3';
//const shortUrlBase = 'https://apisb.thetradedesk.com/v3';

// let shortUrlGqlBase = 'https://api.thetradedesk.com';
let shortUrlGqlBase = baseUrl;
interface IHeaders {
  'Content-Type': string;
  'TTD-Auth'?: string;
}

export interface ttdAjaxParams {
  method: string;
  shortUrl: string;
  data?: string | JSON | { [key: string]: number | string | boolean };
  authToken?: string;
  filterError?: boolean | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let tokenRefresher: Function | undefined;

export const registerTokenRefresher = (refreshFunction: Function) => {
  if (typeof refreshFunction === 'function') {
    tokenRefresher = refreshFunction;
  }
};

export const unregisterTokenRefresher = () => {
  tokenRefresher = undefined;
};

export const setUrlBase = (env: string) => {
  if (env) {
    switch (env) {
      case 'prod':
        shortUrlBase = 'https://api.thetradedesk.com/v3'; // prod
        break;
      case 'extSB':
        shortUrlBase = 'https://apisb.thetradedesk.com/v3';
        break;
      case 'intSB':
      default:
        shortUrlBase = 'https://sb.thetradedesk.com:85/v3';
    }
  }
};

export const getUrlBase = () => {
  return shortUrlBase;
};

const request = function request<T>(
  config: AjaxConfig
): Observable<AjaxResponse<T>> {
  return ajax<T>({ createXHR: () => new XHR2(), ...config });
};

export const ttdAjax = ({
  method = 'GET',
  shortUrl = '',
  data = '',
  authToken = '',
  filterError = false
}: ttdAjaxParams) => {
  // console.log('ttdAjax - m: ' + method + ' u: ' + shortUrl + ' a:' + authToken);
  var url = '';
  // test for graphQL endpoint
  if (shortUrl.includes('/graphql')) {
    url = shortUrlGqlBase + '/graphql';
  } else {
    url = shortUrlBase + shortUrl;
  }
  // JSON formatting if needed
  let sData = typeof data === typeof '' ? data : JSON.stringify(data);
  //headers should include TTD-Auth if we have a token
  let headers: IHeaders = {
    'Content-Type': 'application/json' // ;charset=UTF-8
  };
  let ttdToken = authToken;
  if (typeof tokenRefresher === 'function') {
    ttdToken = tokenRefresher();
  }

  if (ttdToken) {
    // switch between normal and bearer tokens from OIDC
    if (ttdToken.length < 164) {
      headers['TTD-Auth'] = ttdToken;
    } else {
      // bearerToken
      headers['Authorization'] = 'Bearer ' + ttdToken;
    }
  }
  if (shortUrl !== '' && shortUrl[0] === '/') {
    if (shortUrl === '/authentication') {
      if (enableDebug)
        /* prettier-ignore */
        console.log('ttdAjax - method: ' + method + ' url: ' + url + ' headers: ' + JSON.stringify(headers));
    } else if (enableDebug) {
      /* prettier-ignore */
      console.log('ttdAjax - method: ' + method + ' url: ' + url + ' body: ' + data + ' authToken:' + authToken + ' headers: ' + JSON.stringify(headers));
    }
    registerRequest({ method, shortUrl });
    // return AjaxObservable
    return request({
      crossDomain: true,
      withCredentials: false,
      method,
      url,
      body: sData,
      headers
    }).pipe(
      // eslint-disable-next-line no-unused-vars
      tap((res: any) => {
        finishRequest({ method, shortUrl });
        if (res.status !== 200) console.log('ttd-ajax response ', res);
      }),
      // just get the response part
      pluck('response'),
      // tap((res) => {
      //   if (enableDebug) console.log('ttd-ajax response', res);
      // }),
      catchError((error) => {
        // we use an extra error handler here to finish the open request
        finishRequest({ method, shortUrl });
        // now forward the error
        if (!filterError) return throwError(error);
        return of(error.response);
      })
    );
  }
  if (shortUrl === '') {
    return of('Error: empty shortUrl provided');
  }
  return of('Error: shortUrl needs to start with "/"');
};
export default ttdAjax;
