import { titleCase } from '../../../utils';
export type ChartType = {
  id: string;
  name: string;
  apiResponseKey: string;
  chartTooltip?: string;
  chartTooltipSuffix?: string;
  replaceName?: string;
  type: string; // number type
};
export type TimeFrameType = {
  id: string;
  name: string;
};

export const CHART_NAME = {
  TOP_CHART: 'topChart',
  BOTTOM_CHART: 'bottomChart'
};

/**
 * new timeframes can easily be added here and will generate the required TIME_FRAME_OPTION + TIME_FRAME_TYPES
 */
export const TIME_FRAME = [
  '7-days',
  '14-days',
  '30-days',
  '90-days',
  'month-to-date',
  'last-month'
];
/**
 * generate Array for use in dropdowns
 *  [{ id: '7-days', name: '7 Days' }, ...,{ id: 'last-month', name: 'Last Month' }]
 */
export const TIME_FRAME_OPTIONS = TIME_FRAME.map((item) => ({
  id: item,
  // tileCase will replace "-" with " " and uppercase each word
  name: titleCase(item)
}));

/**
 * generate a Lookup object for setting dropdown values
 * *  {'7-days':{ id: '7-days', name: '7 Days' }, ...,'last-month'{ id: 'last-month', name: 'Last Month' }]}
 */
export const TIME_FRAME_TYPES = TIME_FRAME.reduce((acc, item, index) => {
  acc[item] = TIME_FRAME_OPTIONS[index];
  return acc;
}, {});

export const CHART_TYPES = {
  clicks: {
    id: 'clicks',
    name: 'Clicks',
    apiResponseKey: 'clicks',
    chartTooltip: 'Clicks',
    chartTooltipSuffix: '',
    type: 'Int'
  },
  cpc: {
    id: 'cpc',
    name: 'CPC',
    apiResponseKey: 'adv_cpc_usd',
    chartTooltip: 'CPC',
    chartTooltipSuffix: '$',
    replaceName: 'CPC',
    type: 'Float'
  },
  cpm: {
    id: 'cpm',
    name: 'CPM',
    apiResponseKey: 'adv_cpm_usd',
    chartTooltip: 'CPM',
    chartTooltipSuffix: '$',
    replaceName: 'CPM',
    type: 'Float'
  },
  spend: {
    id: 'spend',
    name: 'Spend',
    apiResponseKey: 'spend',
    chartTooltip: 'Spend',
    chartTooltipSuffix: '$',
    replaceName: 'Current Spend',
    type: 'Float'
  },
  impressions: {
    id: 'impressions',
    name: 'Impressions',
    apiResponseKey: 'impressions',
    chartTooltip: 'impressions',
    chartTooltipSuffix: '',
    type: 'Int'
  },
  ctr: {
    id: 'ctr',
    name: 'CTR',
    apiResponseKey: 'ctr',
    chartTooltip: 'CTR',
    chartTooltipSuffix: '%',
    type: 'Pct'
  },
  // cspell:disable
  vcpm: {
    id: 'vcpm', // cspell:disable-line
    name: 'vCPM',
    apiResponseKey: 'vcpm_usd', // cspell:disable-line
    chartTooltip: 'vCPM',
    chartTooltipSuffix: '$',
    type: 'Float'
  }
};

export const CHART_TYPE_OPTIONS = Object.keys(CHART_TYPES).map(
  (key) => CHART_TYPES[key]
);
