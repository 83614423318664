import React from 'react';
import './style.scss';
import Dots from '@ttd/maui/lib/components/display/Loaders/Dots';

interface LoadingProps {
  showLoading?: boolean;
}

const Spin = ({ showLoading }: LoadingProps) => {
  return showLoading ? (
    <div className="spin-container">
      <Dots className="loading-dots" />
    </div>
  ) : null;
};

export default Spin;
