import React, { useEffect, useState } from 'react';
import XLSX from 'sheetjs-style';
import Button from '@ttd/maui/lib/components/core/Button';
import CheckBoxControl from '@ttd/maui/lib/components/controls/CheckboxControl';
import _ from 'lodash';
const DownloadReport = ({ statusReport }) => {
  const [isCheck, setIsCheck] = useState(false);
  const [isError, setIsError] = useState(false);

  const generateData = (header, data) => {
    const formatData = isCheck
      ? data.filter((item) => !item.includes('Passed.'))
      : data;
    return [header, ...formatData];
  };

  useEffect(() => {
    let valueF;
    _.forEach(statusReport, (value) => {
      _.some(value, (item) => {
        _.forEach(item, (val) => {
          valueF = valueF ? valueF : val?.includes('Failed.');
        });
      });
    });

    setIsError(valueF);
  }, [statusReport]);

  const handleDownloadReport = () => {
    const wb = XLSX.utils.book_new();
    for (const sheetName in statusReport) {
      // eslint-disable-next-line no-prototype-builtins
      if (statusReport.hasOwnProperty(sheetName)) {
        const sheetData = statusReport[sheetName];
        const headerSheet = sheetData[0];
        let data = [] as any;
        for (let i = 1; i < sheetData.length; i++) {
          data = [...data, sheetData[i]];
        }

        const fileData = generateData(headerSheet, data);
        const ws = XLSX.utils.aoa_to_sheet(fileData);
        if (sheetName === 'Package_Campaigns') {
          const lastKey = Object.keys(ws).pop();
          // if CampaignLink has value
          if (lastKey !== 'M1') {
            for (let col in ws) {
              // eslint-disable-next-line no-prototype-builtins
              if (ws.hasOwnProperty(col) && col.includes('M') && col !== 'M1') {
                const val = ws[col].v;
                // update hyperlink
                ws[col].l = {
                  Target: val,
                  Tooltip: val,
                };
                // style for hyperlink in excel file
                ws[col].s = {
                  font: {
                    underline: true,
                    color: '#0563C1',
                  },
                };
              }
            }
          }
        }
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
      }
    }

    const fileName = 'Package_Campaigns-result.xlsx';
    XLSX.writeFile(wb, fileName);
  };

  return (
    <div className="report">
      <p className="my-25">
        {`Your edits finished ${!isError ? 'successfully' : 'with errors'}`}
      </p>
      <fieldset>
        <legend>Status report</legend>
        <div className="d-flex">
          <CheckBoxControl
            id="story-book-checkbox-control"
            value={isCheck}
            isIndeterminate={false}
            onChange={() => setIsCheck(!isCheck)}
            isEnabled={true}
            isReadOnly={false}
          />
          <span>Show errors only</span>
        </div>
        <Button
          type="button"
          data-testid="btn-download-report"
          displayType="secondary"
          disabled={statusReport.length <= 1}
          onClick={() => handleDownloadReport()}
        >
          Download Report
        </Button>
      </fieldset>
    </div>
  );
};

export default DownloadReport;
