// c-Spell:disable
export const testdata = [
  {
    AdvertiserId: '4j378qw',
    CampaignId: '05kqvyb',
    CampaignName: 'Charleston-Sc_Near Market_GM_Video_Rogue_Medium_TTD',
    StartDate: '2023-05-01Th04:00:00',
    EndDate: '2024-03-31T15:59:00',
    Budget: { Amount: 34228.993986 },
    BudgetInImpressions: null,
    DailyBudget: { Amount: null },
    DailyBudgetInImpressions: null,
    PacingMode: 'PaceAhead',
    TimeZone: 'America/New_York',
    CampaignType: 'Standard',
    CampaignFlights: [
      {
        CampaignId: '05kqvyb',
        CampaignFlightId: 5007944,
        StartDateInclusiveUTC: '2023-05-01T04:00:00',
        EndDateExclusiveUTC: '2023-06-01T03:59:00',
        BudgetInAdvertiserCurrency: 7182.5,
        BudgetInImpressions: null,
        DailyTargetInAdvertiserCurrency: 0.000333,
        DailyTargetInImpressions: null
      }
    ],
    PrimaryChannel: 'Video',
    PrimaryGoal: { MaximizeReach: true },
    SecondaryGoal: '',
    TertiaryGoal: '',
    LastUpdatedAtUTC: '2024-03-25T19:48:12.227',
    Version: '',
    Availability: 'Available'
  },
  {
    AdvertiserId: '4j378qw',
    CampaignId: '0e41zg9',
    CampaignName:
      'Huntsville/Decatur/Florence_Near Market_GM_Video_Rogue_Medium_TTD',
    StartDate: '2023-05-01T04:00:00',
    EndDate: '2024-03-31T15:59:00',
    Budget: { Amount: 9446.026 },
    BudgetInImpressions: null,
    DailyBudget: { Amount: null },
    DailyBudgetInImpressions: null,
    PacingMode: 'PaceAhead',
    TimeZone: 'America/New_York',
    CampaignType: 'Standard',
    CampaignFlights: [
      {
        CampaignId: '0e41zg9',
        CampaignFlightId: 5008163,
        StartDateInclusiveUTC: '2023-05-01T04:00:00',
        EndDateExclusiveUTC: '2023-06-01T03:59:00',
        BudgetInAdvertiserCurrency: 2675.17,
        BudgetInImpressions: null,
        DailyTargetInAdvertiserCurrency: 0.000333,
        DailyTargetInImpressions: null
      }
    ],
    PrimaryChannel: 'Video',
    PrimaryGoal: { MaximizeReach: true },
    SecondaryGoal: '',
    TertiaryGoal: '',
    LastUpdatedAtUTC: '2024-03-25T19:48:12.227',
    Version: '',
    Availability: 'Available'
  },
  {
    AdvertiserId: 'khs3gdx',
    CampaignId: '1pzty69',
    CampaignName:
      'Shell_Nissan-10/01/2024_ED-10/31/2024_Reach_(JB)09/24/2024_Video Kokai',
    StartDate: '2024-10-01T00:00:00',
    EndDate: '2024-10-31T23:59:00',
    Budget: {
      Amount: 100000
    },
    BudgetInImpressions: null,
    DailyBudget: {
      Amount: null
    },
    DailyBudgetInImpressions: null,
    PacingMode: '',
    TimeZone: 'Etc/GMT',
    CampaignType: 'Standard',
    CampaignFlights: [
      {
        CampaignId: '1pzty69',
        CampaignFlightId: 7479364,
        StartDateInclusiveUTC: '2024-10-01T00:00:00',
        EndDateExclusiveUTC: '2024-10-31T23:59:00',
        BudgetInAdvertiserCurrency: 100000,
        BudgetInImpressions: null,
        DailyTargetInAdvertiserCurrency: null,
        DailyTargetInImpressions: null
      }
    ],
    PrimaryChannel: 'Video',
    PrimaryGoal: {
      MaximizeReach: true
    },
    SecondaryGoal: '',
    TertiaryGoal: '',
    LastUpdatedAtUTC: '2024-09-26T22:38:23.153',
    Version: 'Kokai',
    Availability: 'Available'
  },
  {
    AdvertiserId: 'khs3gdx',
    CampaignId: 'n8rn9is',
    CampaignName:
      'FY24_Abilene/Sweetwater_Near Market_SL_Video_Rogue_Small_TTD - Kokai',
    StartDate: '2024-09-14T06:00:00',
    EndDate: '2024-10-01T03:59:00',
    Budget: {
      Amount: 550.004
    },
    BudgetInImpressions: null,
    DailyBudget: {
      Amount: null
    },
    DailyBudgetInImpressions: null,
    PacingMode: '',
    TimeZone: 'America/New_York',
    CampaignType: 'Standard',
    CampaignFlights: [
      {
        CampaignId: 'n8rn9is',
        CampaignFlightId: 7410608,
        StartDateInclusiveUTC: '2024-09-14T06:00:00',
        EndDateExclusiveUTC: '2024-10-01T03:59:00',
        BudgetInAdvertiserCurrency: 550.004,
        BudgetInImpressions: null,
        DailyTargetInAdvertiserCurrency: null,
        DailyTargetInImpressions: null
      }
    ],
    PrimaryChannel: 'Video',
    PrimaryGoal: {
      MaximizeReach: true
    },
    SecondaryGoal: '',
    TertiaryGoal: '',
    LastUpdatedAtUTC: '2024-09-14T00:46:18.98',
    Version: 'Kokai',
    Availability: 'Available'
  }
];
// c-Spell:enable
import { of, from, firstValueFrom } from 'rxjs';
import { map, mergeMap, reduce } from 'rxjs/operators';
import { IDeltaCampaignData } from '../ttdApi/postDeltaCampaignAdvertiser';
import { IQueryGQL, queryGQL$ } from '../ttdApi/postGql';
import {
  campaignFlightsPayloadCampId,
  ICampaignFlightsPayloadCampId
} from './gqlPayloads';
import { transformCampaignGql } from './gqlTransform';
import { IResultsData } from '../ttdApi/requestTtdApi';
import { getApiToken, getCampaigns } from '../ttdApi/ttdapi';
import { sortJsonArr, strSort } from '.';

export const loadAdvDataKokai = async (advIds: string[]) => {
  try {
    // get the credentials we need
    var ttdAPIUser = process.env.TTD_API_USER;
    var ttdAPIPassword = process.env.TTD_API_PWD;
    // logger.debug('arrJSON', JSON.stringify(arrJSON, '', '\t'));

    if (ttdAPIUser && ttdAPIPassword) {
      var ttdToken;
      // use email and password to get a token
      ttdToken = await getApiToken(ttdAPIUser, ttdAPIPassword);
      console.log('auth-token: ' + ttdToken);
      if (ttdToken) {
        var campRes = await getCampaigns(ttdToken, advIds);
        if (
          campRes?.data &&
          ((campRes.data as unknown) as IDeltaCampaignData) &&
          campRes?.data.length > 0
        ) {
          var campRes1 = await addKokaiCampaignData(campRes.data, ttdToken);
          var campData = sortJsonArr(campRes1.data, strSort, 'CampaignName');
        } else {
          console.error('No campaigns found for provided advertiser IDs');
        }
      }
    } else {
      console.error('Failed to get auth token');
    }
    return campData;
  } catch (err) {
    console.error('Error loading ad campaign data', err);
    return [];
  }
};

/**
 * update REST data (kokaiRestCamps) with kokai flights from GQL (kokaiGQLCamps)
 * @param kokaiRestCamps
 * @param kokaiGQLCamps
 * @returns
 */
const updateCampsWithKokaiFlights = (
  kokaiRestCamps: IDeltaCampaignData[],
  kokaiGQLCamps: IDeltaCampaignData[]
) => {
  // short circuit for empty gql camps
  if (kokaiGQLCamps.length === 0) {
    return kokaiRestCamps;
  }

  if (kokaiRestCamps.length === 0) {
    // use GQL camps as fallback
    return kokaiGQLCamps;
  }
  // Create an index for kokaiCamps by CampaignId
  // prettier-ignore
  const kokaiCampaignIndex = new Map<string,IDeltaCampaignData>();
  kokaiGQLCamps.forEach((camp) => {
    kokaiCampaignIndex.set(camp.CampaignId, camp);
  });

  // Loop through each campaign returned from the GQL query
  kokaiRestCamps.forEach((restCampaign: IDeltaCampaignData) => {
    // Get the matching campaign from the index
    const matchingKokaiCampaign = kokaiCampaignIndex.get(
      restCampaign.CampaignId
    );

    // If a matching campaign is found, replace its CampaignFlights
    if (matchingKokaiCampaign) {
      restCampaign.PacingMode = matchingKokaiCampaign.PacingMode;
      restCampaign.CampaignFlights = matchingKokaiCampaign.CampaignFlights;
    }
  });
};

export const addKokaiCampaignData = async (
  data: IDeltaCampaignData[],
  ttdToken: string,
  updateProgress: Function | undefined = undefined
) => {
  try {
    const result$ = from(data).pipe(
      // Collect restCamps and kokai_camp_ids
      reduce(
        (acc, camp: IDeltaCampaignData) => {
          if (camp.Version === 'Kokai') {
            // Add campaign id to list for Kokai processing
            acc.kokai_camp_ids.push(camp.CampaignId);
            acc.kokaiCamps.push(camp);
          } else {
            // Use existing campaign data
            acc.restCamps.push(camp);
          }
          return acc;
        },
        // prettier-ignore
        { restCamps: [] as IDeltaCampaignData[], kokaiCamps: [] as IDeltaCampaignData[], kokai_camp_ids: [] as string[] }
      ),

      // Fetch Kokai data and combine with restCamps
      mergeMap(({ restCamps, kokaiCamps, kokai_camp_ids }) => {
        console.log('kokai_camp_ids', kokai_camp_ids);
        // If no Kokai campaigns, return just the restCamps
        if (kokai_camp_ids.length === 0) {
          return of({ data: restCamps, message: '' });
        }

        // Prepare gqlProps for the Kokai campaign query with type casting
        const gqlProps = {
          ttdAuthToken: ttdToken,
          payloadBuilder: campaignFlightsPayloadCampId as (jsonVars: {
            [key: string]: any;
          }) => string,
          // TODO: consider dynamic data filter later
          jsonVars: {
            campaignIds: kokai_camp_ids,
            after: null,
            endDate: '2024-01-01'
          },
          responseFormatter: transformCampaignGql,
          updateProgress
        } as IQueryGQL<{ [key: string]: any }>;

        // Fetch Kokai data from GraphQL
        return queryGQL$(gqlProps).pipe(
          // Combine Kokai data with restCamps
          map((resDt: IResultsData) => {
            // Concatenate restCamps and kokaiData into a single array
            const { data } = resDt;
            // for each GQL campaign replace Pacing and CampainFlights if found in (gql) data

            updateCampsWithKokaiFlights(kokaiCamps, data);
            // Combine restCamps with updated kokaiCamps
            const retCamps = restCamps.concat(kokaiCamps);
            resDt.data = retCamps;
            // the return of map is already an observable, so no of() is needed here
            return resDt;
          })
        );
      })
    );

    // Use firstValueFrom to convert observable to promise and await the result
    return await firstValueFrom(result$);
  } catch (err) {
    console.error('Error:', err);
    throw err;
  }
};

export const addKokaiData1 = async (data: IDeltaCampaignData[]) => {
  try {
    // get the credentials we need
    var ttdAPIUser = process.env.TTD_API_USER;
    var ttdAPIPassword = process.env.TTD_API_PWD;
    // logger.debug('arrJSON', JSON.stringify(arrJSON, '', '\t'));

    if (ttdAPIUser && ttdAPIPassword) {
      var ttdToken;
      // use email and password to get a token
      ttdToken = await getApiToken(ttdAPIUser, ttdAPIPassword);
      console.log('auth-token: ' + ttdToken);
      if (ttdToken) {
        const result$ = from(data).pipe(
          // Collect restCamps and kokai_camp_ids
          reduce(
            (acc, camp: IDeltaCampaignData) => {
              if (camp.Version === 'Kokai') {
                // Add campaign id to list for Kokai processing
                acc.kokai_camp_ids.push(camp.CampaignId);
                acc.kokaiCamps.push(camp);
              } else {
                // Use existing campaign data
                acc.restCamps.push(camp);
              }
              return acc;
            },
            // prettier-ignore
            { restCamps: [] as IDeltaCampaignData[], kokaiCamps: [] as IDeltaCampaignData[], kokai_camp_ids: [] as string[] }
          ),

          // Fetch Kokai data and combine with restCamps
          mergeMap(({ restCamps, kokaiCamps, kokai_camp_ids }) => {
            console.log('kokai_camp_ids', kokai_camp_ids);
            // If no Kokai campaigns, return just the restCamps
            if (kokai_camp_ids.length === 0) {
              return of({ data: restCamps, message: '' });
            }

            // Prepare gqlProps for the Kokai campaign query with type casting
            const gqlProps = {
              ttdAuthToken: ttdToken,
              payloadBuilder: campaignFlightsPayloadCampId as (jsonVars: {
                [key: string]: any;
              }) => string,
              jsonVars: {
                campaignIds: kokai_camp_ids,
                after: null,
                endDate: '2024-01-01'
              },
              responseFormatter: transformCampaignGql
            } as IQueryGQL<{ [key: string]: any }>;

            // Fetch Kokai data from GraphQL
            return queryGQL$(gqlProps).pipe(
              // Combine Kokai data with restCamps
              map((resDt: IResultsData) => {
                // Concatenate restCamps and kokaiData into a single array
                const { data } = resDt;
                // use CampaignId and CampainFlights from data (GQL result)
                //  for each element replace CampainFlights in kokaiCamps if we found a matching CampaignId in kokaiCamps

                updateCampsWithKokaiFlights(kokaiCamps, data);
                // // Create an index for kokaiCamps by CampaignId
                // // prettier-ignore
                // const kokaiCampaignIndex = new Map<string,IDeltaCampaignData>();
                // kokaiCamps.forEach((camp) => {
                //   kokaiCampaignIndex.set(camp.CampaignId, camp);
                // });

                // // Loop through each campaign returned from the GQL query
                // data.forEach((gqlCampaign: IDeltaCampaignData) => {
                //   // Get the matching campaign from the index
                //   const matchingKokaiCampaign = kokaiCampaignIndex.get(
                //     gqlCampaign.CampaignId
                //   );

                //   // If a matching campaign is found, replace its CampaignFlights
                //   if (matchingKokaiCampaign) {
                //     matchingKokaiCampaign.CampaignFlights =
                //       gqlCampaign.CampaignFlights;
                //   }
                // });

                // Combine restCamps with updated kokaiCamps
                const retCamps = restCamps.concat(kokaiCamps);
                resDt.data = retCamps;

                // the return of map is already an observable, so no of() is needed here
                return resDt;
              })
            );
          })
        );

        // Use firstValueFrom to convert observable to promise and await the result
        return await firstValueFrom(result$);
      }
    } else {
      throw new Error('Missing TTD_API_USER or TTD_API_PWD');
    }
  } catch (err) {
    console.error('Error:', err);
    throw err;
  }
};

// module.exports = { loadAdvDataKokai, addKokaiCampaignData, addKokaiData1 };
