import { enableSolimar } from '../vars';
export interface CampLinkProps {
  campaign_id: string;
  advertiser_id?: string;
  partner_id?: string;
}

export const generateCampaignLink = (props: CampLinkProps) => {
  var { campaign_id, advertiser_id } = props,
    campLink = '';
  if (enableSolimar) {
    campLink = `https://desk.thetradedesk.com/app/advertiser/${advertiser_id}/buy/campaign/${campaign_id}/details`;
  } else {
    campLink = `https://desk.thetradedesk.com/Campaigns/Detail/${campaign_id}?advertiser=${advertiser_id}#rtb-ad-groups`;
  }
  return campLink;
};
