/* eslint-disable no-unused-vars */
interface requestParams {
  method: string;
  shortUrl: string;
}

interface predictRequestParams extends requestParams {
  newRequests: number;
  responseDelay?: number;
}

interface predictRequestParamsArray extends Array<predictRequestParams> {}
interface shouldDelayRequest {
  shouldDelay: boolean;
  maxCallsPerMinute: number;
  currentCount: number;
}
type requestRoute = string;

interface routeRegistry {
  [key: string]: number;
}

const enableDebug =
  process.env.REACT_APP_DEBUG &&
  process.env.REACT_APP_DEBUG.toLocaleLowerCase() !== 'false';
// prettier-ignore
const idBase = ["additionalfees/adgroup/[a-z0-9]+$", "additionalfees/campaign/[a-z0-9]+$", "adgroup/[a-z0-9]+$", "adgroup/analyticsreport/name/[a-z0-9]+$", "adgroup/name/[a-z0-9]+$", "adgroup/status/name/[a-z0-9]+$", "advertiser/[a-z0-9]+$", "advertiser/name/[a-z0-9]+$", "audience/[a-z0-9]+$", "audience/name/[a-z0-9]+$", "bidlist/[a-z0-9]+$", "bidlist/name/[a-z0-9]+$", "campaign/[a-z0-9]+$", "campaign/name/[a-z0-9]+$", "campaignflight/[a-z0-9]+$", "campaignflight/name/[a-z0-9]+$", "contract/[a-z0-9]+$", "contract/name/[a-z0-9]+$", "contractgroup/[a-z0-9]+$", "contractgroup/name/[a-z0-9]+$", "creative/[a-z0-9]+$", "creative/name/[a-z0-9]+$", "creative/name/[a-z0-9]+$", "datagroup/[a-z0-9]+$", "datagroup/name/[a-z0-9]+$", "dmp/lookalikemodel/name/[a-z0-9]+$", "dmp/lookalikemodel/[a-z0-9]+$", "geosegment/[a-z0-9]+$", "geosegment/name/[a-z0-9]+$", "iptargetinglist/[a-z0-9]+$", "iptargetinglist/name/[a-z0-9]+$", "overview/advertiser/[a-z0-9]+$", "overview/advertiser/name/[a-z0-9]+$", "overview/partner/[a-z0-9]+$", "overview/partner/name/[a-z0-9]+$", "planningjob/status/name/[a-z0-9]+$", "sitelist/name/[a-z0-9]+$", "trackingtag/[a-z0-9]+$", "campaign/clone/status/[a-z0-9]+$"]

// capture rate limit defaults for requests most often used
const requestLimits: routeRegistry = {
  default: 60, // use default for everything we don't have here
  // prettier-ignore
  'get_adformat/query/facets': 200,
  'post_adformat/query': 200,
  delete_adgroup: 400,
  get_adgroup: 170,
  'post_campaign/clone': 50,
  'get_adgroup/query/facets': 100,
  post_adgroup: 32,
  'post_adgroup/query/advertiser': 100,
  'post_adgroup/query/campaign': 150,
  put_adgroup: 40,
  'put_adgroup/geosegmentadjustments': 120,
  'put_adgroup/status': 200,
  'post_adtechnology/query': 200,
  get_advertiser: 400,
  'get_advertiser/query/facets': 200,
  'get_advertiser/query/ids': 200,
  post_advertiser: 12,
  'post_advertiser/query/partner': 120,
  put_advertiser: 80,
  get_audience: 400,
  'get_audience/query/facets': 200,
  post_audience: 50,
  'post_audience/query/advertiser': 400,
  put_audience: 80,
  post_authentication: 10000,
  delete_bidlist: 30,
  get_bidlist: 200,
  'post_bidlist/batch/get': 30,
  'post_bidlist/query/adgroup': 200,
  put_bidlist: 60,
  'put_bidlist/batch': 5,
  'post_bidlistsummary/query/adgroup/available': 600,
  'post_bidlistsummary/query/advertiser/available': 300,
  get_campaign: 400,
  'get_campaign/query/facets': 100,
  post_campaign: 60,
  'post_campaign/query/advertiser': 300,
  put_campaign: 200,
  get_campaignflight: 1000,
  post_campaignflight: 120,
  put_campaignflight: 120,
  'get_category/industrycategories': 120,
  get_contract: 100,
  post_contract: 20,
  'post_contract/query/advertiser/available': 6,
  'post_contract/query/partner': 80,
  'post_contract/query/partner/available': 20,
  'post_contract/query/supplyvendordeal': 200,
  'post_contract/report/impressions/available': 600,
  put_contract: 80,
  post_contractgroup: 10,
  'post_contractgroup/query/advertiser/available': 20,
  put_contractgroup: 10,
  get_creative: 500,
  'get_creative/name': 1000,
  'get_creative/query/facets': 100,
  post_creative: 50,
  'post_creative/generateuploadurlforvideocreative': 100,
  'post_creative/query/advertiser': 300,
  put_creative: 100,
  'post_crossdeviceattributionmodel/query/advertiser': 50,
  'get_currency/query/facets': 200,
  'post_currency/query': 200,
  get_datagroup: 500,
  post_datagroup: 30,
  'post_datagroup/query/advertiser': 50,
  put_datagroup: 120,
  'get_datarate/batch': 500,
  'post_deliveryprofile/query/partner': 50,
  post_delta: 600,
  'post_delta/adgroup/query/advertiser': 200,
  'post_delta/adgroup/query/campaign': 600,
  'post_delta/advertiser': 600,
  'post_delta/campaign': 600,
  'post_delta/campaign/query/advertiser': 300,
  'post_delta/contract/query/advertiser/available': 600,
  'post_delta/creative': 600,
  'post_delta/creative/query/advertiser': 600,
  'post_delta/dmp/firstparty': 600,
  'post_delta/dmp/query/advertiser/firstparty': 300,
  'post_delta/sitelist/query/advertiser': 600,
  'get_dmp/firstparty/facets': 200,
  'get_dmp/lookalikemodel': 200,
  'get_dmp/thirdparty/facets': 50,
  'post_dmp/firstparty/advertiser': 40,
  'post_dmp/lookalikethirdpartydata/query': 5,
  'post_dmp/thirdparty/advertiser': 30,
  post_dynamicparameterretargeting: 480,
  'get_forecasting/facets': 200,
  post_forecasting: 4,
  'post_geoelement/city/query/region': 5,
  'post_geoelement/zip/query/country': 18,
  put_geoevent: 10,
  get_geosegment: 50,
  post_geosegment: 9,
  'post_geosegment/query/advertiser': 40,
  'post_geosegment/query/partner': 5,
  put_geosegment: 25,
  post_hdreports: 1230,
  get_iptargetinglist: 200,
  post_iptargetinglist: 60,
  'post_iptargetinglist/query/advertiser': 120,
  put_iptargetinglist: 5,
  'post_language/query': 120,
  'post_myreports/reportexecution/query/advertisers': 25,
  'post_myreports/reportexecution/query/partners': 50,
  'post_myreports/reportschedule': 100,
  'post_myreports/reportschedule/query': 25,
  'post_myreports/reporttemplateheader/query': 20,
  post_offlinetrackingtag: 60,
  'post_offlinetrackingtag/query/advertiser': 120,
  'get_overview/advertiser': 20,
  'get_overview/partner': 10,
  'get_partner/query/facets': 200,
  'get_planningjob/status': 150,
  post_planningtool: 120,
  'post_rightmediaoffertype/query': 200,
  get_sitelist: 300,
  post_sitelist: 25,
  'post_sitelist/query/advertiser': 200,
  put_sitelist: 40,
  'post_spreadsheet/export': 5,
  'post_supplyvendor/query/advertiser': 120,
  'post_supplyvendor/query/partner': 120,
  post_thirdpartydata: 60,
  'post_thirdpartydata/query': 200,
  put_thirdpartydata: 60,
  'post_tracking/appeventtrackerwithactivity/advertiser/query': 400,
  'post_tracking/universalpixel': 40,
  'post_tracking/universalpixelwithactivity/advertiser/query': 120,
  'put_tracking/universalpixel': 120,
  get_trackingtag: 200,
  'get_trackingtag/query/facets': 200,
  post_trackingtag: 80,
  'post_trackingtag/query/advertiser': 400,
  put_trackingtag: 120,
  'post_universalforecasting/generate': 10
};

var currentRequests: routeRegistry = {};

/**
 * handles route matching to filter out potential ids
 * @param props = { method: string; shortUrl: string;}
 */
const getRoute = (props) => {
  var { method, shortUrl } = props;
  var route,
    idMatch = false;
  var lMethod = method.toLowerCase();
  if (lMethod === 'get') {
    idMatch = idBase.some((el) => {
      var idRe = new RegExp(el);
      var match = idRe.exec(shortUrl);
      if (match !== null) {
        route = el.replace(/^\//, '').replace('/[a-z0-9]+$', '');
      }
      return match !== null;
    });
  }
  if (!idMatch) {
    route = shortUrl.replace(/^\//, '');
  }
  route = lMethod + '_' + route;
  return route;
};

/**
 * returns the number of requests allowed per minute
 * @param props = { method: string; shortUrl: string
 *                newRequests - number of requests }
 */
export const getRequestLimitPerMinute = (props: requestParams) => {
  var { method, shortUrl } = props;
  if (!method || !shortUrl) {
    console.warn('getRequestLimitPerMinute - method and shortUrl are required');
    return { maxPerMinute: 0, trackedRoute: 'default' };
  }
  var trackedRoute = getRoute(props);
  var maxPerMinute = requestLimits.default || 60;
  if (requestLimits[trackedRoute]) {
    maxPerMinute = requestLimits[trackedRoute];
  }
  if (enableDebug)
    console.log(
      'getPredictedRequestMinutes - trackedRoute ' +
        trackedRoute +
        ' : max ' +
        maxPerMinute
    );
  var maxCallsPerMinute = Math.floor(maxPerMinute * 0.98);
  return { maxCallsPerMinute, trackedRoute };
};

/**
 * registers a new request and increments the request counter
 * @param props = { method: string; shortUrl: string;}
 */
export const registerRequest = (props: requestParams) => {
  if (enableDebug)
    console.log(
      'currentRequests:' + JSON.stringify(currentRequests),
      currentRequests
    );
  var trackedRoute = getRoute(props);
  var currentCount = currentRequests[trackedRoute] || 0;
  currentRequests[trackedRoute] = ++currentCount;
  if (enableDebug)
    console.log(
      'registerRequest - trackedRoute ' +
        trackedRoute +
        ' : ' +
        currentRequests[trackedRoute]
    );
};

/**
 * finishes a request and decrements the request counter
 * @param props = { method: string; shortUrl: string;}
 */
export const finishRequest = (props: requestParams) => {
  if (enableDebug)
    console.log(
      'currentRequests:' + JSON.stringify(currentRequests),
      currentRequests
    );
  var trackedRoute = getRoute(props);
  var currentCount = currentRequests[trackedRoute] || 1;
  currentRequests[trackedRoute] = --currentCount;
  if (enableDebug)
    console.log(
      'finishRequest - trackedRoute ' +
        trackedRoute +
        ' : ' +
        currentRequests[trackedRoute]
    );
};

/**
 * check if a  new request stays below the limit and should be allowed
 * @param props = { method: string; shortUrl: string;}
 */
export const shouldAllowRequest = (
  props: requestParams
): shouldDelayRequest => {
  var { maxCallsPerMinute, trackedRoute } = getRequestLimitPerMinute(props);
  maxCallsPerMinute = maxCallsPerMinute || 60;
  var currentCount = currentRequests[trackedRoute] || 0;
  currentCount++;
  var shouldDelay = currentCount > 1;
  if (enableDebug)
    console.log(
      'shouldAllowRequest - trackedRoute ' +
        trackedRoute +
        ' : ' +
        currentCount +
        ' shouldDelay: ' +
        shouldDelay
    );
  return { maxCallsPerMinute, shouldDelay, currentCount };
};

/**
 * returns the estimated minutes to finish a number of requests
 * @param props = { method: string; shortUrl: string
 *                newRequests - number of requests }
 */
export const getPredictedRequestMinutes = (
  props: predictRequestParamsArray
) => {
  if (!Array.isArray(props)) {
    return 0;
  }
  var estimatedTime = props.reduce((acc, el) => {
    var { maxCallsPerMinute, trackedRoute } = getRequestLimitPerMinute(el),
      { newRequests, responseDelay } = el;
    var requests = currentRequests[trackedRoute] || 0;
    maxCallsPerMinute = maxCallsPerMinute || 60;
    requests += newRequests;
    // number of calls we can make per minute without exceeding the rate limit
    var slotSpacing = Math.ceil(60 / maxCallsPerMinute),
      // waiting time until next request, once requests for current minute were sent
      minuteBreakGap = Math.ceil(
        Math.max(slotSpacing * maxCallsPerMinute - 60, 0)
      ),
      // full minutes needed considering rate limit
      baseMinutes = Math.floor(requests / maxCallsPerMinute),
      // requests which can't be fired in full minutes above
      requestMod = requests % maxCallsPerMinute,
      // correction in seconds to handle minute break gap
      gapBase = baseMinutes * minuteBreakGap,
      // gapMinutes= Math.floor(gapBase/60),
      // gapSeconds=gapBase % 60,
      lastRequestStart =
        baseMinutes * 60 + (requestMod - 1) * slotSpacing + gapBase,
      lastRequestEnd = lastRequestStart + (responseDelay || 0),
      duration = `minutes: ${Math.floor(lastRequestEnd / 60)} seconds: ${
        lastRequestEnd % 60
      }`;

    if (enableDebug)
      console.log(
        'getPredictedRequestMinutes - trackedRoute ' +
          trackedRoute +
          ' : ' +
          requests +
          ' lastRequestEnd: ' +
          lastRequestEnd +
          ' duration:' +
          duration
      );
    // eslint-disable-next-line no-param-reassign
    acc += lastRequestEnd;
    return acc;
  }, 0);
  return estimatedTime;
};
