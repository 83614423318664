import axios from 'axios';
import {
  userInfo,
  UserInfoTTD,
  listAdvertisersForPartnerInterface,
  partnerInterface
} from '../actions/actionTypes';
import { getCookies, setCookies } from '../utils';

import { baseUrl} from './../vars';

const ttdUrl = baseUrl + '/v3';

export const getTokenPackageAPI_OIDC = ({ username, ttdToken }) => {
  return axios
    .post(`${baseUrl}/pkg/authToken`, {
      user: username,
      apitoken: ttdToken,
      useSB:  'n'
    })
    .then(async (response) => {
      if (response.status === 200 && response.data.authToken) {
        setCookies('packageToken', response.data.authToken);
        return response.data.authToken;
      }

      throw response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTokenPackageAPI = (data: userInfo) => {
  return axios
    .post(`${baseUrl}/pkg/auth`, data)
    .then(async (response) => {
      response.status === 200 &&
        response.data.authToken &&
        setCookies('packageToken', response.data.authToken);

      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getTokenTTDAPI = (data: UserInfoTTD) => {
  return axios
    .post(`${ttdUrl}/authentication`, data)
    .then(async (responseTtd) => {
      responseTtd.status === 200 &&
        responseTtd.data.Token &&
        setCookies('ttdToken', responseTtd.data.Token);

      return responseTtd;
    })
    .catch((error) => {
      return error.message;
    });
};

export const getPartners = (data: partnerInterface) => {
  return axios
    .post(`${ttdUrl}/partner/query`, data, {
      headers: { 'TTD-Auth': getCookies('ttdToken') }
    })
    .then((resPtn) => {
      if (resPtn.status === 200) {
        return resPtn.data;
      }
      return undefined;
    })
    .catch((error) => {
      console.log(error.message);

      return error.message;
    });
};

export const getListAdvertisersForPartner = (
  data: listAdvertisersForPartnerInterface
) => {
  return axios
    .post(`${ttdUrl}/advertiser/query/partner`, data, {
      headers: { 'TTD-Auth': getCookies('ttdToken') }
    })
    .then((resAdv) => {
      if (resAdv.status === 200) {
        // console.log('TTD List Advertisers For Partner: ' + resAdv.data);
        return resAdv.data;
      }
      return undefined;
    })
    .catch((error) => {
      console.log(error.message);

      return error.message;
    });
};
