import { Observable } from 'rxjs';
import { IResultsData, requestTtdApi$ } from './requestTtdApi';

export interface IPostPutCampaign {
  // auth token
  ttdAuthToken: string;
  // method
  method: 'POST' | 'PUT';
  // JSON payload
  sJSON: string | JSON;
  // parallel runs information for handling token recovery correctly
}

enum DateStrBrand {}
export type DateStr = string & DateStrBrand;
export type Int = number & { __int__: void };

type campPacing = 'PaceToEndOfFlight' | 'PaceAhead' | 'Off';

interface campBudget {
  Amount: number;
  CurrencyCode: string;
}

// const formatDateStrUTC = (dtStr: string): DateStr => {
//   let mDt = new Date(dtStr);
//   let sDt = mDt.toISOString().replace('Z', '+00:00') as DateStr;
//   // console.log('formatDateUTC date:' + mDt.toLocaleString() + ' to ' + dtStr);
//   return sDt;
// };

interface campFlight {
  BudgetInAdvertiserCurrency: number;
  StartDateInclusiveUTC: string;
  EndDateExclusiveUTC: string;
}

export interface genCampJson {
  // if Budget / StartDate is specified, we can't specify CampaignFlights
  Budget?: campBudget;
  StartDate?: string;
  EndDate?: string;
  AutoAllocatorEnabled?: boolean;
  AutoPrioritizationEnabled?: boolean;
  PacingMode: campPacing;
  CampaignFlights?: Array<campFlight>;
  // CampaignConversionReportingColumns are required, but can be an empty Array
  CampaignConversionReportingColumns: Array<any>;
  AdvertiserId: string;
  CampaignName: string;
  Description?: string;
  TimeZone?: string;
}

export const generateCampaignJson = (props: genCampJson) => {};

export const postPutCampaign$ = (
  props: IPostPutCampaign
): Observable<IResultsData> => {
  var { ttdAuthToken, sJSON, method } = props;
  // for posts CampaignId does not exist, so take CampaignName instead as error id
  var errorId = 'CampaignName',
    requestFilterFieldList: Array<any> = ['op', 'cfl', 'nameUpdate', 'rowIdx'];
    if (method === 'PUT') {
      requestFilterFieldList = [
        'op',
        'UpdateAdGroupBudgets'
      ];
    }
    if (method === 'POST') {
    requestFilterFieldList = [
      'CampaignId',
      'op',
      'cfl',
      'nameUpdate',
      'rowIdx',
      'UpdateAdGroupBudgets'
    ];
  }
  // @ts-ignore
  else if (sJSON.CampaignId) {
    // if we have a campaign id then we take it as error id
    errorId = 'CampaignId';
  }
  return requestTtdApi$({
    ttdAuthToken,
    sJSON,
    method,
    shortUrl: '/campaign',
    requestFilterFieldList,
    responseFilterFieldList: [
      'Availability',
      'AdvertiserId',
      'CampaignName',
      'CampaignId',
      'StartDate',
      'EndDate',
      'Budget',
      'DailyBudget',
      'BudgetInImpressions',
      'DailyBudgetInImpressions',
      'PacingMode',
      'TimeZone',
      'CampaignFlights',
      'CampaignConversionReportingColumns'
    ],
    errorId
  });
};
