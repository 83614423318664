import XLSX from 'sheetjs-style';
import {
  getAdvertiserAdgroups,
  getAdvertisers,
  getCampaigns,
  getPartners,
  reportProgress
} from '../../../ttdApi/ttdapi';
import { getCookies, readTemplate, sortJsonArr, strSort } from '../../../utils';
import { ICampAdgroups } from '../../../utils/processExcelFile';
import {
  pkgExportPackage,
  ttdAdvertiserData,
  ttdCampaignData
} from './../../../actions/actionTypes';
import {
  exportPackages,
  getPkgAdvertiserConfig
} from './../../../services/packageService';
const { utils } = XLSX;

const prepareExcel = (pkgExportArr: pkgExportPackage[]) => {
  /* pkgArr = [{"ai":"qwu829h","pi":1,"pn":"New Orleans","m":[{"ci":"q8ru469","cn":"Medium_New Orleans_Near Market_GM_Display_Altima"}, */

  // prettier-ignore
  var pkgHeader1 = ['ai', 'pi', 'pn'],
    pkgHeader2 = ['ci', 'cn'];
  var xlArr: Array<any> = [];
  // xlArr.push([...xlHeader1, ...xlHeader2]);
  pkgExportArr.forEach((row) => {
    // console.log('row' + JSON.stringify(row));
    var baseArr: Array<any> = [];
    // handle package level
    pkgHeader1.forEach((header) => {
      //@ts-ignore
      var value = row[header];
      baseArr.push(value);
    });
    // log("baseArr: " + JSON.stringify(baseArr));
    // get campaigns
    var camps = row['m'];
    var cArr = camps.map((camp) => {
      var c2Arr: Array<any> = [];
      pkgHeader2.forEach((cHeader) => {
        //@ts-ignore
        c2Arr.push(camp[cHeader]);
      });
      // log(JSON.stringify(c2Arr));
      return c2Arr;
    });
    // log(cArr);
    cArr.forEach((cRow) => {
      var combArr = [...baseArr, ...cRow];
      // log(combArr);
      xlArr.push(combArr);
    });
  });
  // console.log(JSON.stringify(xlArr));
  return xlArr;
};

/**
 * generate Array of Arrays for Campaign Data
 * @param campData - campaign data from TTD API
 */
const transformCampData = (campData: ttdCampaignData[]) => {
  var campFilter = ['AdvertiserId', 'CampaignId', 'CampaignName'],
    flightFilter = [
      'CampaignFlightId',
      'StartDateInclusiveUTC',
      'EndDateExclusiveUTC',
      'BudgetInAdvertiserCurrency',
      'DailyTargetInAdvertiserCurrency',
      'BudgetInImpressions',
      'DailyTargetInImpressions'
    ],
    rowArr: Array<any> = [];
  campData.forEach((campaign) => {
    var dtArr: Array<any> = ['', '', '', '', '', '', '', '', '', '', ''];
    campFilter.forEach((campEl, idx) => {
      dtArr[idx] = campaign[campEl];
    });
    // pacing nd timezone go last, but we need to process campaign flights separately
    var pm = (dtArr[10] = campaign['PacingMode']);
    dtArr[11] = campaign['TimeZone'];
    // copy array as we might have more than 1 flight
    var cfArr = campaign.CampaignFlights;
    cfArr.forEach((cf) => {
      var curArr = [...dtArr];
      flightFilter.forEach((flightEl, cIdx) => {
        var val = cf[flightEl];
        if (flightEl.includes('Date')) {
          // dates are in UTC format - replace "T" by " "
          if (val) {
            // safety in case a date like end date is not defined
            val = val.replace('T', ' ');
            val = new Date(val + ' +00:00');
          } else {
            val = '';
          }
        } else if (flightEl.includes('get')) {
          // Target or Budget
          if (val !== null) {
            val = Number(val);
          }
        }
        if (flightEl.includes('Daily') && val !== null) {
          // daily budgets / imps are only allowed for pacing = Off
          if (pm && pm !== 'Off') {
            val = null;
          }
        }
        if (val !== undefined && val !== null) {
          curArr[cIdx + 3] = val;
        }
      });
      rowArr.push(curArr);
    });
  });
  return rowArr;
};

/**
 * filters out Advertisers we don't have enabled
 * @param advArr - advertiser array to filter
 * @returns filtered result
 */
export const filterUnconfiguredAdvertisers = async (advArr: Array<string>) => {
  if (!Array.isArray(advArr)) {
    return [];
  }
  var filterBase = await getPkgAdvertiserConfig();
  return advArr.filter((el) => filterBase.includes(el));
};

/**
 * load ad groups for advertiser ids from TTD API
 * @param advIds - array of AdvertiserIds - if empty an empty Array will be returned
 * @param progressCallback
 */
export const loadAdvAdGroups = async (
  advIds: Array<string>,
  progressCallback: Function
) => {
  var agData: ICampAdgroups[] = [];
  if (advIds && advIds.length > 0) {
    var ttdToken = getCookies('ttdToken');
    if (ttdToken) {
      var agResp = await getAdvertiserAdgroups(
        ttdToken,
        advIds,
        progressCallback
      );
      var { data } = agResp;
      // if (data) {
      //   console.log(JSON.stringify(data));
      // }
      agData = data || [];
    }
  }
  return agData;
};

/**
 * get Campaigns for advertiser ids from TTD API
 * @param advIds - array of AdvertiserIds - if empty, all  advertiser ids for accessible partners will be used
 * @param progressCallback
 */
export const loadCampaignData = async (
  advIds: Array<string>,
  progressCallback: Function
) => {
  var campData: ttdCampaignData[] = [];
  var ttdToken = getCookies('ttdToken');
  if (ttdToken) {
    if (!advIds || advIds.length === 0) {
      var partnerRes = await getPartners(ttdToken);
      var partners = partnerRes.data;
      partners = partners.map((el) => el.PartnerId);
      var pLen = partners.length;
      var advertisers: ttdAdvertiserData[] = [];
      for (let pIdx = 0; pIdx < pLen; pIdx++) {
        const partner = partners[pIdx];
        // eslint-disable-next-line no-await-in-loop
        var pAdvRes = await getAdvertisers(ttdToken, partner);
        var pAdv: ttdAdvertiserData[] = pAdvRes.data;
        advertisers = advertisers.concat(pAdv);
      }
      // console.log('advertisers: ' + JSON.stringify(advertisers));
      advIds = advertisers.map((el) => el.AdvertiserId);
      // filter advertiser ids to match configured advertisers
      advIds = await filterUnconfiguredAdvertisers(advIds);
    }
    if (advIds.length > 0) {
      var campRes = await getCampaigns(ttdToken, advIds, progressCallback);
      campData = sortJsonArr(campRes?.data || [], strSort, 'CampaignName');
    }
  }
  return { campData, advIds };
};

export const handlePackageCampaigns = async (progressCallback: Function) => {
  const step1Progress = (percent) => {
    progressCallback(1, percent);
  };
  const pkgProgress_p = (pct) => {
    console.log('pkgProgress_p' + pct);
    progressCallback(0, pct);
  };
  var pkgProgress = reportProgress(pkgProgress_p, true, '');
  try {
    if (typeof pkgProgress === 'function') {
      pkgProgress({ percent: 20 });
      setTimeout(() => {
        if (typeof pkgProgress === 'function') {
          pkgProgress({ percent: 50 });
        }
      }, 500);
      setTimeout(() => {
        if (typeof pkgProgress === 'function') {
          pkgProgress({ percent: 75 });
        }
      }, 1000);
    }
    var wb = await readTemplate('./Package_Campaign_Cloning_Template.xlsx');

    if (wb) {
      var sName = wb.SheetNames[0];
      var mySheet = wb.Sheets[sName];
      // read file with blanks for header detection

      var pkgArr = await exportPackages([]);
      if (typeof pkgProgress === 'function') {
        pkgProgress({
          percent: 100,
          immediate: true
        });
      }
      console.log(JSON.stringify(pkgArr));
      var sheetData = prepareExcel(pkgArr);
      var pkgSheet = utils.sheet_add_aoa(mySheet, sheetData, {
        dateNF: 22,
        origin: 1 // 2nd row
      });
      wb.Sheets[sName] = pkgSheet;
      step1Progress(0.01);
      var { campData } = await loadCampaignData([], step1Progress);
      console.log('campload done');
      console.log(JSON.stringify(campData));
      sheetData = [];
      if (campData.length > 0) {
        sheetData = transformCampData(campData);
        sName = wb.SheetNames[1];
        mySheet = wb.Sheets[sName];
        var campSheet = utils.sheet_add_aoa(mySheet, sheetData, {
          dateNF: 'yyyy-mm-dd hh:mm:ss', //22,
          origin: 1 // 2nd row
        });

        // ['I', 'J'].forEach((col) => {
        //   getColumn(col, campSheet).forEach((el) => {
        //     campSheet[el].z = 'yyyy-mm-dd hh:mm:ss';
        //   });
        // });
        wb.Sheets[sName] = campSheet;
      }
      XLSX.writeFile(wb, 'package_campaign_export.xlsx', {
        bookType: 'xlsx',
        type: 'file',
        cellDates: false
      });
      step1Progress(100);
    }
  } catch (e) {
    if (e instanceof Error) {
      console.log(e.message);
    }
  }
};

export const exportPackageCampaigns = (
  setStart: Function,
  progressCallback: Function
) => {
  setStart(true);
  // set progress bar to export mode
  progressCallback(0, 0.01, 'e');
  handlePackageCampaigns(progressCallback).then(() => {
    // reset progress step state
    setTimeout(() => {
      progressCallback(-1, 0);
    }, 500);
    setStart(false);
  });
};
