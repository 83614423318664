import { IRunBaseId } from './LoadData';
import { readQuery$ } from './readQuery';

interface IRunBaseRead extends IRunBaseId {
  BaseIdName?: string;
  BaseId: string;
}

/**
 * read advertisers + filter relevant properties
 * @param props
 */
export const readAdvertiser$ = (props: IRunBaseRead) => {
  var BaseIdName = 'PartnerId';
  return readQuery$({
    ...props,
    BaseIdName,
    // In value to use
    // Id name e.g. AdvertiserId
    method: 'POST',
    shortUrl: '/advertiser/query/partner',
    responseFilterFieldList: ['AdvertiserId', 'AdvertiserName', 'PartnerId'],
    errorId: BaseIdName
  });
};
