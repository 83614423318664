import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { Link, useLocation, useHistory } from 'react-router-dom';

import {
  getListPkgPerformanceOverview,
  searchPackagesList
} from '../../actions';
import PackagePerformanceTable from './PackagePerformanceTable';
import SearchList from './SearchPackages';
import RecentlyViewed from './RecentlyViewed';
import Button from '@ttd/maui/lib/components/core/Button';
import { SORT_DIRECTION } from '@ttd/maui/lib/components/display/DataTable/DataTable';
import './Homepage.scss';

import {
  HOMEPAGE_URL_CHANGED,
  PACKAGE_PERFORMANCE_HISTORY_SUCCESS_ACTION,
  PKG_PERFORMANCE_DETAIL_SUCCESS_ACTION
} from '../../actions/actionTypes';
import {
  getAdvertiserOptionsFromPackages,
  getPackageListByAdvertiser
} from '../../utils/packageUtils';
import {
  BULK_EDIT_PATH,
  BULK_EXPORT_PATH,
  PACKAGE_PERFORMANCE_DETAIL_PATH,
  PACKAGE_SETTING_PATH
} from '../../constants/route';

const getPackagePerformanceHistorySuccess = (payload) => {
  return {
    type: PACKAGE_PERFORMANCE_HISTORY_SUCCESS_ACTION,
    payload
  };
};
const getPackagePerformanceDetailSuccess = (payload) => {
  return {
    type: PKG_PERFORMANCE_DETAIL_SUCCESS_ACTION,
    payload
  };
};

const Homepage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const myHistory = useHistory();
  const [term, setTerm] = useState('');
  const [selectedPackageId, setSelectedPackageId] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [sortField, setSortField] = useState('');
  const [packageList, setPackageList] = useState([] as any);
  const [advertiserOption, setAdvertiserOption] = useState({
    id: '',
    name: ''
  });
  const recentlyViewed = useSelector((state: RootState) => state.search.list);
  const isLoading = useSelector((state: RootState) => state.request.isLoading);
  // console.log('Homepage isLoading: ' + isLoading);
  const listPackagePerformanceOverview = useSelector(
    (state: RootState) => state.package.listPackagePerformanceOverview
  );
  const [reportsOutdated, setReportsOutdated] = useState(false);
  const datePlaceholder =
    '\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0';
  const onPackageSelected = (name, packageId) => {
    setTerm(name);
    setSelectedPackageId(packageId);
  };

  const handleUpdateParams = (sf: string) => {
    let so = SORT_DIRECTION.ASCENDING;
    if (sf !== sortField) {
      so = SORT_DIRECTION.ASCENDING;
    } else if (sortOrder === SORT_DIRECTION.ASCENDING) {
      so = SORT_DIRECTION.DESCENDING;
    }
    let queryStr = window.location.search;
    if (queryStr !== '') {
      if (queryStr.indexOf('Limit') > -1) {
        queryStr = queryStr.replace(/(Limit=)[^&]+/, '$110000');
      } else {
        queryStr += '&Limit=10000';
      }
      if (queryStr.indexOf('Offset') > -1) {
        queryStr = queryStr.replace(/(Offset=)[^&]+/, '$10');
      } else {
        queryStr += '&Offset=0';
      }
      if (queryStr.indexOf('SortField') > -1) {
        queryStr = queryStr.replace(/(SortField=)[^&]+/, '$1' + sf);
      } else {
        queryStr += `&SortField=${sf}`;
      }
      if (queryStr.indexOf('SortOrder') > -1) {
        queryStr = queryStr.replace(/(SortOrder=)[^&]+/, '$1' + so);
      } else {
        queryStr += `&SortOrder=${so}`;
      }
    } else {
      queryStr = `?Limit=10000&Offset=0&SortField=${sf}&SortOrder=${so}`;
    }
    window.history.replaceState(null, '', queryStr);
    dispatch({ type: HOMEPAGE_URL_CHANGED, payload: `/${queryStr}` });
    setSortField(sf);
    setSortOrder(so);
  };

  const filterPackageList = useCallback(
    (item = { id: 'all', name: 'All package advertisers' }) => {
      const filteredPackages = getPackageListByAdvertiser(
        item,
        listPackagePerformanceOverview.packages
      );
      setAdvertiserOption(item);
      setPackageList(filteredPackages);

      let queryStr = window.location.search;
      if (queryStr.indexOf('AdvertiserFilter') > -1) {
        queryStr = queryStr.replace(/(AdvertiserFilter=)[^&]+/, '$1' + item.id);
      } else {
        const separator = queryStr === '' ? '?' : '&';
        queryStr = `${queryStr}${separator}AdvertiserFilter=${item.id}`;
      }

      window.history.replaceState(null, '', queryStr);
      //url state update for later return from package details
      dispatch({ type: HOMEPAGE_URL_CHANGED, payload: `/${queryStr}` });
    },
    [listPackagePerformanceOverview.packages]
  );

  const advertiserOptions = useCallback(() => {
    return getAdvertiserOptionsFromPackages(
      listPackagePerformanceOverview.packages
    );
  }, [listPackagePerformanceOverview.packages]);

  useEffect(() => {
    dispatch(searchPackagesList('', 20, 0, 'Desc', 'last_viewed_at'));
  }, [dispatch]);

  useEffect(() => {
    // calculate yesterday and cut off all hours
    const yesterday = new Date(
      new Date(new Date().getTime() - 1000 * 60 * 60 * 24)
        .toISOString()
        .replace(/T.*/, '')
    );
    // set either last report date or today
    var comp = listPackagePerformanceOverview.report_date
        ? new Date(listPackagePerformanceOverview.report_date)
        : new Date(),
      tDiff = yesterday.getTime() - comp.getTime();
    // we have outdated reports if the date difference is greater 0
    setReportsOutdated(tDiff > 0);
  }, [listPackagePerformanceOverview]);

  useEffect(() => {
    let getUrlParams = new URLSearchParams();
    if (location.search) {
      getUrlParams = new URLSearchParams(location.search);
    }

    let so = getUrlParams.get('SortOrder');
    let sf = getUrlParams.get('SortField');
    if (so !== '' && so !== null && sf !== '' && sf !== null) {
      setSortField(sf);
      setSortOrder(so);
    } else {
      sf = 'pacing_percent';
      so = 'Desc';
    }
    console.log(' dispatch getListPkgPerformanceOverview ');
    dispatch(
      getListPkgPerformanceOverview({
        Limit: 10000,
        Offset: 0,
        SortField: sf,
        SortOrder: so
      })
    );
  }, [location.search, dispatch]);

  useEffect(() => {
    if (listPackagePerformanceOverview.length === 0) {
      return;
    }
    console.log(
      'update advertiser filter listPackagePerformanceOverview.length ' +
        listPackagePerformanceOverview.length
    );
    let getUrlParams = new URLSearchParams();
    if (location.search) {
      getUrlParams = new URLSearchParams(location.search);
    }

    let af = getUrlParams.get('AdvertiserFilter');
    let opt = { id: 'all', name: 'All package advertisers' };
    if (af !== '' && af !== null) {
      const temp = advertiserOptions().find((elem) => elem.id === af);
      if (temp !== undefined) {
        opt = temp;
      }
    }
    filterPackageList(opt);
    setAdvertiserOption(opt);
  }, [
    listPackagePerformanceOverview,
    location.search,
    filterPackageList,
    advertiserOptions
  ]);

  useEffect(() => {
    console.log(
      'dispatching getPackagePerformanceHistorySuccess / getPackagePerformanceDetailSuccess'
    );
    // clears the state in case we previewsly viewed a package
    dispatch(getPackagePerformanceHistorySuccess([]));
    dispatch(getPackagePerformanceDetailSuccess([]));
  }, [dispatch]);

  return (
    <div className="wrapper">
      <h1>Package UI</h1>
      <div className="home-page">
        <div className="left-content">
          <SearchList
            term={term}
            packageId={selectedPackageId}
            onPackageSelected={onPackageSelected}
          />
          <RecentlyViewed
            list={recentlyViewed}
            onPackageSelected={onPackageSelected}
          />
        </div>
        <div className="right-content">
          <div className="group-btn">
            <fieldset>
              <legend>Package Actions</legend>
              <Link
                to={`${PACKAGE_PERFORMANCE_DETAIL_PATH}/?PackageId=${selectedPackageId}`}
                className={selectedPackageId ? '' : 'disabled-link'}
              >
                <Button
                  data-testid="btn-performance"
                  displayType="primary"
                  disabled={!selectedPackageId}
                  onClick={() =>
                    myHistory.push(
                      `${PACKAGE_PERFORMANCE_DETAIL_PATH}/?PackageId=${selectedPackageId}`,
                      { from: `` }
                    )
                  }
                >
                  Performance
                </Button>
              </Link>
              <Link
                to={`${PACKAGE_SETTING_PATH}/?PackageId=${selectedPackageId}`}
                className={selectedPackageId ? '' : 'disabled-link'}
              >
                <Button
                  data-testid="btn-package-detail"
                  disabled={!selectedPackageId}
                >
                  Package Details
                </Button>
              </Link>
            </fieldset>
            <div className={'group-btn--report-date'}>
              <div
                className={
                  'group-btn--report-date--dt' +
                  (reportsOutdated ? '--outdated' : '')
                }
              >
                <p>
                  Current Report Date:{' '}
                  {listPackagePerformanceOverview.report_date
                    ? listPackagePerformanceOverview.report_date
                    : datePlaceholder}
                </p>
              </div>
              <Link
                to={`${BULK_EXPORT_PATH}?advertiser=${advertiserOption.id}`}
                className="btn-bulk-export"
              >
                <Button
                  type="button"
                  data-testid="btn-bulk-export"
                  displayType="secondary"
                >
                  EXPORT
                </Button>
              </Link>
              <Link to={BULK_EDIT_PATH}>
                <Button data-testid="btn-bulk-edit">
                  Bulk edit Packages and Campaigns
                </Button>
              </Link>
            </div>
          </div>

          <PackagePerformanceTable
            onPackageSelected={onPackageSelected}
            handleUpdateParams={handleUpdateParams}
            isLoading={isLoading}
            listPackagePerformanceOverview={packageList}
            advertiserOptions={advertiserOptions()}
            filterPackageList={filterPackageList}
            advertiserOption={advertiserOption}
          />
        </div>
      </div>
    </div>
  );
};

export default Homepage;
