import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Prompt } from 'react-router-dom';
import Button from '@ttd/maui/lib/components/core/Button';
import ButtonIcon from '@ttd/maui/lib/components/display/Button/Button';
import Glyph from '@ttd/maui/lib/components/core/Glyph/Glyph';
import TextControl from '@ttd/maui/lib/components/controls/TextControl';
import { RootState } from 'typesafe-actions';
import _ from 'lodash';

import UnassignedCampaigns from './UnassignedCampaigns';
import AssignedCampaigns from './AssignedCampaigns';
import { getPkgDetail } from '../../actions';
import { getQueryParams } from '../../utils';
import { updatePackage, deletePackage } from '../../actions/packageActions';
import Header from '../../partials/Header';
import './PackageSetting.scss';

const PackageSetting = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const packageDetail = useSelector(
    (state: RootState) => state.package.packageDetail
  );
  const pkgUnassignedCampaigns = useSelector(
    (state: RootState) => state.package.packageUnassignedCampaigns
  );

  const isLoading = useSelector((state: RootState) => state.request.isLoading);
  // const errMsg = useSelector((state: RootState) => state.package.errMsg);
  const [packageName, setPackageName] = useState('');
  const [editPackageName, setEditPackageName] = useState(packageName);
  const [isEdit, setIsEdit] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isChangedData, setIsChangedData] = useState(false);
  const [unassignCampaigns, setUnassignCampaigns] = useState([]);
  const [assignedCampaigns, setAssignedCampaigns] = useState([]);
  const [unassignKeyFilter, setUnassignKeyFilter] = useState('');
  const [assignedKeyFilter, setAssignedKeyFilter] = useState('');

  const onKeyFilterUpdate = (name: string, isAssigned: boolean = false) => {
    isAssigned ? setAssignedKeyFilter(name) : setUnassignKeyFilter(name);
  };

  const handleUpdatePackageName = () => {
    setIsEdit(false);
    setPackageName(editPackageName);
    setEditPackageName(editPackageName);
    setIsChangedData(
      packageDetail?.package_name !== undefined &&
        !isEmpty(packageDetail?.package_name) &&
        editPackageName !== packageDetail?.package_name
    );
  };

  const handleCancelPackageName = () => {
    setIsEdit(false);
    setEditPackageName(packageName);
  };

  const handCancel = () => {
    //Reset data and redirect to before page
    window.history.back();
  };
  const handleDelete = async () => {
    // Get Id from params
    const packageId = parseInt(getQueryParams(location.search).PackageId);
    // call API to delete package
    const flag = window.confirm('Are you sure you want to delete the package?');
    if (flag) {
      dispatch(deletePackage([packageId]));
      window.history.back();
    }
  };

  const alertUser = (e) => {
    if (isChangedData && !isLogout) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  const handleMasterSave = () => {
    // Get Id from params
    const packageId = parseInt(getQueryParams(location.search).PackageId);

    let memberIds: string[] = [];
    // Get list members id
    if (assignedCampaigns.length > 0) {
      memberIds = assignedCampaigns.map(function (obj: any) {
        return obj.campaign_id;
      });
    }

    //call API PUT
    dispatch(
      updatePackage([
        {
          PackageName: editPackageName,
          PackageId: packageId,
          Members: memberIds
        }
      ])
    );
    setIsChangedData(false);
  };

  const onAssignCampaigns = (unassignList, assignedList) => {
    setUnassignCampaigns(unassignList);
    setAssignedCampaigns(assignedList);
    setIsChangedData(true);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  });

  useEffect(() => {
    let getUrlParams = new URLSearchParams();
    if (location.search) {
      getUrlParams = new URLSearchParams(location.search);
    }

    let pid = getUrlParams.get('PackageId');
    if (pid !== null && pid !== undefined) {
      dispatch(getPkgDetail(pid));
    }
  }, [dispatch, location.search]);

  useEffect(() => {
    if (packageDetail) {
      setPackageName(packageDetail?.package_name);
      setEditPackageName(packageDetail?.package_name);
      setAssignedCampaigns(packageDetail?.members);
    }
  }, [packageDetail]);

  useEffect(() => {
    if (_.isEmpty(unassignCampaigns)) {
      setUnassignCampaigns(pkgUnassignedCampaigns);
    }
  }, [unassignCampaigns, pkgUnassignedCampaigns]);

  return (
    <>
      <Header isPkgSettingPage={true} actionLogout={() => setIsLogout(true)} />
      <div className="wrapper">
        {isChangedData && (
          <Prompt
            when={props.navigationBlocked}
            message="Changes you made may not be saved."
          />
        )}
        <div className="package-setting">
          {!isEdit && !isLoading && (
            <div className="package-name">
              <h1 onDoubleClick={() => setIsEdit(true)}>{packageName}</h1>
              <Glyph iconName="edit" onClick={() => setIsEdit(true)} />
            </div>
          )}
          {isEdit && (
            <div className="package-name--edit d-flex align-center">
              <TextControl
                className="package-name-textbox"
                value={editPackageName}
                onChange={(event) => setEditPackageName(event.target.value)}
              />
              <div className="btn-group d-flex">
                <ButtonIcon
                  data-testid="id-btn-save"
                  type="button"
                  displayType="secondary"
                  className="btn-unassign"
                  onClick={() => handleUpdatePackageName()}
                  disabled={isEmpty(editPackageName)}
                >
                  <Glyph iconName="good-CPM" />
                </ButtonIcon>
                <ButtonIcon
                  data-testid="id-btn-close"
                  type="button"
                  displayType="secondary"
                  className="btn-unassign"
                  onClick={() => handleCancelPackageName()}
                >
                  <Glyph iconName="delete-small" />
                </ButtonIcon>
              </div>
            </div>
          )}

          <h2>Package Setting</h2>
          <div className="top">
            <h2>Campaign Assignment</h2>
            <div className="group-btn">
              <Button
                type="button"
                ref={() => {}}
                data-testid="btn-save-setting"
                displayType="primary"
                onClick={() => handleMasterSave()}
                disabled={!isChangedData || isEdit || isLoading}
              >
                Save Settings
              </Button>

              <Button
                type="button"
                ref={() => {}}
                data-testid="btn-cancel"
                displayType="secondary"
                onClick={() => handCancel()}
              >
                Cancel
              </Button>
              <Button
                type="button"
                ref={() => {}}
                data-testid="btn-delete"
                displayType="secondary"
                onClick={() => handleDelete()}
              >
                Delete
              </Button>
            </div>
          </div>

          <div className="assign-campaign">
            <div className="left">
              <UnassignedCampaigns
                filterName={unassignKeyFilter}
                items={unassignCampaigns}
                onUpdateFilterName={onKeyFilterUpdate}
                assignedCampaigns={assignedCampaigns}
                onAssign={onAssignCampaigns}
              />
            </div>
            <div className="right">
              <AssignedCampaigns
                filterName={assignedKeyFilter}
                items={assignedCampaigns}
                onUpdateFilterName={onKeyFilterUpdate}
                unassignCampaigns={unassignCampaigns}
                onUnassign={onAssignCampaigns}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackageSetting;
