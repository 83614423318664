import {
  RESET_USER_ERROR_MESSAGE,
  SET_TOKEN_FAILED_ACTION,
  SET_TOKEN_SUCCESS_ACTION,
  USER_FAILED_ACTION,
  SET_USER_CREDENTIAL_FAILED_ACTION,
  SET_USER_CREDENTIAL_SUCCESS_ACTION,
  USER_LOGOUT_FAILED_ACTION,
  USER_LOGOUT_SUCCESS_ACTION,
  USER_SUCCESS_ACTION,
} from '../actions/actionTypes';

interface userState {
  loggedIn: boolean;
  username: string | null;
  ttdToken: any;
  packageToken: any;
  errorMessage: string;
}

const initialState: userState = {
  loggedIn: false,
  username: null,
  ttdToken: null,
  packageToken: null,
  errorMessage: ''
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN_SUCCESS_ACTION:
      return { ...state, ...action.payload };
    case SET_TOKEN_FAILED_ACTION:
      return { ...state };
    case USER_SUCCESS_ACTION:
      return { ...state, ...action.payload, loggedIn: true };
    case USER_FAILED_ACTION:
      return {
        ...state,
        loggedIn: false,
        errorMessage: 'Invalid username or password - Please try again.',
      };
    case SET_USER_CREDENTIAL_SUCCESS_ACTION:
      return { ...state, ...action.payload };
    case SET_USER_CREDENTIAL_FAILED_ACTION:
      return { ...state };
    case USER_LOGOUT_SUCCESS_ACTION:
      return {
        ...state,
        loggedIn: false,
        username: null,
        ttdToken: null,
        packageToken: null
      };
    case USER_LOGOUT_FAILED_ACTION:
      return { ...state };
    case RESET_USER_ERROR_MESSAGE:
      return { ...state, errorMessage: '' };
    default:
      return { ...state };
  }
};

export default userReducer;
