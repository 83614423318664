import {
  PACKAGE_PERFORMANCE_HISTORY_SUCCESS_ACTION,
  PACKAGE_PERFORMANCE_HISTORY_FAILED_ACTION,
  CLEAN_STATE,
  CleanStateAction,
  PackageDetailActionTypes,
  PackageDetailParameterActionTypes,
  PackageDetailType,
  PackageParameterActionTypes,
  PackagePerformanceDetailActionTypes,
  PackagePerformanceDetailType,
  PackagePerformanceOverviewActionTypes,
  PackagePerformanceOverviewType,
  PKG_DETAIL_FAILED_ACTION,
  PackageUpdateActionTypes,
  PackageDeleteActionTypes,
  packageInterface,
  PKG_UPDATE_SUCCESS_ACTION,
  PKG_UPDATE_FAILED_ACTION,
  PKG_DELETE_FAILED_ACTION,
  PKG_DELETE_SUCCESS_ACTION,
  PKG_DETAIL_SUCCESS_ACTION,
  PKG_PERFORMANCE_DETAIL_FAILED_ACTION,
  PKG_PERFORMANCE_DETAIL_SUCCESS_ACTION,
  PKG_PERFORMANCE_OVERVIEW_FAILED_ACTION,
  PKG_PERFORMANCE_OVERVIEW_SUCCESS_ACTION,
  PKG_UNASSIGNED_CAMPAIGN_FAILED,
  PKG_UNASSIGNED_CAMPAIGN_SUCCESS,
  PkgUnassignedCampaignsActionTypes
} from './actionTypes';
import { requestStart, requestStop } from './requestActions';
import {
  getListPkgPerformanceOverviewAPI,
  getPkgDetailAPI,
  updatePackageAPI,
  deletePackageAPI,
  getPkgPerformanceDetailAPI,
  loadPkgUnassignedCampaign,
  loadPackagePerformanceHistory
} from '../services/packageService';

const getPackagePerformanceHistorySuccess = (payload) => {
  return {
    type: PACKAGE_PERFORMANCE_HISTORY_SUCCESS_ACTION,
    payload
  };
};

const getPackagePerformanceHistoryFailed = () => {
  return {
    type: PACKAGE_PERFORMANCE_HISTORY_FAILED_ACTION,
    payload: ''
  };
};

export const getPackagePerformanceHistory = (id: string) => {
  return async (dispatch) => {
    try {
      dispatch(requestStart());
      const data = await loadPackagePerformanceHistory(id);
      dispatch(getPackagePerformanceHistorySuccess(data));
      dispatch(requestStop());
    } catch (e) {
      dispatch(getPackagePerformanceHistoryFailed());
    }
  };
};

const getListPkgPerformanceOverviewSuccessAction = (
  listPkgPerformanceOverview: PackagePerformanceOverviewType[]
): PackagePerformanceOverviewActionTypes => {
  return {
    type: PKG_PERFORMANCE_OVERVIEW_SUCCESS_ACTION,
    payload: listPkgPerformanceOverview
  };
};

const getListPkgPerformanceOverviewFailedAction = (): PackagePerformanceOverviewActionTypes => {
  return {
    type: PKG_PERFORMANCE_OVERVIEW_FAILED_ACTION
  };
};

export const getListPkgPerformanceOverview = (
  pkgPerformanceOverview: PackageParameterActionTypes
) => {
  return async (dispatch) => {
    try {
      dispatch(requestStart());

      const getListPackageResp = await getListPkgPerformanceOverviewAPI({
        Limit: pkgPerformanceOverview.Limit,
        Offset: pkgPerformanceOverview.Offset,
        SortField: pkgPerformanceOverview.SortField,
        SortOrder: pkgPerformanceOverview.SortOrder
      });

      dispatch(getListPkgPerformanceOverviewSuccessAction(getListPackageResp));
      dispatch({
        type: '@@enhanceddataview/DATAVIEW_CONFIG_INITIALIZE',
        payload: {
          id: 'packageperformancetable',
          sortField: pkgPerformanceOverview.SortField,
          sortDirection: pkgPerformanceOverview.SortOrder
        }
      });
      dispatch(requestStop());
    } catch (e) {
      dispatch(getListPkgPerformanceOverviewFailedAction());
      dispatch(requestStop());
    }
  };
};

const getPkgPerformanceDetailSuccessAction = (
  pkgPerformanceDetail: PackagePerformanceDetailType[]
): PackagePerformanceDetailActionTypes => {
  return {
    type: PKG_PERFORMANCE_DETAIL_SUCCESS_ACTION,
    payload: pkgPerformanceDetail
  };
};

const getPkgPerformanceDetailFailedAction = (): PackagePerformanceDetailActionTypes => {
  return {
    type: PKG_PERFORMANCE_DETAIL_FAILED_ACTION
  };
};

export const getPkgPerformanceDetail = (
  pkgPerformanceDetail: PackageDetailParameterActionTypes
) => {
  return async (dispatch) => {
    try {
      dispatch(requestStart());

      const getPackageDetailResp = await getPkgPerformanceDetailAPI({
        Limit: pkgPerformanceDetail.Limit,
        Offset: pkgPerformanceDetail.Offset,
        SortField: pkgPerformanceDetail.SortField,
        SortOrder: pkgPerformanceDetail.SortOrder,
        PackageId: pkgPerformanceDetail.PackageId
      });

      dispatch(getPkgPerformanceDetailSuccessAction(getPackageDetailResp));
      dispatch({
        type: '@@enhanceddataview/DATAVIEW_CONFIG_INITIALIZE',
        payload: {
          id: 'campaignperformancetable',
          sortField: pkgPerformanceDetail.SortField,
          sortDirection: pkgPerformanceDetail.SortOrder
        }
      });
      dispatch(requestStop());
    } catch (e) {
      dispatch(getPkgPerformanceDetailFailedAction());
      dispatch(requestStop());
    }
  };
};

export const cleanState = (): CleanStateAction => {
  return {
    type: CLEAN_STATE
  };
};

const getPkgDetailSuccessAction = (
  pkgDetail: PackageDetailType
): PackageDetailActionTypes => {
  return {
    type: PKG_DETAIL_SUCCESS_ACTION,
    payload: pkgDetail
  };
};

const getPkgDetailFailedAction = (): PackageDetailActionTypes => {
  return {
    type: PKG_DETAIL_FAILED_ACTION
  };
};

export const getPkgDetail = (packageId: string) => {
  return async (dispatch) => {
    try {
      dispatch(requestStart());

      const getPackageDetailResp = await getPkgDetailAPI(packageId);
      // update package performance detail for new package name
      dispatch(
        getPkgPerformanceDetail({
          Limit: 10000,
          Offset: 0,
          SortField: 'pacing_percent',
          SortOrder: 'Desc',
          PackageId: packageId
        })
      );
      // update charts for new package
      dispatch(getPackagePerformanceHistory(packageId));
      dispatch(getPkgDetailSuccessAction(getPackageDetailResp));
      dispatch(getPkgUnassignedCampaigns(getPackageDetailResp.advertiser_id));
      dispatch(requestStop());
    } catch (e) {
      dispatch(getPkgDetailFailedAction());
      dispatch(requestStop());
    }
  };
};

const updatePackageSuccessAction = (
  pkgDetail: PackageDetailType
): PackageUpdateActionTypes => {
  return {
    type: PKG_UPDATE_SUCCESS_ACTION,
    payload: pkgDetail
  };
};

const updatePackageFailedAction = (): PackageUpdateActionTypes => {
  return {
    type: PKG_UPDATE_FAILED_ACTION
  };
};

const deletePackageFailedAction = (): PackageDeleteActionTypes => {
  return {
    type: PKG_DELETE_FAILED_ACTION,
    payload: { errMsg: "Failed to delete package" }

  };
};

const deletePackageSuccessAction = (): PackageDeleteActionTypes => {
  return {
    type: PKG_DELETE_SUCCESS_ACTION
  };
};


export const updatePackage = (data: packageInterface[]) => {
  return async (dispatch) => {
    try {
      dispatch(requestStart());
      const updatePackageResp = await updatePackageAPI(data);
      dispatch(updatePackageSuccessAction(updatePackageResp));
      dispatch(getPkgDetail(data[0].PackageId.toString()));
      dispatch(requestStop());
    } catch (e) {
      dispatch(updatePackageFailedAction());
      dispatch(requestStop());
    }
  };
};

export const deletePackage = (packageIds: number[]) => {
  return async (dispatch) => {
    try {
      dispatch(requestStart());
      await  deletePackageAPI(packageIds);
      dispatch(deletePackageSuccessAction());
      dispatch(requestStop());
    } catch (e) {
      dispatch(deletePackageFailedAction());
      dispatch(requestStop());
    }
  };
};




export const Package = (data: packageInterface[]) => {
  return async (dispatch) => {
    try {
      dispatch(requestStart());

      const updatePackageResp = await updatePackageAPI(data);
      dispatch(updatePackageSuccessAction(updatePackageResp));
      dispatch(getPkgDetail(data[0].PackageId.toString()));
      dispatch(requestStop());
    } catch (e) {
      dispatch(updatePackageFailedAction());
      dispatch(requestStop());
    }
  };
};

const loadPkgUnassignedCampaignsSuccess = (
  payload
): PkgUnassignedCampaignsActionTypes => {
  return {
    type: PKG_UNASSIGNED_CAMPAIGN_SUCCESS,
    payload
  };
};

const loadPkgUnassignedCampaignsFailed = (): PkgUnassignedCampaignsActionTypes => {
  return {
    type: PKG_UNASSIGNED_CAMPAIGN_FAILED
  };
};

export const getPkgUnassignedCampaigns = (advertiser_id: string) => {
  return async (dispatch) => {
    try {
      dispatch(requestStart());

      const data = await loadPkgUnassignedCampaign(advertiser_id);
      dispatch(loadPkgUnassignedCampaignsSuccess(data));

      dispatch(requestStop());
    } catch (e) {
      dispatch(loadPkgUnassignedCampaignsFailed());
      dispatch(requestStop());
    }
  };
};
