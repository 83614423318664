import React, { memo, useState } from 'react';
import _ from 'lodash';

import { getPackageSearch } from '../../../services/searchService';
import TextControl from '@ttd/maui/lib/components/controls/TextControl';
import './SearchPackages.scss';

function SearchList(props) {
  const [list, setList] = useState<any[]>([]);

  const searchOnChange = async (e) => {
    const val = e.target.value;
    if (val !== '') {
      props.onPackageSelected(val);
      const searchResult = await getPackageSearch(val);
      setList(searchResult);
    } else {
      props.onPackageSelected('');
      setList([]);
    }
  };

  const updateViewedPackage = (name: string, package_id: number) => {
    props.onPackageSelected(name, package_id);
    setList([]);
  };

  return (
    <>
      <TextControl
        className="search"
        value={props.term}
        onInput={searchOnChange}
        placeholder="Please enter Package Name"
      />
      <input
        id="package_id"
        name="package_id"
        type="hidden"
        value={props.packageId}
      />

      {!_.isEmpty(list) && (
        <div className="search-packages-result">
          <ul>
            {list.map((item, key) => {
              return (
                <li
                  onClick={() =>
                    updateViewedPackage(item.package_name, item.package_id)
                  }
                  key={key}
                >
                  {item.package_name}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}

export default memo(SearchList);
