import axios from 'axios';

import {
  PackageParameterActionTypes,
  PackageDetailParameterActionTypes,
  packageInterface,
  pkgExportPackage
} from '../actions/actionTypes';
import { getCookies } from '../utils';

import { baseUrl } from './../vars';
const ttdUrl = baseUrl + '/v3';

export const loadPackagePerformanceHistory = (id: string) => {
  const requestUrl = `${baseUrl}/pkg/packagePerformanceHistory?PackageId=${id}`;
  return axios
    .get(requestUrl, {
      headers: { PkgAuth: getCookies('packageToken') }
    })
    .then((packages) => {
      if (packages.status === 200 && packages.data.error === undefined) {
        return packages.data;
      }

      return [];
    })
    .catch((error) => {
      console.log(error.message);
      return [];
    });
};

export const getListPkgPerformanceOverviewAPI = (
  data: PackageParameterActionTypes
) => {
  return axios
    .get(
      `${baseUrl}/pkg/packagePerformanceOverview?Limit=${data.Limit}&Offset=${data.Offset}&SortField=${data.SortField}&SortOrder=${data.SortOrder}`,
      {
        headers: { PkgAuth: getCookies('packageToken') }
      }
    )
    .then((resPkg) => {
      if (resPkg.status === 200) {
        return resPkg.data;
      }
      return undefined;
    })
    .catch((error) => {
      console.log(error.message);

      return error.message;
    });
};

export const getPkgPerformanceDetailAPI = (
  data: PackageDetailParameterActionTypes
) => {
  return axios
    .get(
      `${baseUrl}/pkg/packagePerformanceDetails?PackageId=${
        data.PackageId
      }&Limit=${data.Limit || ''}&Offset=${data.Offset || ''}&SortOrder=${
        data.SortOrder || 'Desc'
      }&SortField=${data.SortField || 'pacing_percent'}`,
      {
        headers: { PkgAuth: getCookies('packageToken') }
      }
    )
    .then((resPkg) => {
      if (resPkg.status === 200) {
        return resPkg.data.packages;
      }
      return undefined;
    })
    .catch((error) => {
      console.log(error.message);

      return error.message;
    });
};

export const getPkgDetailAPI = (packageId: string) => {
  return axios
    .get(`${baseUrl}/pkg/packageDetails?PackageId=${packageId}`, {
      headers: { PkgAuth: getCookies('packageToken') }
    })
    .then((resPkgDetail) => {
      if (resPkgDetail.status === 200) {
        return resPkgDetail.data;
      }
      return undefined;
    })
    .catch((error) => {
      console.log(error.message);

      return error.message;
    });
};

export const updatePackageAPI = (data: packageInterface[]) => {
  return axios
    .put(`${baseUrl}/pkg/packages`, data, {
      headers: { PkgAuth: getCookies('packageToken') }
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      return undefined;
    })
    .catch((error) => {
      console.log(error.message);

      return error.message;
    });
};

export const deletePackageAPI = (package_ids: number[]) => {
  return axios
    .delete(`${baseUrl}/pkg/packages`, {
      headers: { PkgAuth: getCookies('packageToken') },
      data: { PackageIds: package_ids }
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      return undefined;
    })
    .catch((err) => {
      console.log(err);
      console.log(err.message);
      throw err.message;
      // return err.message;
    });
};
export const loadPkgUnassignedCampaign = (advertiser_id: string) => {
  return axios
    .get(
      `${baseUrl}/pkg/packageUnassignedCampaigns?AdvertiserId=${advertiser_id}&Limit=10000&Offset=0`,
      {
        headers: { PkgAuth: getCookies('packageToken') }
      }
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }

      return undefined;
    })
    .catch((err) => {
      console.log(err.message);

      return err.message;
    });
};

const fetchRetry = (err, retryFunction, data, retries = 10) => {
  if (
    err.response &&
    err.response.status === 429 &&
    err.response.headers['retry-after'] &&
    retries > 0
  ) {
    retries -= 1;
    setTimeout(() => {
      return retryFunction(data);
    });
  } else {
    return false;
  }
};

export const checkDataExisted = (url, header) => {
  return axios
    .get(url, {
      headers: header
    })
    .then((res) => {
      return res.status === 200;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const loadAdvertiserCampaigns = (advId) => {
  const postData = {
    AdvertiserId: advId,
    PageStartIndex: 0,
    PageSize: 10000,
    SortFields: []
  };

  return axios
    .post(`${ttdUrl}/campaign/query/advertiser`, postData, {
      headers: { 'TTD-Auth': getCookies('ttdToken') }
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err.message);
      return fetchRetry(err, loadAdvertiserCampaigns, advId);
    });
};

export const createNewCampaignTTD = (campaignData) => {
  return axios
    .post(`${ttdUrl}/campaign`, campaignData, {
      headers: { 'TTD-Auth': getCookies('ttdToken') }
    })
    .then((res) => res)
    .catch((err) => {
      console.log(err.message);
      return fetchRetry(err, createNewCampaignTTD, campaignData);
    });
};

export const updateCampaignTTD = (campaignData) => {
  return axios
    .put(`${ttdUrl}/campaign`, campaignData, {
      headers: { 'TTD-Auth': getCookies('ttdToken') }
    })
    .then((res) => res)
    .catch((err) => {
      console.log(err.message);
      return fetchRetry(err, updateCampaignTTD, campaignData);
    });
};

export const createNewCampaignFlightTTD = (campaignData) => {
  return axios
    .post(`${ttdUrl}/campaignflight`, campaignData, {
      headers: { 'TTD-Auth': getCookies('ttdToken') }
    })
    .then((res) => res)
    .catch((err) => {
      console.log(err.message);
      return fetchRetry(err, createNewCampaignFlightTTD, campaignData);
    });
};

export const updateCampaignFlightTTD = (campaignData) => {
  return axios
    .put(`${ttdUrl}/campaignflight`, campaignData, {
      headers: { 'TTD-Auth': getCookies('ttdToken') }
    })
    .then((res) => res)
    .catch((err) => {
      console.log(err.message);
      return fetchRetry(err, updateCampaignFlightTTD, campaignData);
    });
};

export const createNewCampaigns = (campaignData) => {
  return axios
    .post(`${baseUrl}/pkg/campaigns`, campaignData, {
      headers: { PkgAuth: getCookies('packageToken') }
    })
    .then((res) => res)
    .catch((err) => {
      console.log(err.message);
      return err.response;
    });
};

export const updateCampaigns = (campaignData) => {
  return axios
    .put(`${baseUrl}/pkg/campaigns`, campaignData, {
      headers: { PkgAuth: getCookies('packageToken') }
    })
    .then((res) => res)
    .catch((err) => {
      console.log(err.message);
      return err.response;
    });
};

export const createNewPackages = (packagesData) => {
  return axios
    .post(`${baseUrl}/pkg/packages`, packagesData, {
      headers: { PkgAuth: getCookies('packageToken') }
    })
    .then((res) => res)
    .catch((err) => {
      console.log(err.message);
      return err.response;
    });
};

export const updatePackages = (packagesData) => {
  return axios
    .put(`${baseUrl}/pkg/packages`, packagesData, {
      headers: { PkgAuth: getCookies('packageToken') }
    })
    .then((res) => res)
    .catch((err) => {
      console.log(err.message);
      return err.response;
    });
};

export const getPackagePerformanceExport = (
  advId: string
): Promise<pkgExportPackage[]> => {
  var requestUrl = `${baseUrl}/pkg/exportPerf`;
  if (advId) {
    requestUrl += '/?AdvertiserIds=' + advId;
  }
  return axios
    .get(requestUrl, {
      headers: {
        PkgAuth: getCookies('packageToken'),
        'Content-Type': 'application/json'
      }
    })
    .then((packages) => {
      if (packages.status === 200 && packages.data.error === undefined) {
        if (!Array.isArray(packages.data)) {
          return [];
        }
        return packages.data as pkgExportPackage[];
      }

      return [];
    })
    .catch((error) => {
      console.log(error.message);
      return [];
    });
};

export const exportPackages = (
  advIds: Array<any>
): Promise<pkgExportPackage[]> => {
  var requestUrl = `${baseUrl}/pkg/export`;
  if (advIds && advIds.length > 0) {
    var sAdvIds = advIds.join(',');
    requestUrl += '/?AdvertiserIds=' + sAdvIds;
  }
  // console.log(requestUrl);
  return axios
    .get(requestUrl, {
      headers: {
        PkgAuth: getCookies('packageToken'),
        'Content-Type': 'application/json'
      }
    })
    .then((packages) => {
      if (packages.status === 200 && packages.data.error === undefined) {
        if (!Array.isArray(packages.data)) {
          return [];
        }
        return packages.data as pkgExportPackage[];
      }

      return [];
    })
    .catch((error) => {
      console.log(error.message);
      return [];
    });
};

export const getPkgAdvertiserConfig = (): Promise<Array<any>> => {
  var requestUrl = `${baseUrl}/pkg/advConfig`;
  // console.log(requestUrl);
  return axios
    .get(requestUrl, {
      headers: {
        PkgAuth: getCookies('packageToken'),
        'Content-Type': 'application/json'
      }
    })
    .then((packages) => {
      if (packages.status === 200 && packages.data.error === undefined) {
        if (!Array.isArray(packages.data)) {
          return [];
        }
        return packages.data as Array<any>;
      }

      return [];
    })
    .catch((error) => {
      console.log(error.message);
      return [];
    });
};
