import { Observable } from 'rxjs';
import { IRunBaseId } from './LoadData';
import { readQuery$ } from './readQuery';
import { IResultsData } from './requestTtdApi';

interface IRunBaseRead extends IRunBaseId {
  BaseIdName?: string;
  BaseId: string;
}

/**
 * read partners + filter relevant properties
 * @param props
 */
export const readPartner$ = (props: IRunBaseRead):Observable<IResultsData> => {
  var BaseIdName = '';
  return readQuery$({
    ...props,
    BaseIdName,
    // In value to use
    // Id name e.g. AdvertiserId
    method: 'POST',
    shortUrl: '/partner/query',
    responseFilterFieldList: ['PartnerName', 'PartnerId'],
    errorId: BaseIdName
  });
};
