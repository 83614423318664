import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { RootState } from 'typesafe-actions';
import { isEmpty } from 'lodash';
import Button from '@ttd/maui/lib/components/core/Button';
import ToggleControl from '@ttd/maui/lib/components/controls/ToggleControl';
import LabelledInput from '@ttd/maui/lib/components/display/LabelledInput';
import { SORT_DIRECTION } from '@ttd/maui/lib/components/display/DataTable/DataTable';
import CampaignPerformanceTable from './CampaignPerformanceTable';
import { getPackagePerformanceHistory } from '../../actions';
import { getPkgPerformanceDetail } from '../../actions/packageActions';
import PackagePacing from './PackagePacing';
import PackageKPI from './PackageKPI';
import './PackagePerformanceDetail.scss';
import { requestStart, requestStop } from '../../actions/requestActions';
import {
  getPackagePerformanceExport,
  getPkgPerformanceDetailAPI
} from '../../services/packageService';
import { exportPackagePerformanceExcel } from '../../utils/packageUtils';
import _ from 'lodash';
import { PACKAGE_SETTING_PATH } from '../../constants/route';

import { useQuery, useQueryClient, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import {
  createUrlParams,
  filterCampaigns,
  tPackagePerformanceDetails,
  tPerfCampData
} from '../../utils/performanceDetailUtils';
import { useImmer } from 'use-immer';
import {
  forceCampaignTableSort,
  forceCampaignTableSortField
} from '../../reducers/packageReducer';
import { getSortFunc, sortJsonArr, titleCase } from '../../utils';
import { CHART_TYPES, TimeFrameType } from './PackageKPI/types';

const usePerfDetails = () => {
  let urlParams = new URLSearchParams();
  // eslint-disable-next-line no-restricted-globals
  if (location.search) {
    // eslint-disable-next-line no-restricted-globals
    urlParams = new URLSearchParams(location.search);
  }

  let pid = urlParams.get('PackageId');
  // let so = urlParams.get('SortOrder');
  // let sf = urlParams.get('SortField');
  // if (!so) {
  //   so = 'Desc';
  // }
  // if (!sf) {
  //   sf = 'pacing_percent';
  // }
  var pkgPerformanceDetail = {
    // // Limit: 10000,
    // // Offset: 0,
    // SortField: sf,
    // SortOrder: so,
    PackageId: pid
  };
  return useQuery({
    queryKey: ['pkgPerformanceDetail', pkgPerformanceDetail],
    queryFn: async () => {
      var data;
      if (!pkgPerformanceDetail.PackageId) {
        data = [{}] as tPackagePerformanceDetails[];
      } else {
        data = await getPkgPerformanceDetailAPI({
          // Limit: pkgPerformanceDetail.Limit,
          // Offset: pkgPerformanceDetail.Offset,
          // SortField: pkgPerformanceDetail.SortField,
          // SortOrder: pkgPerformanceDetail.SortOrder,
          PackageId: pkgPerformanceDetail.PackageId
        });
      }
      return data as tPackagePerformanceDetails[];
    },
    cacheTime: 30 * 60 * 1000,
    staleTime: 30 * 60 * 1000
  });
};

// const MyPerfDetail = ({ setPerfDetail }) => {
//   // const queryClient = useQueryClient();
//   const { status, data, error, isFetching, fetchStatus } = usePerfDetails();
//   console.log('MyPerfDetail - fetchStatus' + fetchStatus);
//   if (!isFetching && !(status === 'loading')) {
//     console.log('MyPerfDetail - setting data ' + JSON.stringify(data));
//     console.log('MyPerfDetail - fetchStatus setdata');
//     setPerfDetail(data);
//   }

//   return (
//     <div>
//       <h4>PKG Details</h4>
//       {/* <div>
//         {status === 'loading' ? (
//           'Loading...'
//         ) : status === 'error' && error instanceof Error ? (
//           <span>Error: {error.message}</span>
//         ) : (
//           <>
//             <div>
//               {data &&
//                 data.map((pkgDet) => (
//                   <p key={pkgDet.package_id}>
//                     {JSON.stringify(pkgDet, null, '\t\n')}
//                   </p>
//                 ))}
//             </div>
//             <div>{isFetching ? 'Background Updating...' : ' '}</div>
//           </>
//         )}
//       </div> */}
//     </div>
//   );
// };

const PackagePerformanceDetail = () => {
  // const [sortOrder, setSortOrder] = useState('');
  // const [sortField, setSortField] = useState('');
  const [ltPos, setLtPos] = useState('0');
  const [oldPackageId, setOldPackageId] = useState('');
  const [curPkg, setcurPkg] = useState({
    id: undefined,
    name: ''
  });

  const packagePerformanceDetail = useSelector(
    (state: RootState) => state.package.packagePerformanceDetail
  );
  const pkg = useSelector(
    (state: RootState) => state.package.performanceHistory
  );
  const homePageUrl = useSelector(
    (state: RootState) => state.package.homePageUrl
  );
  const isLoading = useSelector((state: RootState) => state.request.isLoading);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [config, setConfig] = useImmer({
    period: '',
    history: false,
    sortField: 'pacing_percent',
    sortOrder: SORT_DIRECTION.DESCENDING,
    campaigns: [] as tPerfCampData[],
    top: 'spend',
    bottom: 'impressions'
  });
  // const queryClient = useQueryClient();
  // const [perfDetail, setPerfDetail] = React.useState({});

  const changeCampData = useCallback(
    (history, period, setData = false) => {
      // console.log(
      //   'changeCampData ' + history + ' per ' + period + ' setDt ' + setData
      // );
      setConfig((draft) => {
        // console.log(
        //   'changeCampData setConfig - draft ' + JSON.stringify(draft)
        // );
        let changed = false;
        if (history !== undefined && draft.history !== history) {
          draft.history = history;
          // console.log('history changed:' + history);
          changed = true;
        }
        if (period !== undefined && draft.period !== period) {
          draft.period = period;
          // console.log('period changed:' + period);
          changed = true;
        }
        if (changed || setData) {
          // console.log(' campaigns update + ' + draft.history + draft.period);
          draft.campaigns = filterCampaigns(
            packagePerformanceDetail,
            draft.history,
            draft.period
          );
          if (!setData) {
            // console.log('sort order update');
            forceCampaignTableSort(dispatch, draft.sortOrder);
          }
          //  else {
          //   console.log('no sort order update');
          // }
        }
      });
    },
    [
      packagePerformanceDetail,
      config.history,
      config.period,
      config.sortOrder,
      config.campaigns
    ]
  );

  const updatePeriod = useCallback(
    (timeFrame: TimeFrameType) => {
      changeCampData(undefined, timeFrame.id);
      setUrlParams({ period: timeFrame.id });
    },
    [packagePerformanceDetail, config]
  );

  const toggleHistory = useCallback(() => {
    // console.log('toggleHistory: - old ' + config.history);
    let histToggled = !config.history;
    changeCampData(histToggled, undefined);
    // console.log('toggleHistory: - setting history URL Params ' + histToggled);
    setUrlParams({ history: histToggled ? 'y' : 'n' });
  }, [config]);

  const updateCharts = useCallback(
    (top, bottom) => {
      const sortSwitched = (draft, val, prop) => {
        let sortComp = CHART_TYPES[draft[prop]].apiResponseKey;
        // console.log('sortField - ' + draft.sortField);
        // console.log('sortComp - ' + sortComp);
        if (draft.sortField === sortComp) {
          // console.log(`${prop} was sortfield and changed to ${val}`);
          return val;
        }
        return '';
      };

      setConfig((draft) => {
        // console.log('updateCharts - draft ' + JSON.stringify(draft));
        let switchField = '',
          params = {};
        if (top && draft.top !== top) {
          switchField = sortSwitched(draft, top, 'top');
          draft.top = top;
          params = { top };
          // console.log('top changed:' + top);
        }
        if (bottom && draft.bottom !== bottom) {
          switchField = sortSwitched(draft, bottom, 'bottom');
          draft.bottom = bottom;
          // console.log('bottom changed:' + bottom);
          params = { bottom };
        }
        if (switchField) {
          let { apiResponseKey } = CHART_TYPES[switchField];
          // console.log('apiResponseKey - ' + apiResponseKey);
          draft.sortField = apiResponseKey;
          params = { ...params, sortField: apiResponseKey };
          // console.log(
          //   'sO :' + draft.sortOrder + ' - ' + draft.sortOrder === 'Desc'
          // );
          draft.campaigns = sortJsonArr(
            draft.campaigns,
            getSortFunc(apiResponseKey),
            apiResponseKey,
            draft.sortOrder === 'Desc'
          );
          // somehow the campaign table doesn't notice the sort change otherwise
          forceCampaignTableSortField(dispatch, apiResponseKey);
        }
        setUrlParams(params);
      });
    },
    [config, dispatch]
  );

  type updateSortArgs = { sortField: string; sortOrder: string };
  const updateSort = useCallback(
    (args) => {
      let fields = ['sortField', 'sortOrder'];
      setConfig((draft) => {
        // console.log('updateSort - draft ' + JSON.stringify(draft));
        fields.forEach((field, idx) => {
          if (args[field] && draft[field] !== args[field]) {
            // console.log('field ' + field + ' updated ' + args[field]);
            draft[field] = args[field];
          }
        });
      });
      setUrlParams(args);
    },
    [config]
  );

  const setUrlParams = useCallback(
    (params) => {
      // console.log('PerfDetails setUrlParams search' + JSON.stringify(params));
      const urlParams = new URLSearchParams(createUrlParams(location.search));
      // console.log(
      //   'PerfDetails setUrlParams urlParams base - ' + urlParams.toString()
      // );
      Object.keys(params).forEach((key) => {
        // console.log(`setting ${key} = ${params[key]}`);
        urlParams.set(key, params[key]);
      });
      // console.log('PerfDetails setUrlParams ' + urlParams.toString());
      history.replace({ search: urlParams.toString() });
    },
    [location.search]
  );
  // position out LT column label dynamically based on column position
  const getLtLeft = useCallback(() => {
    function offset(el) {
      var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }
    try {
      var colDiv = document.querySelector(
        'div.datatable__header > div > div:nth-child(6)'
      );
      if (colDiv) {
        var divOffset = offset(colDiv);
        // console.log('getLtLeft: ' + divOffset.left);
        return divOffset.left + 'px';
      }
    } catch (e) {
      //
    }
    // console.log('getLtLeft: 0');
    return '0';
  }, []);

  const histTitle = useCallback(() => {
    return 'Show historical Performance for ' + titleCase(config.period);
  }, [config.period]);

  useLayoutEffect(() => {
    // console.log('useLayoutEffect');
    let urlParams = new URLSearchParams();
    if (location.search) {
      urlParams = new URLSearchParams(createUrlParams(location.search));
      // console.log(
      //   'perfDetails useLayoutEffect - urlParams' +
      //     JSON.stringify(urlParams.toString())
      // );
    }

    const shouldUpdate = (field: any, compare: any) => {
      return field !== compare;
    };
    let pid = urlParams.get('PackageId'),
      updatePkgId = false,
      oldPid,
      // url parameters
      fields = ['period', 'history', 'top', 'bottom', 'sortField', 'sortOrder'],
      // parameter defaults
      defaults = [
        '30-days',
        'n',
        'spend',
        'impressions',
        'pacing_percent',
        SORT_DIRECTION.DESCENDING
      ];

    // if (location.href === 'http://localhost/') {
    //   pid = '681';
    // }
    if (pid === null) {
      return;
    }
    // console.log('old pid: ' + oldPackageId);
    updatePkgId = shouldUpdate(pid, oldPackageId);

    let dt = {};
    fields.forEach((f, idx) => {
      // get URL parameter or assign default value
      dt[f] = urlParams.get(f) || defaults[idx];
      // for history translate into boolean
      if (f === 'history') {
        dt[f] = dt[f].toLowerCase() === 'y';
      }
    });
    setConfig((draft) => {
      fields.forEach((f, idx) => {
        if (shouldUpdate(draft[f], dt[f])) {
          // console.log('URL based update ' + f + ' ' + dt[f]);
          draft[f] = dt[f];
        }
      });
    });
    // update once
    if (!oldPid || updatePkgId) {
      setLtPos(getLtLeft());
    }

    if (isEmpty(packagePerformanceDetail) || updatePkgId) {
      // console.log('new dispatch: packagePerformanceDetail pid' + pid);
      setOldPackageId(pid);
      dispatch(
        getPkgPerformanceDetail({
          // Limit: 10000,
          // Offset: 0,
          SortField: config.sortField,
          SortOrder: config.sortOrder,
          PackageId: pid
        })
      );
      dispatch(getPackagePerformanceHistory(pid));
    }
    // console.log('pkgDet: ' + JSON.stringify(packagePerformanceDetail));
  }, [dispatch, location.search, packagePerformanceDetail, config]);

  // useEffect(() => {
  //   if (packagePerformanceDetail && !render) {
  //     setRender(true);
  //   }
  // }, [render, packagePerformanceDetail]);

  useEffect(() => {
    // console.log('perfDetail change - calling changeCampData');
    changeCampData(undefined, undefined, true);
    const packageId =
      packagePerformanceDetail && packagePerformanceDetail[0]
        ? packagePerformanceDetail[0].package_id ?? 0
        : 0;
    const packageName =
      packagePerformanceDetail && packagePerformanceDetail[0]
        ? packagePerformanceDetail[0].package_name ?? ''
        : '';
    // console.log(`packageId ${packageId}, packageName ${packageName}`);
    setcurPkg({ id: packageId, name: packageName });
  }, [packagePerformanceDetail]);

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      setLtPos(getLtLeft());
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);
    setLtPos(getLtLeft());
    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  const exportFile = async () => {
    dispatch(requestStart());
    const currentPackage = packagePerformanceDetail[0];
    const advertiserId = currentPackage.campaigns[0]
      ? packagePerformanceDetail[0].campaigns[0].advertiser_id
      : '';
    // const currentPackage = perfDetail[0];
    // const advertiserId = currentPackage.campaigns[0]
    //   ? perfDetail[0].campaigns[0].advertiser_id
    //   : '';
    let packageData = await getPackagePerformanceExport(advertiserId);
    if (packageData.length > 0) {
      packageData = _.filter(packageData, ['pi', currentPackage.package_id]);

      await exportPackagePerformanceExcel(
        packageData,
        `_${currentPackage.package_name}`
      );
    }

    dispatch(requestStop());
  };

  // const packageId =
  //   packagePerformanceDetail && packagePerformanceDetail[0]
  //     ? packagePerformanceDetail[0].package_id ?? 0
  //     : 0;
  // const packageName =
  //   packagePerformanceDetail && packagePerformanceDetail[0]
  //     ? packagePerformanceDetail[0].package_name ?? ''
  //     : '';
  // console.log(`packageId ${packageId}, packageName ${packageName}`);

  return (
    <div className="wrapper">
      <div className="header-container">
        <h1 className="performanceHeader">
          {curPkg.name}
          {/* {perfDetail && perfDetail[0]
            ? perfDetail[0].package_name
            : ''} */}
        </h1>
        <div data-testid="group-btn" id="group-btn" className="group-btn">
          <Link to={`${PACKAGE_SETTING_PATH}/?PackageId=${curPkg.id}`}>
            <Button
              id="myBtn"
              data-testid="btn-package-setting"
              displayType="primary"
            >
              Packages Settings
            </Button>
          </Link>
          <Link to={homePageUrl}>
            <Button data-testid="btn-done">Done</Button>
          </Link>
        </div>
      </div>
      <div className="package-performance-detail-page">
        <div className="top">
          <div className="left-content">
            <PackagePacing pkg={pkg} />
          </div>
          <div className="right-content">
            <div className="chart-info">
              <PackageKPI
                pkg={pkg}
                top={config.top}
                bottom={config.bottom}
                period={config.period}
                updatePeriod={updatePeriod}
                updateCharts={updateCharts}
              />
            </div>
          </div>
        </div>
        <div className="export-btn">
          <Button
            data-testid="btn-bulk-export"
            className="button-export"
            type="button"
            displayType="primary"
            onClick={exportFile}
          >
            Export Package Campaign Data
          </Button>
        </div>
        <div className="history-toggle-container">
          <ToggleControl
            id="toggle-control-story-basic"
            onChange={toggleHistory}
            isReadOnly={false}
            isEnabled={true}
            isLoading={isLoading}
            theme={'stoplight'}
            value={config.history}
          />
          {/* {!isLoading && ( */}
          <div className="hist-title" onClick={toggleHistory}>
            <LabelledInput
              label={histTitle()}
              size={'xl'}
              isInline={true}
              theme={'emphasize-label'}
            ></LabelledInput>
          </div>
        </div>
        <div
          className="lt-label-container"
          style={{
            // left will change dynamically
            left: ltPos
          }}
        >
          <LabelledInput
            label="LT=Live Time"
            size={'sm'}
            isInline={true}
            theme={'disabled'}
          ></LabelledInput>
        </div>
        <div className="camp-table">
          {/* {!isLoading && ( */}
          <CampaignPerformanceTable
            // packageId={curPkg.id}
            isLoading={isLoading}
            updateSort={updateSort}
            {...config}
            // campaigns={campDetail}
            // sortOrder={sortOrder}
            // sortField={sortField}
            // top={charts.top}
            // bottom={charts.bottom}
          />
          {/* <CampaignPerformanceTable
            packageId={
              perfDetail[0] === undefined
                ? ''
                : perfDetail[0].package_id
            }
            campaigns={
              perfDetail[0] === undefined
                ? []
                : perfDetail[0].campaigns
            }
            sortOrder={sortOrder}
            sortField={sortField}
            isLoading={isLoading}
          /> */}
          {/* )} */}
        </div>
        {/* <div className="bottom">
          <div className="botton-content">
            {/* <MyPerfDetail setPerfDetail={setPerfDetail}></MyPerfDetail> */}
        {/* <ReactQueryDevtools initialIsOpen />
          </div>
        </div>  */}
      </div>
    </div>
  );
};

export default PackagePerformanceDetail;
