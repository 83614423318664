import React from 'react';
import DataTable, {
  COMPARATOR,
  HORIZONTAL_ALIGNMENT
} from '@ttd/maui/lib/components/display/DataTable/DataTable';
import ColumnHeader from '../../../components/ColumnHeader';
const horizontal = HORIZONTAL_ALIGNMENT.RIGHT;

type genColumnProps = {
  display: (value: number) => string;
  handleUpdateParams: (params: string) => void;
  field: string;
  order: number;
  minWidth: number;
  replaceName?: string;
};
export const DataColumn = (props: genColumnProps) => {
  var {
    handleUpdateParams,
    display,
    field,
    minWidth,
    replaceName,
    order
  } = props;
  var hdObj = { handleUpdateParams, field, replaceName };
  return {
    id: field,
    'data-testid':'tbl-col-'+field,
    header: <ColumnHeader {...hdObj}></ColumnHeader>,
    layout: { order },
    width: { minWidthPixels: minWidth, flexGrow: 1 },
    isSortable: true,
    sortComparator: DataTable.COMPARATOR.NUMERIC(field),
    renderContent: (row) => display(row[field]),
    alignment: {
      horizontal
    }
  };
};
