import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { loginAction } from '../../actions';
import { getStaticFile } from '../../utils';

//import TTD Components
import TextControl from '@ttd/maui/lib/components/controls/TextControl';
import Button from '@ttd/maui/lib/components/core/Button';
// import Dots from '@ttd/maui/lib/components/display/Loaders/Dots';
import './Login.scss';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const isLoading = useSelector((state: RootState) => state.request.isLoading);
  const errorMessage = useSelector(
    (state: RootState) => state.user.errorMessage
  );
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(loginAction({ user: username, password: password }));
  };

  const btnDisabled = isEmpty(username) || isEmpty(password);

  return (
    <div className="login-page wrapper">
      {/* {isLoading ? <Dots className="loading-dots" /> : ''} */}
      <h1>Package UI</h1>
      <div className="login-form">
        <div className="logo">
          <img
            src={getStaticFile('images/logos/ttd-logo-medium.png')}
            alt="Logo TTD"
          />
        </div>
        <div className="my-25">
          <span>Login: </span>
          <TextControl
            className="username"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            placeholder="Username"
          />
        </div>

        <div className="my-25">
          <span>Password</span>
          <TextControl
            className="password"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            placeholder="Password"
          />
        </div>

        <Button
          type="button"
          ref={() => {}}
          id="id-btn-login"
          displayType="primary"
          onClick={onSubmit}
          disabled={btnDisabled || isLoading}
        >
          Login
        </Button>
        {errorMessage && <div className="error">{errorMessage}</div>}
      </div>
    </div>
  );
}

export default Login;
