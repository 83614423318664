import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { RootState } from 'typesafe-actions';
import { withCookies } from 'react-cookie';
import OidcManager from './oidc';

import {
  insertLocalData,
  getLocalData,
  getCurrentTimestamp,
  removeLocalData
} from '../../utils';
import { setUserCredentialAction } from '../../actions';
import { requestStart } from '../../actions/requestActions';
import { logoutAction } from '../../actions/userActions';
import { USER_SESSION_TIME } from '../../vars';

function AuthProvider(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loggedIn } = useSelector((state: RootState) => state.user);

  const redirectOidcLogin = useCallback(() => {
    insertLocalData(
      'redirectPath',
      `${window.location.pathname}${window.location.search}`
    );
    OidcManager.signinRedirect();
  }, []);

  const onLoadOidcUserSuccess = useCallback(
    (oidcUser) => {
      const tokenExpTime = getLocalData('tokenExpTime', 'local');
      const currentTimestamp = getCurrentTimestamp();
      if (currentTimestamp - Number(tokenExpTime) > USER_SESSION_TIME) {
        //kf-debug
        console.log('seesion timeout reached - dispatching logout: ');
        dispatch(logoutAction());
        return;
      }

      dispatch(setUserCredentialAction(oidcUser));
    },
    [dispatch]
  );

  useEffect(() => {
    const verifyUser = async () => {
      try {
        dispatch(requestStart());
        const oidcUser = await OidcManager.getUser();
        // const urlParams1 = new URLSearchParams(window.location.search);
        // const callbackCode1 = urlParams1.get('code');
        if (oidcUser) {
          await onLoadOidcUserSuccess(oidcUser);
        } else {
          const urlParams = new URLSearchParams(window.location.search);
          const callbackCode = urlParams.get('code');
          if (callbackCode) {
            try {
              insertLocalData(
                'tokenExpTime',
                getCurrentTimestamp().toString(),
                'local'
              );

              await OidcManager.signinRedirectCallback();

              // Redirect back
              const redirectUrl = getLocalData('redirectPath') || '/';
              removeLocalData('redirectPath');
              history.push(redirectUrl);
            } catch (error) {
              redirectOidcLogin();
            }
          } else {
            redirectOidcLogin();
          }
        }
      } catch (error) {
        console.log('error', error);
        redirectOidcLogin();
      }
    };

    verifyUser();
  }, [dispatch, history, onLoadOidcUserSuccess, redirectOidcLogin]);

  const onAccessTokenExpired = useCallback(() => {
    OidcManager.signinSilent().catch(() => {
      dispatch(logoutAction());
    });
  }, [dispatch]);

  const onSilentRenewError = useCallback(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  const onUserLoaded = useCallback(
    (user) => {
      onLoadOidcUserSuccess(user);
    },
    [onLoadOidcUserSuccess]
  );

  React.useEffect(() => {
    OidcManager.startSilentRenew();
    OidcManager.events.addUserLoaded(onUserLoaded);
    OidcManager.events.addAccessTokenExpired(onAccessTokenExpired);
    OidcManager.events.addSilentRenewError(onSilentRenewError);

    return () => {
      OidcManager.stopSilentRenew();
      OidcManager.events.removeUserLoaded(onUserLoaded);
      OidcManager.events.removeAccessTokenExpired(onAccessTokenExpired);
      OidcManager.events.removeSilentRenewError(onSilentRenewError);
    };
  }, [onAccessTokenExpired, onSilentRenewError, onUserLoaded]);

  return <>{loggedIn && props.children}</>;
}

export default withRouter(withCookies(AuthProvider));
