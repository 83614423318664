import {
  catchError,
  EMPTY,
  expand,
  firstValueFrom,
  mergeMap,
  Observable,
  of,
  reduce,
  scan,
  take,
  tap,
  throwError
} from 'rxjs';
import { IResultsData, requestTtdApi$ } from './requestTtdApi';

// Define IQueryGQL with generic types for both jsonVars and responseFormatter
export interface IQueryGQL<
  TPayload = { [key: string]: any }, // For jsonVars and payloadBuilder
  TResponseInput = { [key: string]: any }, // For the input of responseFormatter
  TResponseOutput = { [key: string]: any } // For the output of responseFormatter
> {
  // Auth token
  ttdAuthToken: string;

  // Function to generate payload, which is now generic
  payloadBuilder: (jsonVars: TPayload) => string;
  // jsonVars is also generic

  jsonVars: TPayload;

  // Optional function to format the JSON response (generic input and output)
  responseFormatter?: (input: TResponseInput) => TResponseOutput;

  // Function to update progress (generic signature)
  updateProgress?: (percent: number | Object) => void;
}

const transformResponseObject = (response: any) => {
  // in gql queries, the name of the object containing the response wil vary, depending on the object used in teh query - e.g. campaigns will return data in data.campaigns, while adgroups will return data in data.adgroups
  try {
    const dt = response.data;

    // Check if data is an empty array and errors are present
    if (
      Array.isArray(dt) &&
      dt.length === 0 &&
      ((response.userErrors && response.userErrors.length > 0) ||
        (response.errors && response.errors.length > 0) ||
        (response.message && response.message != ''))
    ) {
      // Trigger the catch block to handle error testing and formatting
      throw new Error('errors detected');
    }
    // Handle valid data structure
    const dtKey = Object.keys(dt)[0];
    const nextLevel = dt[dtKey];

    var data = [],
      pageInfo = { hasNextPage: false, endCursor: null },
      totalCount;

    // Handle nodes, edges, or fallback to return the whole object
    if (nextLevel && typeof nextLevel === 'object') {
      if (nextLevel.nodes) {
        data = nextLevel.nodes;
      } else if (nextLevel.edges) {
        data = nextLevel.edges;
      } else {
        // Fallback, e.g., for mutations where the entire object is returned
        data = nextLevel;
      }
      // Extract pageInfo and totalCount if available
      if (nextLevel.pageInfo) {
        pageInfo = nextLevel.pageInfo;
      }
      if (nextLevel.totalCount) {
        totalCount = nextLevel.totalCount;
      }
    }
    return {
      data,
      pageInfo,
      totalCount
    };
  } catch (error) {
    // Handle the potential error objects and throw them as errors
    if (response.userErrors) {
      const errorMessage = response.userErrors
        .map(
          (error: any) =>
            `Field: ${error.field.join(', ')} - Message: ${error.message}`
        )
        .join(' | ');
      throw new Error(`GraphQL user errors: ${errorMessage}`);
    }
    if (response.error) {
      throw new Error(JSON.stringify(response.error));
    }
    if (response.message) {
      throw new Error(JSON.stringify(response.message));
    }
    throw new Error(
      'unknown GraphQL error parsing response: ' + JSON.stringify(response)
    );
  }
};

interface IAccumulatedResults {
  data: any[];
  messages: string[];
  totalCount: number | undefined;
  currentCount: number; // to track progress
}
/**
 * read queryCampaignFlightsGQL
 * @param props
 */
export const queryGQL$ = (props: IQueryGQL): Observable<IResultsData> => {
  var {
    ttdAuthToken,
    payloadBuilder,
    jsonVars,
    responseFormatter,
    updateProgress
  } = props;
  var errorId = 'CampaignIdsGql';

  // Create an initial request payload, which can be formatted based on the "after" parameter (pagination)
  const createRequestPayload = (jsonVars: any, endCursor?: string) => {
    // responseFormatter using jsonVars and include the endCursor in jsonVars if it exists
    let pageJsonVars = endCursor ? { ...jsonVars, after: endCursor } : jsonVars;

    // Pass the formatted jsonVars to the payloadBuilder
    return payloadBuilder(pageJsonVars);
  };

  const calculateProgress = (
    current: number,
    total: number | undefined
  ): number => {
    if (!current || !total || total === 0) return 0;
    if (current > total) return 100; // max 100%
    return (current / total) * 100;
  };

  // Recursive request with pagination handling
  const fetchCampaigns$ = (endCursor?: string): Observable<IResultsData> => {
    const sJSON = createRequestPayload(jsonVars, endCursor);

    return requestTtdApi$({
      ttdAuthToken,
      method: 'POST',
      shortUrl: '/graphql',
      sJSON,
      responseFilterFieldList: [],
      disableResponseFilter: true,
      errorId,
      altErrorId: 'GraphQL_Error'
    }).pipe(
      tap((res) => console.log('queryCampaignFlightsGQL$ - response', res)),
      mergeMap((res) => {
        var { data, pageInfo, totalCount } = transformResponseObject(res.data);
        var retRes = { ...res, data, totalCount, pageInfo };
        return of(retRes);
      })
      // catchError((err) => {
      //   // Log error or handle it here
      //   console.error('Error in fetchCampaigns$', err);
      //   return throwError(() => new Error('Failed to fetch campaign data'));
      // })
    );
  };

  if (!ttdAuthToken) {
    return throwError(() => new Error('error - Missing TTD Auth Token'));
  }
  // Recursive function using `expand` to continue fetching while hasNextPage is true
  return fetchCampaigns$().pipe(
    // Use expand to continue querying if hasNextPage is true
    expand(
      (response): Observable<IResultsData> => {
        const pageInfo = response.pageInfo;
        const hasNextPage = pageInfo?.hasNextPage;
        const endCursor = pageInfo?.endCursor ?? undefined; // Convert `null` to `undefined`
        // Only continue if there's a next page
        return hasNextPage ? fetchCampaigns$(endCursor) : EMPTY;
      }
    ),
    // Accumulate all results and track progress with `scan` for progress reporting
    scan(
      (acc, response) => {
        let dt: any[] = [];
        let msgs: any[] = [];
        try {
          dt = response.data;
        } catch (error) {
          msgs.push(error);
        }

        if (typeof response.message === 'string' && response.message) {
          msgs.push(response.message);
        }

        // Accumulate the new data
        const newData = acc.data.concat(dt);
        const currentCount = newData.length;
        const totalCount = response.totalCount; // Use the totalCount from the response
        if (updateProgress && typeof updateProgress === 'function') {
          // Update progress based on the current count and total count
          const progress = calculateProgress(currentCount, totalCount);
          // Emit progress here via tap
          console.log(`Progress: ${progress.toFixed(2)}%`);
          updateProgress(progress.toFixed(2));
        }
        return {
          data: newData,
          messages: acc.messages.concat(msgs),
          totalCount, // totalCount comes directly from the response
          currentCount // Track running count of items
        };
      },
      {
        data: [],
        messages: [],
        totalCount: 0,
        currentCount: 0
      } as IAccumulatedResults
    ),
    // Accumulate all the results from each response
    reduce(
      (acc, current) => {
        // Finalize the accumulated data
        acc.data = current.data;
        acc.messages = current.messages;
        acc.totalCount = current.totalCount || 0;
        return acc;
      },
      { data: [] as any[], messages: [] as string[], totalCount: 0 }
    ),
    mergeMap((res) => {
      var resDt: IResultsData = { data: [], message: '' };
      // console.log('queryCampaignFlightsGQL$ - final response', res);
      resDt.data = responseFormatter ? responseFormatter(res.data) : res.data;
      resDt.message = res.messages.length > 0 ? res.messages.join(';') : '';
      resDt.totalCount = res.totalCount;
      return of(resDt);
    }),
    // tap((finalResult) => {
    //   // console.log('Final aggregated edges', finalResult);
    // }),
    take(1)
    // Handle success and log final result

    // // Error handling for the entire observable stream
    // catchError((err) => {
    //   console.error('Error during pagination process', err);
    //   return throwError(() => new Error('Pagination process failed'));
    // })
  );
};

// Make getAsyncGQLResult generic to accept specific types of IQueryGQL
export async function getAsyncGQLResult<TPayload>(props: IQueryGQL<TPayload>) {
  try {
    const result = await firstValueFrom(
      queryGQL$((props as unknown) as IQueryGQL)
    );
    console.log(result); // This will not be executed
    return result;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error caught:', error.message); // Only access message if error is an instance of Error
    } else {
      console.error('Unknown error caught:', error); // Handle other types of errors (non-Error objects or primitives)
    }
  }
}
