import React, { useCallback, useEffect, useState } from 'react';

import './style.scss';

import { Link, useHistory, useLocation } from 'react-router-dom';
import Button from '@ttd/maui/lib/components/core/Button';
import RadioGroupControl from '@ttd/maui/lib/components/controls/RadioGroupControl';
import TextControl from '@ttd/maui/lib/components/controls/TextControl';
import CheckBoxControl from '@ttd/maui/lib/components/controls/CheckboxControl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { getListPkgPerformanceOverview } from '../../actions';
import _ from 'lodash';
import { PackagePerformanceOverviewType } from '../../actions/actionTypes';
import Card from '@ttd/maui/lib/components/display/Card';
import HorizontalSeparator from '@ttd/maui/lib/components/display/HorizontalSeparator';
import { getPackagePerformanceExport } from '../../services/packageService';
import { requestStart, requestStop } from '../../actions/requestActions';
import {
  exportPackagePerformanceExcel,
  getAdvertiserOptionsFromPackages,
  getPackageListByAdvertiser
} from '../../utils/packageUtils';

const BulkExport = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const homePageUrl = useSelector(
    (state: RootState) => state.package.homePageUrl
  );
  const listPackagePerformanceOverview = useSelector(
    (state: RootState) => state.package.listPackagePerformanceOverview
  );

  const [advertiserOptions, setAdvertiserOptions] = useState([]);
  const [packageOptions, setPackageOptions] = useState(
    [] as PackagePerformanceOverviewType[]
  );
  const [selectedAdvertiser, setSelectedAdvertiser] = useState({
    id: null,
    name: ''
  });
  const [selectedPackages, setSelectedPackages] = useState(
    [] as PackagePerformanceOverviewType[]
  );
  const [selectAllPackages, setSelectAllPackages] = useState(false);
  const [packageSearchKey, setPackageSearchKey] = useState('');

  useEffect(() => {
    if (
      listPackagePerformanceOverview &&
      listPackagePerformanceOverview.length <= 0
    ) {
      dispatch(
        getListPkgPerformanceOverview({
          Limit: 10000,
          Offset: 0,
          SortField: 'package_name',
          SortOrder: 'Desc'
        })
      );
    }
  }, [dispatch, listPackagePerformanceOverview]);

  const onFilterPackage = useCallback(
    (keyword) => {
      const packageList = getPackageListByAdvertiser(
        selectedAdvertiser,
        listPackagePerformanceOverview.packages
      );
      if (!keyword) {
        setPackageOptions(packageList);
        return;
      }
      const newPackageList = _.filter(packageList, (option) =>
        option.package_name.toLowerCase().includes(keyword.toLowerCase())
      );
      setPackageOptions(newPackageList);
    },
    [selectedAdvertiser, listPackagePerformanceOverview.packages]
  );

  useEffect(() => {
    const advertiserOptions = getAdvertiserOptionsFromPackages(
      listPackagePerformanceOverview.packages
    );
    setAdvertiserOptions(advertiserOptions);
  }, [listPackagePerformanceOverview.packages]);

  useEffect(() => {
    if (location.search && !selectedAdvertiser.id) {
      const urlParams = new URLSearchParams(location.search);
      const advertiserId = urlParams.get('advertiser');
      const advertiser = _.find(advertiserOptions, ['id', advertiserId]);
      if (advertiser) {
        setSelectedAdvertiser(advertiser);
      }
    }
  }, [advertiserOptions, location.search, selectedAdvertiser.id]);

  useEffect(() => {
    const packageList = getPackageListByAdvertiser(
      selectedAdvertiser,
      listPackagePerformanceOverview.packages
    );

    if (selectedAdvertiser.id) {
      const urlParams = new URLSearchParams(location.search);
      urlParams.set('advertiser', selectedAdvertiser.id || 'all');
      history.replace({ search: urlParams.toString() });
    }

    setPackageOptions(packageList);
    setSelectedPackages([]);
    setSelectAllPackages(false);
    setPackageSearchKey('');
  }, [
    selectedAdvertiser,
    listPackagePerformanceOverview.packages,
    location.search,
    history
  ]);

  useEffect(() => {
    const isSelectAll = _.every(packageOptions, (item) =>
      _.find(selectedPackages, ['package_id', item.package_id])
    );
    setSelectAllPackages(packageOptions.length > 0 && isSelectAll);
  }, [selectedPackages, packageOptions]);

  useEffect(() => {
    onFilterPackage(packageSearchKey);
  }, [packageSearchKey, onFilterPackage]);

  const handleSelectPackage = (checked, packageItem) => {
    if (checked) {
      setSelectedPackages((prevState) => [...prevState, packageItem]);
    } else {
      const newSelectedList = _.filter(
        selectedPackages,
        (item) => item.package_id !== packageItem.package_id
      );
      setSelectedPackages(newSelectedList);
    }
  };

  const handleSelectAllPackages = (checked) => {
    if (checked) {
      setSelectedPackages([...packageOptions]);
    } else {
      const pkgOptionIds = _.flatMap(packageOptions, (el) => el.package_id);
      const newSelectedList = _.filter(
        selectedPackages,
        (item) => !_.includes(pkgOptionIds, item.package_id)
      );
      setSelectedPackages(newSelectedList);
    }
    setSelectAllPackages(checked);
  };

  const getFileNameSuffix = (): string => {
    // Only 1 package is selected
    if (selectedPackages.length === 1) {
      return `_${selectedPackages[0].package_name}`;
    }

    // All advertisers and all packages are selected
    if (
      selectedAdvertiser.id === 'all' &&
      selectedPackages.length === listPackagePerformanceOverview.packages.length
    ) {
      return '_all_advertisers';
    }

    // One advertiser and all packages are selected
    if (
      selectedAdvertiser.id !== 'all' &&
      selectedPackages.length ===
        getPackageListByAdvertiser(
          selectedAdvertiser,
          listPackagePerformanceOverview.packages
        ).length
    ) {
      return `_${selectedAdvertiser.name}`;
    }
    return '';
  };

  const exportFile = async () => {
    dispatch(requestStart());
    let packageData = await getPackagePerformanceExport(
      selectedAdvertiser.id === 'all' ? '' : selectedAdvertiser.id || ''
    );
    packageData = _.filter(packageData, (packageItem) =>
      _.find(selectedPackages, ['package_id', packageItem.pi])
    );

    await exportPackagePerformanceExcel(packageData, getFileNameSuffix());

    dispatch(requestStop());
  };
  return (
    <div className="wrapper">
      <div className="bulk-export-header">
        <h1>Bulk Export</h1>
        <a
          className="btn-user-manual"
          data-testid="btn-user-manual"
          href="./Bulk_Export_Manual.pdf"
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button>User Manual</Button>
        </a>
      </div>
      <div className="bulk-export">
        <h3>Tick on packages and campaigns to export</h3>
        <div className="advertiser-package">
          <Card title="Advertisers" theme="page-card" className="card-block">
            <HorizontalSeparator />
            <div data-testid="advertisers-list" className="list-view">
              <RadioGroupControl
                availableValues={advertiserOptions}
                value={selectedAdvertiser}
                id="advertiser"
                displayNameKey="name"
                primaryKey="id"
                onChange={(value) => setSelectedAdvertiser(value)}
              />
            </div>
          </Card>

          <Card title="Packages" theme="page-card" className="card-block">
            <HorizontalSeparator />
            <div className="package-search-block">
              <span>Search:</span>
              <TextControl
                data-testid="packages-search-box"
                className="search-input"
                value={packageSearchKey}
                onChange={(event) => setPackageSearchKey(event.target.value)}
                placeholder="Enter package name"
              />
            </div>
            <div
              data-testid="checkbox-select-all-packages"
              className="package-selection"
            >
              <CheckBoxControl
                id="select-all"
                value={selectAllPackages}
                onChange={(event) =>
                  handleSelectAllPackages(event.target.checked)
                }
              />
              <label htmlFor="select-all">
                {selectAllPackages ? 'Unselect all' : 'Select all'}
              </label>
            </div>
            <div data-testid="packages-list" className="list-view">
              {_.map(packageOptions, (option) => (
                <div key={option.package_id} className="package-selection">
                  <CheckBoxControl
                    id={option.package_id}
                    value={
                      _.find(
                        selectedPackages,
                        (selected) => selected.package_id === option.package_id
                      ) || 0 // || 0 initializes as "controlled component"
                    }
                    onChange={(event) =>
                      handleSelectPackage(event.target.checked, option)
                    }
                  />
                  <label htmlFor={option.package_id}>
                    {option.package_name}
                  </label>
                </div>
              ))}
            </div>
          </Card>
        </div>
        <Link to={homePageUrl} className="back-button">
          <Button data-testid="btn-back" type="button" displayType="secondary">
            Back
          </Button>
        </Link>
        <Button
          data-testid="btn-export"
          type="button"
          displayType="primary"
          onClick={exportFile}
          disabled={selectedPackages.length <= 0}
        >
          EXPORT
        </Button>
      </div>
    </div>
  );
};

export default BulkExport;
