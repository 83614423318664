import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

import './styles/index.scss';
import 'styles/base.scss';
import '@ttd/maui/src/fonts/avenir.scss';
import Header from './partials/Header';
import Homepage from './screens/Homepage';
import Login from './screens/Login';
import Logout from './screens/Logout';
import NotFound from './screens/Notfound';
import Package from './screens/Package';
import PackagePerformanceDetail from './screens/PackagePerformanceDetail';
import PackageSetting from './screens/PackageSetting';
import BulkEdit from './screens/BulkEdit';
import { getCookies } from './utils';
import {
  BULK_EDIT_PATH,
  BULK_EXPORT_PATH,
  FORBIDDEN_PATH,
  HOME_PATH,
  PACKAGE_PATH,
  PACKAGE_PERFORMANCE_DETAIL_PATH,
  PACKAGE_SETTING_PATH,
  SIGNIN_OIDC_PATH,
  SIGNOUT_OIDC_PATH,
  LOGIN_PATH
} from './constants/route';
import AuthProvider from './components/AuthProvider';
import LocalAuthProvider from './components/LocalAuthProvider';
import Forbidden from './screens/Forbidden';
import { useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import BulkExport from './screens/BulkExport';
import Spin from './components/Spin';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const client = new QueryClient();

function App() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLocal, setIsLocal] = useState(
    (process?.env?.REACT_APP_IS_LOCAL &&
      process?.env?.REACT_APP_IS_LOCAL.toLocaleLowerCase() !== 'false') ||
      false
  );
  const location = useLocation();
  // console.log('APP isLocal' + isLocal);
  const [isHeaderForPkgSetting, setIsHeaderForPkgSetting] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { loggedIn } = useSelector((state: RootState) => state.user);
  const { isLoading } = useSelector((state: RootState) => state.request);

  useEffect(() => {
    // console.log('isLocal ' + isLocal + ' origin: ' + origin);
    if (isLocal) {
      setIsLoggedIn(
        !isEmpty(getCookies('ttdToken')) && !isEmpty(getCookies('packageToken'))
      );
    }
    if (loggedIn) {
      setIsLoggedIn(loggedIn);
    }
  }, [isLocal, loggedIn]);

  useEffect(() => {
    if (location?.pathname.includes('/package-setting/')) {
      setIsHeaderForPkgSetting(true);
    } else {
      setIsHeaderForPkgSetting(false);
    }
  }, [location]);
  return (
    <div className="App">
      <Spin showLoading={isLoading} />
      {isLoggedIn && !isHeaderForPkgSetting && <Header />}
      {isLocal && ( // non OIDC route
        <Switch>
          <Route
            exact
            path={[
              HOME_PATH,
              PACKAGE_PATH,
              PACKAGE_PERFORMANCE_DETAIL_PATH,
              PACKAGE_SETTING_PATH,
              BULK_EDIT_PATH,
              BULK_EXPORT_PATH
            ]}
          >
            <QueryClientProvider client={client}>
              <LocalAuthProvider>
                <Switch>
                  <Route exact path={HOME_PATH} component={Homepage} />
                  <Route exact path="/logout" component={Logout} />
                  <Route exact path={PACKAGE_PATH} component={Package} />
                  <Route
                    path={PACKAGE_PERFORMANCE_DETAIL_PATH}
                    component={PackagePerformanceDetail}
                  />
                  <Route
                    path={PACKAGE_SETTING_PATH}
                    component={PackageSetting}
                  />
                  <Route exact path={BULK_EDIT_PATH} component={BulkEdit} />
                  <Route exact path={BULK_EXPORT_PATH} component={BulkExport} />
                  <Route path="*" component={NotFound} />
                </Switch>
              </LocalAuthProvider>
              {/* <ReactQueryDevtools initialIsOpen /> */}
            </QueryClientProvider>
          </Route>
          <Route exact path={LOGIN_PATH}>
            {isLoggedIn ? <Redirect to={HOME_PATH} /> : <Login />}
          </Route>
          <Route path="*" component={NotFound} />
        </Switch>
      )}
      {!isLocal && (
        <Switch>
          <Route
            exact
            path={[
              HOME_PATH,
              LOGIN_PATH,
              PACKAGE_PATH,
              PACKAGE_PERFORMANCE_DETAIL_PATH,
              PACKAGE_SETTING_PATH,
              BULK_EDIT_PATH,
              BULK_EXPORT_PATH
            ]}
          >
            <QueryClientProvider client={client}>
              <AuthProvider>
                <Route exact path={HOME_PATH} component={Homepage} />
                <Route exact path={PACKAGE_PATH} component={Package} />
                <Route
                  path={PACKAGE_PERFORMANCE_DETAIL_PATH}
                  component={PackagePerformanceDetail}
                />
                <Route path={PACKAGE_SETTING_PATH} component={PackageSetting} />
                <Route exact path={BULK_EDIT_PATH} component={BulkEdit} />
                <Route exact path={BULK_EXPORT_PATH} component={BulkExport} />
              </AuthProvider>
              {/* <ReactQueryDevtools initialIsOpen /> */}
            </QueryClientProvider>
          </Route>
          <Route
            path={SIGNIN_OIDC_PATH}
            render={({ location }) => (
              <Redirect to={{ ...location, pathname: HOME_PATH }} />
            )}
          />
          <Route
            path={SIGNOUT_OIDC_PATH}
            render={({ location }) => (
              <Redirect to={{ ...location, pathname: HOME_PATH }} />
            )}
          />
          <Route path={FORBIDDEN_PATH} component={Forbidden} />
          <Route path="*" component={NotFound} />
        </Switch>
      )}
    </div>
  );
}

export default App;
