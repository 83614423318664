import Cookies from 'universal-cookie';
import _ from 'lodash';
import XLSX from 'sheetjs-style';

const cookies = new Cookies();

export const titleCase = (f: string | undefined) => {
  if (!f) {
    return '';
  }
  return (
    f
      .replace('_', ' ')
      .replace(/-/g, ' ')
      .split(' ')
      // 1st character -> upper case, rest lower case
      .map((f) => {
        return f.charAt(0).toUpperCase() + f.slice(1).toLowerCase();
      })
      .join(' ')
  );
};

/**
 * calculates the start of the previous Month for a given date
 * @param baseDate
 * @returns Date
 */
export const getLastMonth = (baseDate: Date) => {
  var curYear = baseDate.getFullYear(),
    curMonth = baseDate.getMonth(),
    lastMonth: number;
  if (curMonth === 0) {
    // January
    curYear -= 1; // use December in previous year
    lastMonth = 11;
  } else {
    // calculate normally
    lastMonth = curMonth - 1;
  }
  return new Date(curYear, lastMonth, 1);
};

export const formatFloat = (value: number, digits = 2): string => {
  if (value !== null && value !== undefined) {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: digits
    });
  }
  return '';
};
export const formatInt = (value: number): string =>
  formatFloat(value, 0).replace(/\.\d+$/, '');

export const formatPct = (value: number): string =>
  formatFloat(value, 0) + ' %';

export const getStaticFile = (url) => {
  return `${process.env.PUBLIC_URL}/${url}`;
};

const checkLocalData = (name) => {
  return name === 'local' ? localStorage : sessionStorage;
};

export const getLocalData = (item: string, local: string = 'session') => {
  const data = checkLocalData(local).getItem(item);
  return data ? data : false;
};

export const insertLocalData = (
  item: string,
  val: string,
  local: string = 'session'
) => {
  const data = getLocalData(item);
  if (data) {
    removeLocalData(item);
  }

  return checkLocalData(local).setItem(item, val);
};

export const removeLocalData = (item: string, local: string = 'session') => {
  return checkLocalData(local).removeItem(item);
};

export const removeAllLocalData = (local: string = 'session') => {
  return checkLocalData(local).clear();
};

export const getCookies = (name) => {
  return cookies.get(name);
};

export const getAllCookies = () => {
  return cookies.getAll();
};

export const setCookies = (name: string, val: any, exp: number = 28800) => {
  const options = {
    path: '/',
    sameSite: 'strict',
    secure: true,
    maxAge: exp
  };
  removeCookies(name);
  // @ts-ignore
  return cookies.set(name, val, options);
};

export const removeCookies = (name: string) => {
  return cookies.remove(name, { path: '/' });
};

export const getCurrentTimestamp = (second: boolean = true) => {
  const timestamp = Date.now();
  return second ? Math.floor(timestamp / 1000) : timestamp;
};

export const getQueryParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
          let [key, value] = param.split('=');
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : '';
          return params;
        }, {})
    : {};
};

export const uncheckAllCheckbox = () => {
  const uncheck = document.getElementsByTagName('input');
  for (let i = 0; i < uncheck.length; i++) {
    if (uncheck[i].type === 'checkbox') {
      uncheck[i].checked = false;
    }
  }
};

export const getKeyByValue = (name: string, data: object) => {
  return _.findKey(data, (o) => o === name);
};

// reads external configuration file from AWS
export const readTemplate = (excelUrl: string) => {
  return new Promise<XLSX.WorkBook>((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open(
      'GET',
      excelUrl
      // 'https://ps-nt-sa-831-nissan-pkg.s3.amazonaws.com/Package_Campaign_Creation_Template.xlsx'
    );
    xhr.responseType = 'blob'; //force the HTTP response, response-type header to be blob
    xhr.onload = function () {
      var blob = xhr.response; //xhr.response is now a blob object
      var myReader = new FileReader();
      myReader.addEventListener('loadend', (e): XLSX.WorkBook | undefined => {
        //@ts-ignore
        var res: ArrayBufferLike = e && e.target && e.target.result;
        // console.log(res);
        try {
          var data = new Uint8Array(res);
          // console.log(data);
          var wb: XLSX.WorkBook = XLSX.read(data, {
            type: 'array',
            cellStyles: true
          });
          resolve(wb);
          return wb;
        } catch (e) {
          if (e instanceof Error) {
            console.error(e.message);
          }
          reject(undefined);
          return undefined;
        }
      });
      myReader.readAsArrayBuffer(blob);
    };
    xhr.send();
  });
};

// prettier-ignore
var fieldTypes={
  packageid:'number',packagename:'string',campaignname:'string',campaignid:'string',campaignflightId:'number',startdateinclusiveutc:'number',enddateexclusiveutc:'number', package_id:'number',package_name:'string',campaign_name:'string',campaign_id:'string',target_budget:'number',total_spend:'number',budget:'number',total_impressions:'number',impressions:'number',spend:'number',pacing_percent:'number',last_updated_at:'date',last_viewed_at:'date',end_date:'date',
  baseadvertiserid:'string', basecampaignid:'string', basecampaignname:'string', targetadvertiserid:'string', targetcampaignname:'string', clicks:'number', adv_cpc_usd:'number', cpm:'number', ctr:'number',
  vcpm_usd:'number'}; //cspell:disable-line

export const noSort = () => 0;
/**
 * numeric sort
 * @param {*} a - value 1
 * @param {*} b - value 2
 * @param {*} key - key to compare
 * @param {*} desc - sort order (true = descending), false = ascending
 */
export const numSort = (a, b, key, desc = false) => {
  var valA = a[key],
    valB = b[key],
    n1 = Number(valA),
    n2 = Number(valB),
    replaceNumber;
  if (desc) {
    replaceNumber = Number.NEGATIVE_INFINITY;
  } else {
    replaceNumber = Number.POSITIVE_INFINITY;
  }
  if (Number.isNaN(n1) || (n1 === 0 && valA !== 0)) {
    n1 = replaceNumber;
  }
  if (Number.isNaN(n2) || (n2 === 0 && valB !== 0)) {
    n2 = replaceNumber;
  }
  if (n1 === n2) {
    return 0;
  }
  if (desc) {
    return n2 - n1;
  }
  return n1 - n2;
};

/**
 * string sort
 * @param {*} a - value 1
 * @param {*} b - value 2
 * @param {*} key - key to compare
 * @param {*} desc - sort order (true = descending), false = ascending
 */
export const strSort = (a, b, key, desc = false) => {
  var s1 = a[key],
    s2 = b[key],
    sRet,
    replaceString = '';
  if (!desc) {
    replaceString = String.fromCharCode(1);
    if (!s1) {
      s1 = replaceString;
    }
    if (!s2) {
      s2 = replaceString;
    }
  }
  if (desc) {
    // eslint-disable-next-line no-nested-ternary
    sRet = s1 > s2 ? -1 : s2 > s1 ? 1 : 0;
  } else {
    // eslint-disable-next-line no-nested-ternary
    sRet = s1 > s2 ? 1 : s2 > s1 ? -1 : 0;
  }
  return sRet;
};

/**
 * date string sort
 * @param {*} a - value 1
 * @param {*} b - value 2
 * @param {*} key - key to compare
 * @param {*} desc - sort order (true = descending), false = ascending
 */
export const dtStrSort = (a, b, key, desc = false) => {
  var sA = a[key];
  var sB = b[key];
  var replaceDate, dtA, dtB;
  if (desc) {
    replaceDate = new Date('1900-01-01 00:00:00');
  } else {
    replaceDate = new Date();
  }
  if (sA) {
    dtA = new Date(a[key]);
  } else {
    dtA = replaceDate;
  }
  if (sB) {
    dtB = new Date(sB);
  } else {
    dtB = replaceDate;
  }
  if (desc) {
    // @ts-ignore
    return dtB - dtA;
  }
  // @ts-ignore
  return dtA - dtB;
};

/**
 * return sort function based on data type of field
 * @param {*} field
 */
export const getSortFunc = (field) => {
  if (field) {
    var fld = field.toLowerCase(),
      type = fieldTypes[fld];
    if (type === 'number') {
      return numSort;
    }
    if (type === 'string') {
      return strSort;
    }
    if (type === 'date') {
      return dtStrSort;
    }
  }
  return noSort;
};

/**
 * sort jsArr
 * @param {*} jsArr - data to sort
 * @param {*} sortFunc - sort function
 * @param {*} key - key to compare
 * @param {*} desc - sort order (true = descending), false = ascending
 */
export const sortJsonArr = (jsArr, sortFunc, key, desc = false) => {
  // console.log('sortJsonArr', JSON.stringify(jsArr) + '\n', sortFunc, key, desc);
  if (jsArr && jsArr.length && jsArr.length > 0) {
    return Array.prototype.sort.call(jsArr, (a, b) =>
      sortFunc(a, b, key, desc)
    );
  }
  return jsArr;
};

/**
 * limits an Array by Limit and Offset
 * @param jsArr -  Array
 * @param limit - max number of Array elements to return
 * @param offset - start element
 * @returns - limited Array
 */
export const limitJsonArr = (jsArr: Array<any>, limit, offset) => {
  var ret: Array<any> = [],
    filteredCount = 0,
    offsetCount = 0,
    unfilteredCount = 0;
  if (jsArr && jsArr.length > 0) {
    var aLen = jsArr.length,
      aEnd = aLen,
      aStart = 0;
    if (!Number.isNaN(offset) && offset >= 0) {
      aStart = Number(offset);
    }
    if (!Number.isNaN(limit) && limit > 0) {
      aEnd = aStart + Number(limit);
    }
    ret = jsArr.slice(aStart, aEnd);
    filteredCount = ret.length;
    offsetCount = jsArr.slice(aStart, aLen).length;
    unfilteredCount = aLen;
  }

  return { retArr: ret, filteredCount, offsetCount, unfilteredCount };
};

export const trimStr = (sStr: any) => {
  var sVal = sStr;
  if (typeof sVal === 'string') {
    sVal = sVal.trim();
  }
  return sVal;
};

export const getExcelDateCode = (sDate: string) => {
  var basedate = new Date(1899, 11, 30, 0, 0, 0);
  var dnthresh =
    basedate.getTime() + (0 - basedate.getTimezoneOffset()) * 60000;

  var day_ms = 24 * 60 * 60 * 1000;
  var days_1462_ms = 1462 * day_ms;

  function datenum(v, date1904) {
    let epoch = v.getTime();
    if (date1904) {
      epoch -= days_1462_ms;
    }
    return (epoch - dnthresh) / day_ms;
  }

  var dt = new Date(sDate + 'Z');
  return datenum(dt, false);
};

/**
 * splits array into array of arrays with n members
 * @param array
 * @param n
 */
export const split_array = (array, n) => {
  var res: any = [];
  if (n > 0) {
    let [...arr] = array;
    while (arr.length) {
      res.push(arr.splice(0, n));
    }
  }
  return res;
};

/**
 * provides error handling for Promise.all calls
 * @param {*} errs - array to store full errors
 * @param {*} errMsgs - array to only store error messages
 * @returns {handle_errors - updates promiseArray with mapped error handlers,
 *  catch_errors - error handler for individual error handling (e.g. serial promise execution)}
 */
export const generatePromiseErrorHandlers = (errs, errMsgs) => {
  // push potential errors into the correct arrays
  const catch_errors = (e) => {
    var err = '';
    if (e.code) {
      err += `${e.code}:`;
    }
    if (e.message) {
      err += e.message;
    }
    errMsgs.push(err);
    if (e.stack) {
      err += `\n${e.stack}`;
    }
    errs.push(err);
  };
  // adds error handler to each promise in promiseArr
  const handle_errors = (promiseArr) =>
    promiseArr.map((p) =>
      p.catch((e) => {
        catch_errors(e);
      })
    );
  return { handle_errors, catch_errors };
};

/**
 * generates a promise for a functions and it's arguments
 * @param {*} func - function
 * @param  {...any} fnArgs - arguments
 * @returns promise resolving with the function result
 */
export const genValPromise = (func, ...fnArgs) => {
  var args = fnArgs;
  if (typeof func === 'function') {
    var pFunc = () => {
      return func(...args);
    };
    return new Promise((resolve) => {
      resolve(pFunc());
    });
  }
  return new Promise((resolve, reject) => {
    reject(new Error('genValPromise error - func is not a function '));
  });
};

/**
 * generates the real promise only when testArr is not empty
 * using genValPromise and func + fnArgs
 * @param testArr
 * @param func
 * @param fnArgs
 */
export const genCondPromise = async (testArr, func, ...fnArgs) => {
  if (testArr && testArr.length > 0) {
    return genValPromise(func, ...fnArgs);
  }
  return [];
};

// merges arrays passed in and returns the result
export function mergeCondArray(...arrArgs) {
  var resArr = [],
    argLen = arguments.length;
  for (let i = 0; i < argLen; i++) {
    if (Array.isArray(arguments[i])) resArr = resArr.concat(arguments[i]);
  }
  return resArr;
}

export const kf_download = (filename, text) => {
  var element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const doDownload = (sObj, fileName) => {
  const blob = new Blob([JSON.stringify(sObj)], { type: 'text/json' });

  // Create a download link for the blob content
  const downloadLink = downloadBlob(blob, fileName);

  // Set the title and classnames of the link
  downloadLink.title = 'Export as JSON';
  downloadLink.classList.add('btn-link', 'download-link');

  // Set the text content of the download link
  downloadLink.textContent = 'Export Records';

  // Attach the link to the DOM
  document.body.appendChild(downloadLink);
};

export function downloadBlob(blob, filename) {
  // Create an object URL for the blob object
  const url = URL.createObjectURL(blob);

  // Create a new anchor element
  const a = document.createElement('a');

  // Set the href and download attributes for the anchor element
  // You can optionally set other attributes like `title`, etc
  // Especially, if the anchor element will be attached to the DOM
  a.href = url;
  a.download = filename || 'download';

  // Click handler that releases the object URL after the element has been clicked
  // This is required for one-off downloads of the blob content
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      // @ts-ignore
      this.removeEventListener('click', clickHandler);
    }, 150);
  };

  // Add the click event listener on the anchor element
  // Comment out this line if you don't want a one-off download of the blob content
  a.addEventListener('click', clickHandler, false);

  // Programmatically trigger a click on the anchor element
  // Useful if you want the download to happen automatically
  // Without attaching the anchor element to the DOM
  // Comment out this line if you don't want an automatic download of the blob content
  a.click();

  // Return the anchor element
  // Useful if you want a reference to the element
  // in order to attach it to the DOM or use it in some other way
  return a;
}
