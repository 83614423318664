import { IRunBaseId } from './LoadData';
import { requestTtdApi$ } from './requestTtdApi';


export interface IRunBaseRead extends IRunBaseId {
  BaseIdName?: string;
  BaseId: string;
}

/**
 * read campaigns + filter relevant properties
 * @param props
 */
export const readCampaignIds$ = (props: IRunBaseRead) => {
  var BaseIdName = 'CampaignId';
  var campaignId = props[BaseIdName]
  return requestTtdApi$({
    ...props,
    method: 'GET',
    shortUrl: '/campaign/' + campaignId,
    responseFilterFieldList: [
      'CampaignName',
      'CampaignId',
      'AdvertiserId',
      'StartDate',
      'EndDate',
      'Budget',
      'DailyBudget',
      'BudgetInImpressions',
      'DailyBudgetInImpressions',
      'PacingMode',
      'TimeZone',
      'CampaignFlights',
      // perhaps only needed here
      'CreatedAtUTC',
      'LastUpdatedAtUTC',
      'CampaignConversionReportingColumns',
      'AutoAllocatorEnabled',
      'AutoPrioritizationEnabled',
      'Objective',
      'PrimaryGoal',
      'PrimaryChannel'
    ],
    errorId: BaseIdName
  });
};
