import {
  PackagePerformanceOverviewType,
  pkgExportPackage,
  pkgExportPackageCampaigns
} from '../actions/actionTypes';
import { readTemplate } from './index';
import XLSX from 'sheetjs-style';
import _ from 'lodash';

const preparePackagePerformanceExcelData = (
  packageData: pkgExportPackage[]
): string[][] => {
  const headers = [
    'ai', // Advertiser Id
    'pi', // Package Id
    'pn', // Package name
    'ci', // Campaign Id
    'cn', // Campaign name
    'rd', // Report date
    'tb', // Target budget
    's', // Total spend
    'i', // Total impressions
    'p', // Pacing percent
    'b', // Budget
    'c', // Clicks
    'cpm', // Advertiser CPM
    'ctr', // CTR in %
    'cpc', // Advertiser CPC
    'vcpm', // Advertiser viewable CPM - cspell:disable-line
    'ed' // Campaign end date
  ];
  const excelData: string[][] = [];
  _.forEach(packageData, (pkgExport: pkgExportPackage) => {
    _.forEach(pkgExport.m, (campaign: pkgExportPackageCampaigns) => {
      const combineData = { ...pkgExport, ...campaign };
      const rowData: string[] = [];
      _.forEach(headers, (header) => {
        let celData = combineData[header];
        if (header === 'ed') {
          // Format DateTime
          celData = celData.replace('T', ' ');
        }
        rowData.push(celData);
      });
      excelData.push(rowData);
    });
  });
  return excelData;
};

export const exportPackagePerformanceExcel = async (
  packageData: pkgExportPackage[],
  fileNameSuffix = ''
) => {
  const wb = await readTemplate('/Package_Campaign_Performance_Template.xlsx');
  if (wb) {
    const sName = wb.SheetNames[0];
    const mySheet = wb.Sheets[sName];

    const sheetData = preparePackagePerformanceExcelData(packageData);
    const pkgSheet = XLSX.utils.sheet_add_aoa(mySheet, sheetData, {
      dateNF: 22,
      origin: 1 // 2nd row
    });
    wb.Sheets[sName] = pkgSheet;

    XLSX.writeFile(wb, `Package_Campaign_Performance${fileNameSuffix}.xlsx`, {
      bookType: 'xlsx',
      type: 'file',
      cellDates: false
    });
  }
};

export const getAdvertiserOptionsFromPackages = (packagesList = []) => {
  let advertisers = [{ id: 'all', name: 'All package advertisers' }] as any;
  if (packagesList.length > 0) {
    packagesList.map((item: PackagePerformanceOverviewType) => {
      const option = {
        id: item.advertiser_id,
        name: item.advertiser_name
      };
      advertisers = _.uniqWith([...advertisers, option], _.isEqual);
      return true;
    });
  }
  return advertisers;
};

export const getPackageListByAdvertiser = (advertiser, packageList = []) => {
  let filteredList = [];
  if (packageList.length > 0) {
    filteredList = _.filter(
      packageList,
      (o) => advertiser.id === 'all' || o.advertiser_id === advertiser.id
    );
  }
  return filteredList;
};
