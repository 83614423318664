import React from 'react';
import Button from '@ttd/maui/lib/components/core/Button';
import { useDispatch } from 'react-redux';
import { logoutAction } from '../../actions/userActions';

import { getStaticFile } from '../../utils';

function Header({ showLogoutButton = true, ...props }) {
  const dispatch = useDispatch();

  const onLogout = () => {
    if (props.isPkgSettingPage) {
      props.actionLogout();
      setTimeout(() => {
        dispatch(logoutAction());
      }, 500);
    } else {
      dispatch(logoutAction());
    }
  };
  return (
    <header className="header">
      <div className="logo">
        <img
          src={getStaticFile('images/logos/ttd-logo-white.png')}
          alt="Logo TTD"
        />
      </div>
      {showLogoutButton && (
        <Button
          type="button"
          ref={() => {}}
          id="id-btn-logout"
          displayType="primary"
          onClick={onLogout}
        >
          Logout
        </Button>
      )}
    </header>
  );
}

export default Header;
