import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import ButtonIcon from '@ttd/maui/lib/components/display/Button/Button';
import TextControl from '@ttd/maui/lib/components/controls/TextControl';
import { uncheckAllCheckbox } from '../../../utils';

function AssignedCampaigns(
  { items, unassignCampaigns, onUnassign, filterName, onUpdateFilterName }
) {
  const campItems = items;
  const [campaignsList, setCampaignList] = useState(items);
  const [selectedList, setSelectedList] = useState([]);
  const [error, setError] = useState('');
  const updateFilter = (e) => {
    const val = e.target.value;
    onUpdateFilterName(val, true);
    const newList = _.filter(campItems, o => o.campaign_name.includes(val));
    setCampaignList(newList);
  };

  const updateSelectedList = (id, name) => {
    let newList;
    const data = {
      campaign_id: id,
      campaign_name: name,
    };
    if (_.find(selectedList, data)) {
      newList = selectedList.filter((item: any) => {
        return item.campaign_id !== id;
      });
    } else {
      newList = [...selectedList, ...[data]];
    }
    setSelectedList(newList);

    if (items.length === newList.length) {
      setError('At least 1 campaign needs to be assigned to the package');
    } else {
      setError('');
    }
  };

  const updateCampaigns = () => {
    if (selectedList.length > 0) {
      selectedList.map((item) => _.remove(campItems, item));
    }
    // update assigned campaigns list
    setCampaignList(_.filter(campItems, o => o.campaign_name.includes(filterName)));
    // update unassigned campaigns list
    const unassignedCampaigns = [...unassignCampaigns, ...selectedList];
    onUnassign(unassignedCampaigns, campItems);
    uncheckAllCheckbox();
    setSelectedList([]);
  };

  useEffect(() => {
    setCampaignList(_.filter(items, o => o.campaign_name.includes(filterName)));
  }, [items, filterName]);

  return (
    <>
      <fieldset>
        <legend>Package Campaigns</legend>

        <div>
          <TextControl
            className="search-box"
            id="assignedFilterBox"
            value={filterName}
            onChange={updateFilter}
            placeholder="Enter filter key..."
          />

          <div className="display-box">
            <ul id="assignedList">
              {campaignsList
                ? campaignsList.length > 0 &&
                campaignsList.map((item, key) => {
                    return (
                      <li className="child" key={key}>
                        <input
                          type="checkbox"
                          value={item.campaign_id}
                          onChange={() =>
                            updateSelectedList(
                              item.campaign_id,
                              item.campaign_name
                            )
                          }
                          id={`unassignedItem_${item.campaign_id}`}
                        />
                        <label htmlFor={`unassignedItem_${item.campaign_id}`}>
                          {item.campaign_name}
                        </label>
                      </li>
                    );
                  })
                : []}
            </ul>
          </div>
        </div>
      </fieldset>

      <ButtonIcon
        data-testid="id-btn-unassign"
        type="button"
        glyphBefore="left-arrow"
        displayType="secondary"
        className="btn-unassign"
        onClick={updateCampaigns}
        disabled={selectedList.length <= 0 || error}
      >
        Unassign Selected Campaigns
      </ButtonIcon>
      <div className="error text-left">{error}</div>
    </>
  );
}

export default AssignedCampaigns;
