export const baseUrl = 'https://packages-qa.thetradedesk.com';
export const enableSolimar = true;
export const USER_SESSION_TIME = 48000; // second

// OIDC CONFIGURATION
export const OIDC_CONFIGURATION = {
  AUTH_URL: 'https://auth.thetradedesk.com/',
  SCOPE: 'openid profile ttdapi offline_access',
  CLIENT_ID: 'nissan-packages',
  CLIENT_ROOT: baseUrl
};

// prevent usings these advertiser ids in production environment
// needs to be a string list
// c-Spell:disable
export const filter_advertiser_ids = [
  // FY21
  '157s0mr',
  '4f1q3fc',
  '6830fnt',
  'blt80me',
  'tk5o0mm',
  // FY22
  '1dqoio5',
  '5ud12yz',
  '9voc82c',
  'h64mlja',
  't148ne9'
];
