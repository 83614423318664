import {
  SET_TOKEN_SUCCESS_ACTION,
  SET_TOKEN_FAILED_ACTION,
  USER_FAILED_ACTION,
  USER_SUCCESS_ACTION,
  UserActionTypes,
  TokenActionTypes,
  userInfo,
  RESET_USER_ERROR_MESSAGE,
  ResetErrorMessageActionTypes,
  SET_USER_CREDENTIAL_FAILED_ACTION,
  SET_USER_CREDENTIAL_SUCCESS_ACTION,
  UserCredentialActionTypes,
  USER_LOGOUT_FAILED_ACTION,
  USER_LOGOUT_SUCCESS_ACTION,
  UserLogoutActionTypes
} from './actionTypes';
import { requestStart, requestStop } from './requestActions';
import {
  getTokenPackageAPI,
  getTokenPackageAPI_OIDC,
  getTokenTTDAPI
} from '../services/userServices';
import {
  insertLocalData,
  removeLocalData,
  setCookies,
  getCookies,
  removeCookies,
  getLocalData,
  getCurrentTimestamp
} from '../utils';

import OidcManager from '../components/AuthProvider/oidc';
import { FORBIDDEN_PATH } from '../constants/route';

export const resetErrorMessage = (): ResetErrorMessageActionTypes => {
  return {
    type: RESET_USER_ERROR_MESSAGE
  };
};

const loginSuccessAction = (
  ttdToken: string,
  packageToken: string,
  username: string
): UserActionTypes => {
  return {
    type: USER_SUCCESS_ACTION,
    payload: {
      username,
      ttdToken,
      packageToken
    }
  };
};

const loginFailedAction = (): UserActionTypes => {
  return {
    type: USER_FAILED_ACTION
  };
};

export const loginAction = (ui: userInfo) => {
  return async (dispatch) => {
    try {
      dispatch(requestStart());
      dispatch(resetErrorMessage());
      const getTokenPackageResp = await getTokenPackageAPI(ui);
      const getTokenPackageRespIsSuccess =
        getTokenPackageResp.status === 200 &&
        getTokenPackageResp.data.authToken;
      if (!getTokenPackageRespIsSuccess) {
        throw new Error('invalid user or password');
      }
      const getTokenTTDResp = await getTokenTTDAPI({
        Login: ui.user,
        Password: ui.password,
        TokenExpirationInMinutes: 480
      });

      const getTokenTTDRespIsSuccess =
        getTokenTTDResp.status === 200 && getTokenTTDResp.data.Token;

      if (getTokenTTDRespIsSuccess) {
        var redirectUrl = getLocalData('redirectPath') || '/';
        if (redirectUrl === '/login') {
          redirectUrl = '/';
        }
        removeLocalData('redirectPath');
        insertLocalData(
          'tokenExpTime',
          getCurrentTimestamp().toString(),
          'local'
        );

        setCookies('username', ui.user);
        setCookies('ttdToken', getTokenTTDResp.data.Token);
        setCookies('packageToken', getTokenPackageResp.data.authToken);

        dispatch(
          loginSuccessAction(
            getTokenTTDResp.data.Token,
            getTokenPackageResp.data.authToken,
            ui.user
          )
        );
        dispatch(requestStop());
        window.location.href = redirectUrl;
      } else {
        dispatch(loginFailedAction());
        dispatch(requestStop());
      }
    } catch (e) {
      dispatch(loginFailedAction());
      dispatch(requestStop());
    }
  };
};

const logoutSuccessAction = (): UserLogoutActionTypes => {
  return {
    type: USER_LOGOUT_SUCCESS_ACTION
  };
};

const logoutFailedAction = (): UserLogoutActionTypes => {
  return {
    type: USER_LOGOUT_FAILED_ACTION
  };
};

export const logoutAction = () => {
  return async (dispatch) => {
    try {
      // dispatch(requestStart());
      removeCookies('username');
      removeCookies('packageToken');
      removeCookies('ttdToken');
      removeLocalData('tokenExpTime', 'local');
      dispatch(logoutSuccessAction());
      if (!process?.env?.REACT_APP_IS_LOCAL) {
        await OidcManager.signoutRedirect();
      } else {
        window.location.href = '/';
      }
    } catch (e) {
      dispatch(logoutFailedAction());
    }
    // finally {
    //   dispatch(requestStop());
    // }
  };
};

const setTokenSuccessAction = (
  ttdToken: string,
  packageToken: string,
  username: string
): TokenActionTypes => {
  return {
    type: SET_TOKEN_SUCCESS_ACTION,
    payload: {
      loggedIn: true,
      ttdToken,
      packageToken,
      username
    }
  };
};

const setTokenFailedAction = (): TokenActionTypes => {
  return {
    type: SET_TOKEN_FAILED_ACTION
  };
};

export const setTokenAction = () => {
  return async (dispatch) => {
    try {
      dispatch(requestStart());
      const ttdToken = getCookies('ttdToken');
      const packageToken = getCookies('packageToken');
      const username = getCookies('username');
      dispatch(setTokenSuccessAction(ttdToken, packageToken, username));
      dispatch(requestStop());
    } catch (e) {
      dispatch(setTokenFailedAction());
      dispatch(requestStop());
    }
  };
};

const setUserCredentialSuccessAction = (
  userData
): UserCredentialActionTypes => {
  //kf-debug
  // console.log('setUserCredentialSuccessAction: ' + JSON.stringify(userData));
  return {
    type: SET_USER_CREDENTIAL_SUCCESS_ACTION,
    payload: {
      loggedIn: true,
      ...userData
    }
  };
};

const setUserCredentialFailedAction = (): UserCredentialActionTypes => {
  return {
    type: SET_USER_CREDENTIAL_FAILED_ACTION
  };
};

export const setUserCredentialAction = (oidcUser) => {
  return async (dispatch) => {
    try {
      dispatch(requestStart());
      //kf-debug
      console.log('setUserCredentialAction: ' + JSON.stringify(oidcUser));
      try {
        const packageToken = await getTokenPackageAPI_OIDC({
          username: oidcUser.profile.name,
          ttdToken: oidcUser.access_token
        });

        setCookies('packageToken', packageToken);
        setCookies('username', oidcUser.profile.name);
        setCookies('ttdToken', oidcUser.access_token);
        dispatch(requestStop());
        dispatch(
          setUserCredentialSuccessAction({
            username: oidcUser.username,
            ttdToken: oidcUser.access_token,
            packageToken
          })
        );
      } catch (error) {
        /* No permission to access Package UI */
        const { pathname, search } = window.location;
        insertLocalData('redirectPath', `${pathname}${search}`);
        window.location.href = FORBIDDEN_PATH;
      }
    } catch (e) {
      dispatch(requestStop());
      dispatch(setUserCredentialFailedAction());
    }
  };
};
