import React from 'react';
import Button from '@ttd/maui/lib/components/core/Button';
import { useDispatch } from 'react-redux';
import { logoutAction } from '../../actions/userActions';
import Header from '../../partials/Header';

const Forbidden = () => {
  const dispatch = useDispatch();
  return (
    <div>
      <Header showLogoutButton={false} />
      <div className="wrapper">
        <h2>
          Invalid Package UI Credentials - please verify your username and
          password
        </h2>
        <Button
          onClick={() => {
            dispatch(logoutAction());
          }}
        >
          Use different account
        </Button>
      </div>
    </div>
  );
};

export default Forbidden;
