import React, { useCallback, useEffect, useState } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import DataTable, {
  SORT_DIRECTION,
  HORIZONTAL_ALIGNMENT
} from '@ttd/maui/lib/components/display/DataTable/DataTable';
import EnhancedDataTable from '@ttd/maui/lib/components/display/DataTable/EnhancedDataTable';
import { generateCampaignLink } from '../../../utils/ttdUtils';
import ColumnHeader from '../../../components/ColumnHeader';
import { formatFloat, formatInt, formatPct } from '../../../utils';
import { DataColumn } from './generateColumns';
import { CHART_TYPES } from '../PackageKPI/types';
import { tPerfCampData } from '../../../utils/performanceDetailUtils';
const horizontal = HORIZONTAL_ALIGNMENT.RIGHT;

type updateParams = { sortField: string; sortOrder: string };
type campaignPerformanceTableProps = {
  isLoading: boolean;
  campaigns: tPerfCampData[];
  sortField: string;
  sortOrder: string;
  top: string;
  bottom: string;
  updateSort: (params: updateParams) => void;
};

const CampaignPerformanceTable = (props: campaignPerformanceTableProps) => {
  // prettier-ignore
  const {  isLoading, campaigns, top, bottom, sortField, sortOrder, updateSort } = props;

  // number formatting
  const [displayRows, setDisplayRows] = useState<number>(1);
  const displayFloat = useCallback((value) => {
    return formatFloat(value);
  }, []);
  const typedDisplay = useCallback((numberType) => {
    switch (numberType) {
      case 'Int':
        return (value: number) => formatInt(value);
      case 'Pct':
        return (value: number) => formatPct(value);
      default:
        return (value: number) => formatFloat(value);
    }
  }, []);
  // automatically generate column based on fieldname, order and minWidth
  const metricColumn = useCallback(
    (field, order, minWidth) => {
      let width = minWidth;
      if (field === 'spend') {
        width = 115;
      }

      // replaceName overwrites the proper-cased field name, apiResponseKey is the data-field from the API and type indicated the number format to use
      let { replaceName, apiResponseKey, type } = CHART_TYPES[field];
      return DataColumn({
        field: apiResponseKey,
        handleUpdateParams,
        display: typedDisplay(type),
        order,
        minWidth: width,
        replaceName
      });
    },
    [props]
  );

  // Calculate Table Size dynamically to avoid scrollbars if possible
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // 875 px are needed for everything else (from header bar to table header)
      // one table row is 48 px

      // calculate leftover space 1920 screen
      var possibleRows = Math.max(
        1, // display at least 1 row
        Math.floor((window.innerHeight - 760) / 48)
      );

      var dataRows = campaigns ? campaigns.length : 1;
      var resultRows = Math.min(possibleRows, dataRows);
      setDisplayRows(resultRows);
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [campaigns]);

  // handle clicks on table headers to switch sort order
  const handleUpdateParams = useCallback(
    (sf: string) => {
      let so = SORT_DIRECTION.ASCENDING;
      if (sf !== sortField) {
        so = SORT_DIRECTION.ASCENDING;
      } else if (sortOrder === SORT_DIRECTION.ASCENDING) {
        so = SORT_DIRECTION.DESCENDING;
      }
      updateSort({ sortField: sf, sortOrder: so });
    },
    [props]
  );

  // build up table columns
  // the 1st 3 columns (campaign name, pacing, target budget) and the last 2 columns (LT budget, end date) are static
  // the 4th and 5th column are matching the displayed charts
  const columns = [
    {
      id: 'campaign_name',
      'data-testid': 'tbl-col-campaign_name',
      header: (
        <ColumnHeader
          handleUpdateParams={handleUpdateParams}
          field="campaign_name"
        ></ColumnHeader>
      ),
      layout: { order: 1 },
      width: { minWidthPixels: 550, flexGrow: 1 },
      isSortable: true,
      sortComparator: DataTable.COMPARATOR.STRING('campaign_name'),
      renderContent: (row) => (
        <a
          className="col-name"
          href={generateCampaignLink(row)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {row.campaign_name}
        </a>
      )
    },
    {
      id: 'pacing_percent',
      'data-testid': 'tbl-col-pacing_percent',
      header: (
        <ColumnHeader
          handleUpdateParams={handleUpdateParams}
          field="pacing_percent"
          replaceName="Pacing"
        ></ColumnHeader>
      ),
      layout: { order: 2 },
      width: { minWidthPixels: 80, flexGrow: 1 },
      isSortable: true,
      sortComparator: DataTable.COMPARATOR.NUMERIC('pacing_percent'),
      // pacing_percent can range from 0 to values greater than 10% as we are allowing overdelivery; this means we need a special treatment using 2 different progress bars  to allow such values and display lables correctly
      renderContent: (row) => (
        <div>
          <div
            style={{
              width: `${
                row.pacing_percent > 100 ? row.pacing_percent - 50 : 50
              }%`,
              position: 'relative',
              top: '10px',
              maxWidth: '100%',
              minWidth: '60px'
            }}
          >
            <ProgressBar
              labelSize={row.pacing_percent < 40 ? '0px' : '12px'}
              bgcolor={
                row.pacing_percent < 40
                  ? 'red'
                  : row.pacing_percent < 100
                  ? '#6a1b9a'
                  : row.pacing_percent === 100
                  ? '#2eaeff'
                  : '#ef6c00'
              }
              borderRadius="5px"
              completed={row.pacing_percent}
            />
          </div>
          {row.pacing_percent < 40 && (
            <div
              style={{
                width: '50%',
                height: '20px',
                zIndex: 2,
                position: 'absolute',
                top: '18px',
                left: '36%',
                fontWeight: 'bold',
                fontSize: '12px',
                color: 'red'
              }}
            >
              {row.pacing_percent + '%'}
            </div>
          )}
          <div
            style={{
              width: '50%',
              visibility: row.pacing_percent > 100 ? 'visible' : 'hidden',
              position: 'relative',
              top: '-10px',
              left: '46%',
              zIndex: -1
            }}
          >
            <ProgressBar
              labelSize="0px"
              bgcolor="#ef6c00"
              borderRadius="5px"
              baseBgColor="#ffffff"
              completed={Math.max(row.pacing_percent - 100, 0)}
            />
          </div>
        </div>
      )
    },
    {
      id: 'target_budget',
      'data-testid': 'tbl-col-target_budget',
      header: (
        <ColumnHeader
          handleUpdateParams={handleUpdateParams}
          field="target_budget"
        ></ColumnHeader>
      ),
      layout: { order: 3 },
      width: { minWidthPixels: 110, flexGrow: 1 },
      isSortable: true,
      sortComparator: DataTable.COMPARATOR.NUMERIC('target_budget'),
      renderContent: (row) => displayFloat(row.target_budget),
      alignment: {
        horizontal
      }
    },
    metricColumn(top, 4, 100),
    metricColumn(bottom, 5, 100),
    {
      id: 'budget',
      'data-testid': 'tbl-col-budget',
      header: (
        <ColumnHeader
          handleUpdateParams={handleUpdateParams}
          field="budget"
          replaceName="LT Budget"
        ></ColumnHeader>
      ),
      layout: { order: 6 },
      width: { minWidthPixels: 80, flexGrow: 1 },
      isSortable: true,
      sortComparator: DataTable.COMPARATOR.NUMERIC('budget'),
      renderContent: (row) => displayFloat(row.budget),
      alignment: {
        horizontal
      }
    },
    {
      id: 'end_date',
      'data-testid': 'tbl-col-end_date',
      header: (
        <ColumnHeader
          handleUpdateParams={handleUpdateParams}
          field="end_date"
        ></ColumnHeader>
      ),
      layout: { order: 6 },
      width: { minWidthPixels: 78, flexGrow: 1 },
      isSortable: true,
      sortComparator: DataTable.COMPARATOR.STRING('end_date'),
      renderContent: (row) => row.end_date,
      alignment: {
        horizontal
      }
    }
  ];

  const dataTableColumns = [...columns];

  return (
    <div id="campaign-performancetable" data-testid="campaign-performancetable">
      <EnhancedDataTable
        id="campaignperformancetable"
        onSelectionChanged={() => {}}
        theme={DataTable.THEME.LIGHT}
        rows={campaigns}
        getPrimaryKey={(row) => row.campaign_id}
        bodyHeight={{ isFixed: true, heightInRows: displayRows }}
        columns={dataTableColumns}
        sorting={{
          sortAllRows: true,
          direction: sortOrder,
          columnId: sortField
        }}
        isFiltering={false}
        isLoading={isLoading}
        isRetrievingMoreData={false}
        isMoreDataAvailable={false}
        getIsRowDisabled={(row) => false}
        getIsRowVisible={(row) => true}
      />
    </div>
  );
};

export default CampaignPerformanceTable;
