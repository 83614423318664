import { Observable, of } from 'rxjs';
import {
  mergeMap,
  delay,
  tap,
  take,
  filter,
  concatMap,
  concatAll,
  reduce,
  catchError
} from 'rxjs/operators';
import { IRequestError, IResultsData } from './requestTtdApi';

const enableDebug =
  process.env.REACT_APP_DEBUG && process.env.REACT_APP_DEBUG.toLocaleLowerCase() !== 'false';

export interface ILoadMultiData {
  [index: string]: number | string | undefined | any;
  BaseIds: Array<any>;
  BaseIdName: string;
  ttdAuthToken: string;
  searchTerm?: string;
  sortField?: string;
  progress?: Function;
  limitOverride?: number;
  // allow unfiltered responses by disabling response filter
  disableResponseFilter?: boolean | undefined;
  // allow counting
  countOnly?: boolean | undefined;
}
export interface IMultiResultsData {
  data: Array<any>;
  count: number;
  controlCount: number;
  totalCounts?: Object;
  messages: Array<any>;
}

export interface ILoadData {
  [index: string]: number | string | undefined | any;
  BaseId: string;
  BaseIdName: string;
  ttdAuthToken: string;
  searchTerm?: string;
  sortField?: string;
  runCnt?: number;
  runProgress?: Array<any>;
  progress?: Function;
  limitOverride?: number;
  // allow unfiltered responses by disabling response filter
  disableResponseFilter?: boolean | undefined;
  // allow counting
  countOnly?: boolean | undefined;
}

export interface IRunBase {
  run?: number;
  start: number;
  limit: number;
  ttdAuthToken: string;
  searchTerm?: string;
  sortField?: string;
  // allow unfiltered responses by disabling response filter
  disableResponseFilter?: boolean;
}
export interface IRunBaseId extends IRunBase {
  [index: string]: number | string | boolean | undefined;
}

export interface APIRequest {
  // eslint-disable-next-line no-unused-vars
  (props: IRunBaseId): Observable<IResultsData>;
}

export interface ILoadDataFunc extends ILoadData {
  apiRequest: APIRequest;
}

export interface ILoadMultiDataFunc extends ILoadMultiData {
  apiRequest: APIRequest;
}

/**
 * generic data loader
 * @param props
 */
export const loadData$ = (props: ILoadDataFunc): Observable<IResultsData> => {
  const {
    apiRequest,
    BaseId,
    BaseIdName,
    ttdAuthToken,
    searchTerm,
    sortField,
    runCnt,
    progress,
    limitOverride,
    disableResponseFilter,
    countOnly
  } = props;
  var runCount, runProgress;
  if (runCnt === undefined) {
    runCount = 1;
  } else {
    runCount = runCnt;
  }
  runProgress = props.runProgress;
  if (progress && !runProgress) {
    runProgress = [{ cnt: 0, runs: 1 }];
  }
  // debugger;
  // apiLogin$ returns an observable and needs subscribe for that reason
  var obj: IRunBaseId = {
    run: 0,
    start: 0,
    limit: 1,
    ttdAuthToken,
    searchTerm,
    sortField,
    BaseIdName,
    disableResponseFilter,
    countOnly
  };
  obj[BaseIdName] = BaseId;

  const loadRunData = (count: number): Observable<IResultsData> => {
    const limit = limitOverride || 1000;
    const tries = Math.ceil(count / limit);
    // @ts-ignore
    const tArr = [...Array(tries).keys()];
    const loadRuns = tArr.length;
    // var gm: number;
    var desiredDelay = 500;
    // using an arrray as counter provider together with mergeMap for parallel execution of the fetch requests
    return of(tArr).pipe(
      // See the initial values
      // tap(console.log),
      // Split array into single values during emit
      // Collect observables and subscribe to next when previous completes
      // @ts-ignore
      concatAll(),
      // Emit each value as a sequence of observables with a desired delay
      concatMap((a) => of(a).pipe(delay(desiredDelay))),
      // parallel execution for all fetches
      // @ts-ignore
      mergeMap((run: number) => {
        // status message
        // var gm1: number;
        var curRun: number = run;
        return of(run).pipe(
          // create parameters for each fetch
          mergeMap((run1: number) => {
            if (enableDebug)
              console.log(
                'data load ' +
                  (curRun + 1) +
                  ' loadRuns ' +
                  loadRuns +
                  ' running ... '
              );
            let obj1: IRunBaseId;
            obj1 = {
              start: run1 * limit,
              limit,
              ttdAuthToken,
              searchTerm,
              sortField,
              disableResponseFilter
            };
            obj1[BaseIdName] = BaseId;

            return of(obj1);
          }),
          // execute individual fetch
          mergeMap((p: IRunBaseId) => {
            return apiRequest(p);
          }),
          tap(() => {
            if (enableDebug) {
              console.log(
                'data load ' +
                  (curRun + 1) +
                  ' of ' +
                  loadRuns +
                  'returned - runCnt: ' +
                  runCount
              );
            }
            // progress reporting
            if (progress && typeof progress === 'function') {
              /* we can't be sure about the order of requests 
              therefore, we just increase a request counter for each run and compare it to the number of total runs;
              the function can be called more than once for multi loads, so each call has a separate cnt / runs set in the runProgress array reflecting all calls  
              */
              runProgress[runCount].cnt += 1;
              runProgress[runCount].runs = loadRuns;
              var pct = 0,
                div = runProgress.length;
              runProgress.forEach((el) => {
                pct += el.cnt / el.runs / div;
              });
              pct = Math.round(pct * 100);
              progress({ percent: pct });
            }
          })
        );
      }),

      // combine data
      reduce(
        (acc, val: IResultsData) => {
          // console.log('camp reduce ', val);
          // console.log('camp reduce ', acc);
          var data = val.data;
          if (data) {
            // acc.data = [...acc.data, ...data];
            if (Array.isArray(data)) {
              acc.data = [...acc.data, ...data];
              acc.count = acc.data.length;
            } else if (Object.keys(data).length > 0) {
              acc.data.push(data);
              acc.count++;
              if (!val.totalCount) {
                acc.totalCount = acc.count;
              }
            }
            if (val.totalCount) {
              acc.totalCount = val.totalCount;
            }
          } else if (val.message) {
            acc.message = val.message;
          }
          return acc;
        },
        {
          data: [] as any,
          count: 0,
          totalCount: 0,
          message: '' as string | IRequestError
        }
      ),
      // ensure complete data or error
      filter((el: IResultsData) => {
        if (!el || (el && el.message !== '')) {
          // let errors pass
          return true;
        }
        if (el.data && el.count && el.totalCount) {
          if (el.count !== el.totalCount) {
            console.error(
              'filtered data mismatch - count expected: ' +
                el.totalCount +
                '  count received: ' +
                el.count
            );
          }
          return el.count === el.totalCount;
        }
        return false;
      })
      // See the result, not necessary
      // tap(console.log)
    );
  };

  return of(obj).pipe(
    take(1),
    // read number of records
    mergeMap((p: IRunBaseId) => {
      return apiRequest(p);
    }),
    mergeMap((resp: IResultsData) => {
      const { totalCount } = resp;
      // console.log(
      //   'API response - count ' +
      //     count +
      //     ' message: ' +
      //     message +
      //     ' data: ' +
      //     JSON.stringify(data),
      //   data
      // );
      if (countOnly) {
        // for counting BaseId and totalCount are enough
        // eslint-disable-next-line no-param-reassign
        resp.BaseId = BaseId;
        return of(resp);
      }
      if (totalCount && totalCount > 1) {
        return loadRunData(totalCount).pipe(
          // eslint-disable-next-line no-unused-vars
          tap((resp1) => {
            // console.log('loadRunData response', resp1);
          })
        );
      }
      // forward error
      return of(resp);
    })
  );
};

export const loadMultiData$ = (
  props: ILoadMultiDataFunc
): Observable<IMultiResultsData> => {
  const {
    apiRequest,
    BaseIds,
    BaseIdName,
    ttdAuthToken,
    searchTerm,
    sortField,
    progress,
    limitOverride,
    disableResponseFilter,
    countOnly
  } = props;
  // debugger;
  // apiLogin$ returns an observable and needs subscribe for that reason
  var expectedVals = (BaseIds && BaseIds.length) || 0,
    progArr = new Array(expectedVals);
  for (let i = 0; i < progArr.length; i++) {
    progArr[i] = { cnt: 0, runs: 1 };
  }
  var obj: ILoadDataFunc;
  obj = {
    ttdAuthToken,
    searchTerm,
    sortField,
    BaseIdName,
    BaseId: '',
    apiRequest,
    runProgress: progArr,
    progress,
    limitOverride,
    disableResponseFilter,
    countOnly
  };
  var runCnt = -1;
  var parDelay = 150;
  var parallelRuns = 5;
  return of(BaseIds).pipe(
    // See the initial values
    // tap(console.log),
    // Split array into single values during emit
    // Collect observables and subscribe to next when previous completes
    // @ts-ignore
    concatAll(),
    // Emit each value as a sequence of observables with a desired delay
    concatMap((a) => of(a).pipe(delay(parDelay))),
    // Call service on each value as soon as possible, do not care about the order
    mergeMap((BaseId: string) => {
      obj.BaseId = BaseId;
      runCnt++;
      obj.runCnt = runCnt;
      return loadData$(obj);
    }, parallelRuns),
    // Reduce single values back into array
    // Reduces the values from source observable to a single value that's emitted when the source completes
    reduce(
      (acc, val: IResultsData) => {
        var { data, totalCount, BaseId } = val;
        if (countOnly && totalCount && BaseId) {
          acc.totalCounts[BaseId] = totalCount;
        }
        if (data) {
          if (Array.isArray(data)) {
            acc.data = [...acc.data, ...data];
            acc.count = acc.data.length;
          } else if (Object.keys(data).length > 0) {
            acc.data.push(data);
            acc.count++;
          }
          acc.controlCount++;
        } else if (val.message) {
          // if (acc.message) {
          //   acc.message += ';';
          // }
          // acc.message += val.message;
          acc.messages.push(val.message);
        }
        return acc;
      },
      {
        data: [] as any,
        count: 0,
        controlCount: 0,
        totalCounts: {},
        messages: [] as any
      }
    ),
    // ensure complete data or error
    filter((el: IMultiResultsData) => {
      // if (!el || (el && el.message !== '')) {
      if (!el || (el && el.messages.length > 0)) {
        // let errors pass
        return true;
      }
      if (el.data && el.controlCount) {
        if (el.controlCount !== expectedVals) {
          console.error(
            'filtered multi data mismatch count expected: ' +
              expectedVals +
              ' count received: ' +
              el.controlCount
          );
        }
        return el.controlCount === expectedVals;
      }
      return false;
    }),
    take(1),
    // See the result, not necessary
    tap(() => {
      if (progress && typeof progress === 'function') {
        progress({ percent: 100, immediate: true });
      }
    }),
    catchError((err) => {
      // ensure proper cleanup
      // console.log(err);

      if (progress && typeof progress === 'function') {
        progress({ percent: 100, immediate: true });
      }
      return of(err);
    })
  );
};

// module.exports = { loadData$, loadMultiData$ };
