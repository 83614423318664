import { START_REQUEST, STOP_REQUEST, RequestActionTypes } from './actionTypes';

export const requestStart = (): RequestActionTypes => {
  return {
    type: START_REQUEST,
    payload: { isLoading: true }
  };
};

export const requestStop = (): RequestActionTypes => {
  return {
    type: STOP_REQUEST,
    payload: { isLoading: false }
  };
};
