import { IRunBaseId } from './LoadData';
import { readQuery$ } from './readQuery';

interface IRunBaseRead extends IRunBaseId {
  BaseIdName?: string;
  BaseId: string;
}

/**
 * read advertisers + filter relevant properties
 * @param props
 */
export const readAdvAdgroup$ = (props: IRunBaseRead) => {
  var BaseIdName = 'AdvertiserId';
  return readQuery$({
    ...props,
    BaseIdName,
    method: 'POST',
    shortUrl: '/adgroup/query/advertiser',
    responseFilterFieldList: [
      'AdGroupName',
      'AdGroupId',
      'CampaignId',
      'IsEnabled',
      // multi level objects
      // 'RTBAttributes.CreativeIds',
      'RTBAttributes.BudgetSettings.Budget',
      'RTBAttributes.BudgetSettings.DailyBudget'
      // 'RTBAttributes.BudgetSettings.AdGroupFlights'
    ],
    errorId: BaseIdName
  });
};
