import { combineReducers, Reducer } from "redux";
import { reducer as formReducer } from "redux-form";
import userReducer from "./userReducer";
import requestReducer from "./requestReducer";
import searchListReducer from "./searchReducer";
import packageReducer from "./packageReducer";
import stepProgressReducer from './stepProgressReducer';
import { RootState, MyStore } from "typesafe-actions";

/* ==== MAUI Component Reducers ==== */

// needed for Enhanced Data View is needed for Enhanced Data Table
import dvReducer, {
  ENHANCED_DATAVIEW_STATE_KEY,
} from "@ttd/maui/lib/components/display/DataTable/EnhancedDataView/EnhancedDataViewReducer";

// for Maui Dialogs
import dlgReducer from "@ttd/maui/lib/components/display//Dialog/DialogReducer";

// for Maui Toasties
import toastieReducer from "@ttd/maui/lib/components/display/Toasties/toastiesReducer";


// these State Keys are not exported by Maui -> redefine them here
const TOASTIE_STATE_KEY = "maui.display.toasties";
const DIALOG_STATE_KEY = "maui.display.dialog";

/* ==== END MAUI Component Reducers ==== */

const __DEV__ = process.env.NODE_ENV !== "production";

// Private utility function to transform a portion of the asyncReducers tree
// into combined reducers to be used by makeRootReducer().
function combineSubReducers(node, isRoot) {
  let result = {};
  for (let key in node) {
    if (node[key] instanceof Function) {
      result[key] = node[key];
    } else {
      result[key] = combineSubReducers(node[key], false);
    }
  }
  return isRoot ? result : combineReducers(result);
}

export const registerSubReducers = (store) => {
  const reducers = [
    // EnhancedDataView is the sorting wrapper for EnhancedDataTable -> add reducer here
    {
      key: ENHANCED_DATAVIEW_STATE_KEY,
      reducer: dvReducer,
    },
    // Dialogs
    {
      key: DIALOG_STATE_KEY,
      reducer: dlgReducer,
    },
    // Toasties
    {
      key: TOASTIE_STATE_KEY,
      reducer: toastieReducer,
    },
  ];

  reducers.forEach((reducer) => {
    injectReducer(store, reducer);
  });
};

export const makeRootReducer = (asyncReducers?: Object): RootState => {
  const processedAsyncReducers = combineSubReducers(asyncReducers, true);

  return combineReducers({
    form: formReducer,
    user: userReducer,
    request: requestReducer,
		search: searchListReducer,
		package: packageReducer,
    stepProgress: stepProgressReducer,
    ...processedAsyncReducers,
  });
};

export let testableAddReducerToTree = null;

function addReducerToTree(treeRoot, key, reducer: Reducer, checkSimilarNames) {
  let keyParts = key.split(".");
  let currentNode = treeRoot;
  let needToRebuild = false;

  for (let i = 0; i < keyParts.length; i++) {
    if (!Object.hasOwnProperty.call(currentNode, keyParts[i])) {
      if (checkSimilarNames) {
        // Check to see if there's already a node that isn't what we're trying to add, but is
        // the same if we don't consider casing.  That might be very confusing down the road, when
        // debugging (i.e., having "adGroup.someState" and "adgroup.SomeState").
        const lowercasedPart = keyParts[i].toLowerCase();
        if (
          Object.keys(currentNode).some(
            (val) => val !== keyParts[i] && val.toLowerCase() === lowercasedPart
          )
        ) {
          throw new Error(
            `There is already a similar (different only by casing) object named ${keyParts[i]}`
          );
        }
      }

      if (i === keyParts.length - 1) {
        // We're at the end of the path, so here's where the reducer goes.
        currentNode[keyParts[i]] = reducer;
      } else {
        // We're not yet at the end of the path, but we need a
        // container in which to nest.
        currentNode[keyParts[i]] = {};
      }
      needToRebuild = true;
    }
    currentNode = currentNode[keyParts[i]];
  }

  return needToRebuild;
}

// Injects a new reducer asynchronously (that is, after the main store
// and root reducer) have already been created.  The key may be a simple string
// (which places the provided reducer just inside the root reducer), or a "path",
// separated by dots (which places the provided reducer inside nested containers whose
// names are given by the path elements).
export const injectReducer = (store: MyStore, { key, reducer }) => {
  const needToRebuild = addReducerToTree(
    store.asyncReducers,
    key,
    reducer,
    __DEV__
  );
  if (needToRebuild) {
    // @ts-ignore
    store.replaceReducer(makeRootReducer(store.asyncReducers) as Reducer);
  }
};

export default makeRootReducer;
