import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import ButtonIcon from '@ttd/maui/lib/components/display/Button/Button';
import TextControl from '@ttd/maui/lib/components/controls/TextControl';
import { uncheckAllCheckbox } from '../../../utils';

const UnassignedCampaign = ({
  items,
  assignedCampaigns,
  onAssign,
  filterName,
  onUpdateFilterName
}) => {
  const campItems = items;
  const [campaignsList, setCampaignList] = useState(items);
  const [selectedList, setSelectedList] = useState([]);
  const [isButtonEnable, setButtonEnabled] = useState(false);

  const updateFilter = (e) => {
    const val = e.target.value;
    onUpdateFilterName(val);
    const newList = _.filter(campItems, (o) => o && o.campaign_name && o.campaign_name.includes(val));
    setCampaignList(newList);
  };

  const updateSelectedList = (id, name) => {
    let newList;
    const data = {
      campaign_id: id,
      campaign_name: name
    };
    if (_.find(selectedList, data)) {
      _.remove(selectedList, data);
      newList = selectedList;
    } else {
      newList = [...selectedList, ...[data]];
    }
    setSelectedList(newList);
    setButtonEnabled(!_.isEmpty(newList));
  };

  const updateCampaigns = () => {
    if (selectedList.length > 0) {
      selectedList.map((item) => _.remove(campItems, item));
    }
    // update unassigned campaigns list
    setCampaignList(
      _.filter(
        campItems,
        (o) => o && o.campaign_name && o.campaign_name.includes(filterName)
      )
    );
    // update assigned campaigns list
    const assignedList = [...assignedCampaigns, ...selectedList];
    onAssign(campItems, assignedList);
    uncheckAllCheckbox();
    setButtonEnabled(false);
    setSelectedList([]);
  };

  useEffect(() => {
    setCampaignList(
      _.filter(
        items,
        (o) => o && o.campaign_name && o.campaign_name.includes(filterName)
      )
    );
  }, [items, filterName]);

  return (
    <>
      <fieldset>
        <legend>Unassigned Campaigns</legend>
        <div>
          <TextControl
            className="search-box"
            id="unassignedFilterBox"
            value={filterName}
            onChange={updateFilter}
            placeholder="Enter filter key..."
          />

          <div className="display-box">
            <ul id="unassignedList">
              {campaignsList
                ? campaignsList.length > 0 &&
                  campaignsList.map((item, key) => {
                    return (
                      <li className="child" key={key}>
                        <input
                          type="checkbox"
                          value={item.campaign_id}
                          onChange={() =>
                            updateSelectedList(
                              item.campaign_id,
                              item.campaign_name
                            )
                          }
                          id={`unassignedItem_${item.campaign_id}`}
                          className="checkbox"
                        />
                        <label htmlFor={`unassignedItem_${item.campaign_id}`}>
                          {item.campaign_name}
                        </label>
                      </li>
                    );
                  })
                : []}
            </ul>
          </div>
        </div>
      </fieldset>

      <ButtonIcon
        data-testid="id-btn-assign"
        type="button"
        glyphAfter="right-arrow"
        displayType="secondary"
        className="btn-assign"
        onClick={updateCampaigns}
        isEnabled={isButtonEnable}
      >
        Assign Selected Campaigns
      </ButtonIcon>
    </>
  );
};

export default UnassignedCampaign;
