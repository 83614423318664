import { config } from 'dotenv';

config();

import {
  getApiToken,
  responseFilter
  // postPutCampaign$,
  //
  // PostPutCampaignFlight$,
  // advertiserLiveCampaigns
} from '../ttdApi/ttdapi';

var nodeFields = ['nodes', 'edges.node'],
  upLevelFields = ['advertiser.AdvertiserId', 'partner.PartnerId'];

// c-Spell:disable
var edges = [
  {
    cursor: 'MA==',
    node: {
      CampaignId: '043omp5',
      CampaignName:
        'FY24_Harrisburg/Lancaster/Lebanon/York_Near Market_TM_Video_Kicks_Medium_TTD',
      StartDate: '2024-09-01T04:00:00.000Z',
      EndDate: '2024-09-30T03:59:00.000Z',
      PacingMode: 'PACE_AHEAD',
      TimeZone: 'America/New_York',
      CreatedAtUTC: '2024-08-28T22:09:23.140Z',
      LastUpdatedAtUTC: '2024-09-10T20:46:43.953Z',
      Version: null,
      CampaignFlights: {
        totalCount: 1,
        nodes: [
          {
            CampaignFlightId: '7322141',
            StartDateInclusiveUTC: '2024-09-01T04:00:00.000Z',
            EndDateExclusiveUTC: '2024-09-30T03:59:00.000Z',
            BudgetInAdvertiserCurrency: 4518,
            BudgetInImpressions: null,
            DailyTargetInAdvertiserCurrency: null,
            DailyTargetInImpressions: null
          }
        ]
      },
      advertiser: {
        AdvertiserId: 'lwtv17q'
      },
      partner: {
        PartnerId: 'ha11zpk'
      }
    }
  },
  {
    cursor: 'MQ==',
    node: {
      CampaignId: '04cas6o',
      CampaignName:
        'FY24_Harlingen/Weslaco/Brownsville/Mcallen_Near Market_SL_Video_Rogue_Medium_TTD',
      StartDate: '2024-04-01T04:00:00.000Z',
      EndDate: '2024-10-01T03:59:00.000Z',
      PacingMode: 'PACE_AHEAD',
      TimeZone: 'America/New_York',
      CreatedAtUTC: '2024-03-26T22:08:57.390Z',
      LastUpdatedAtUTC: '2024-08-28T19:51:10.127Z',
      Version: null,
      CampaignFlights: {
        totalCount: 6,
        nodes: [
          {
            CampaignFlightId: '6550259',
            StartDateInclusiveUTC: '2024-04-01T04:00:00.000Z',
            EndDateExclusiveUTC: '2024-05-01T03:59:00.000Z',
            BudgetInAdvertiserCurrency: 7400,
            BudgetInImpressions: null,
            DailyTargetInAdvertiserCurrency: 366.942149,
            DailyTargetInImpressions: null
          },
          {
            CampaignFlightId: '6760510',
            StartDateInclusiveUTC: '2024-05-08T04:00:00.000Z',
            EndDateExclusiveUTC: '2024-06-01T03:59:00.000Z',
            BudgetInAdvertiserCurrency: 3480,
            BudgetInImpressions: null,
            DailyTargetInAdvertiserCurrency: 1098.947368,
            DailyTargetInImpressions: null
          },
          {
            CampaignFlightId: '6872066',
            StartDateInclusiveUTC: '2024-06-01T04:00:00.000Z',
            EndDateExclusiveUTC: '2024-07-01T03:59:00.000Z',
            BudgetInAdvertiserCurrency: 3700,
            BudgetInImpressions: null,
            DailyTargetInAdvertiserCurrency: 174.80315,
            DailyTargetInImpressions: null
          },
          {
            CampaignFlightId: '7027027',
            StartDateInclusiveUTC: '2024-07-01T04:00:00.000Z',
            EndDateExclusiveUTC: '2024-08-01T03:59:00.000Z',
            BudgetInAdvertiserCurrency: 3700,
            BudgetInImpressions: null,
            DailyTargetInAdvertiserCurrency: null,
            DailyTargetInImpressions: null
          },
          {
            CampaignFlightId: '7184045',
            StartDateInclusiveUTC: '2024-08-01T04:00:00.000Z',
            EndDateExclusiveUTC: '2024-09-01T03:59:00.000Z',
            BudgetInAdvertiserCurrency: 3700,
            BudgetInImpressions: null,
            DailyTargetInAdvertiserCurrency: null,
            DailyTargetInImpressions: null
          },
          {
            CampaignFlightId: '7320550',
            StartDateInclusiveUTC: '2024-09-01T04:00:00.000Z',
            EndDateExclusiveUTC: '2024-10-01T03:59:00.000Z',
            BudgetInAdvertiserCurrency: 3700.00085,
            BudgetInImpressions: null,
            DailyTargetInAdvertiserCurrency: null,
            DailyTargetInImpressions: null
          }
        ]
      },
      advertiser: {
        AdvertiserId: 'a8xtj2o'
      },
      partner: {
        PartnerId: 'ha11zpk'
      }
    }
  }
];

var advEdges = [
  {
    node: {
      AdvertiserId: '00aa92d',
      partner: {
        PartnerId: 'tdpartnerid'
      }
    }
  },
  {
    node: {
      AdvertiserId: '01cxrn3',
      partner: {
        PartnerId: 'tdpartnerid'
      }
    }
  }
];

export function transformAdvGql(data) {
  var gql = data.map((edge) => {
    var { node } = edge;
    const result = {};
    // assign fields in the list to the highest level object
    upLevelFields.forEach((field) => {
      const keys = field.split('.');
      let lastKey = keys[keys.length - 1];
      let value = node;
      // Traverse down the object using the keys
      for (let i = 0; i < keys.length; i++) {
        if (value[keys[i]] !== undefined) {
          value = value[keys[i]];
        } else {
          //@ts-ignore
          value = undefined;
          break;
        }
      }
      if (value !== undefined) {
        // If we have multiple levels, assign the deepest value to the last key in the chain
        result[lastKey] = value;
      }
      // delete top level value;
      delete node[keys[0]];
    });
    node = { ...node, ...result };
    return node;
  });
  return gql;
}

var adv1 = transformAdvGql(advEdges);
console.log(adv1);

// c-Spell:enable
function toProperCase(myString: String): string {
  return myString
    .toLowerCase()
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
}

function translatePacingMode(mode: String): string {
  // prettier-ignore
  var caseList = ['PACE_AHEAD', 'PACE_EVENLY', 'PACE_AS_SOON_AS_POSSIBLE', 'PACE_TO_DAILY_CAP'];
  var retMode = '';
  switch (mode) {
    // 'PACE_AHEAD', 'PACE_EVENLY'
    case caseList[0]:
    case caseList[1]:
      retMode = toProperCase(mode).replace(/ /g, '');
      break;
    // 'PACE_AS_SOON_AS_POSSIBLE', 'PACE_TO_DAILY_CAP'
    case caseList[2]:
    case caseList[3]:
      retMode = 'Off';
      break;
    default:
      retMode = 'Unknown'; // Handle unknown cases
  }

  return retMode;
}

export function transformCampaignGql(data) {
  var gql = data.map((edge) => {
    var { node } = edge;
    var CampaignFlights = node.CampaignFlights.nodes;
    // transform flight ids to numbers like in the REST version
    CampaignFlights = CampaignFlights.map((flight) => ({
      ...flight,
      CampaignFlightId: parseInt(flight.CampaignFlightId)
    }));
    // @ts-ignore
    node['CampaignFlights'] = CampaignFlights;
    const result = {};
    // assign fields in the list to the highest level object
    upLevelFields.forEach((field) => {
      const keys = field.split('.');
      let lastKey = keys[keys.length - 1];
      let value = node;
      // Traverse down the object using the keys
      for (let i = 0; i < keys.length; i++) {
        if (value[keys[i]] !== undefined) {
          value = value[keys[i]];
        } else {
          //@ts-ignore
          value = undefined;
          break;
        }
      }
      if (value !== undefined) {
        // If we have multiple levels, assign the deepest value to the last key in the chain
        result[lastKey] = value;
      }
      // delete top level value;
      delete node[keys[0]];
    });
    node.PacingMode = translatePacingMode(node.PacingMode);
    node = { ...node, ...result };
    return node;
  });
  return gql;
}

// module.exports = {
//   transformCampaignGql
// };
